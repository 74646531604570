import React from 'react';
import { Modal, Button, InputGroup, ListGroup, Row, Col, Form, Badge } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { InfinitySpin } from 'react-loader-spinner';
import Avatar from '../../Widgets/Avatar';
import AvatarImage from '../../Widgets/AvatarImage';

export default ({ tourId, driverData, searchQuery, setSearchQuery, handleCloseAssignModal, setAssignedDriverDetails, unassignDriver, handleAssignDriverSubmit, enablePreloader, setSelectedDriver, tripDate }) => {

    return (
        <>
            <Modal.Header>
                <Modal.Title className='upperCase-keyword'>Select Driver</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleCloseAssignModal} />
            </Modal.Header>
            <div className='mx-3 mt-4'>
                <InputGroup>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="Search by name..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </InputGroup>
            </div>
            <Modal.Body className='overflow-y-auto height-driver-modal'>
                {enablePreloader ? (
                    <div className='loader-wrapper'>
                        <InfinitySpin
                            visible={true}
                            width="200"
                            color="#4fa94d"
                            ariaLabel="infinity-spin-loading"
                        />
                    </div>
                ) : (
                    <>
                        {driverData.map((driver) => (
                            <ListGroup.Item
                                key={driver.driverId}
                                action
                                className="border-bottom-0 br-5"
                                onClick={() =>
                                    setSelectedDriver(driver)
                                }>

                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                    {driver.image 
                                        ? AvatarImage(driver.image) 
                                        : Avatar(driver.firstName, driver.lastName)
                                    }
                                    </Col>
                                    <Col className="ps-0 ms--2">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h4 className="h6 mb-0 text-small upperCase-keyword">{driver.firstName} {driver.lastName}</h4>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <Badge className={`f-10 badge-sm badge mt-1 px-2 py-1 bg-${{
                                                        onTrip: 'warning',
                                                        available: 'success',
                                                    }[driver.driverStatus] || 'light'
                                                        }`}
                                                    >
                                                        {{
                                                            onTrip: 'On a trip',
                                                            available: 'Available',
                                                            onLeave: 'On a leave'
                                                        }[driver.driverStatus] || 'Unknown'}
                                                    </Badge>
                                                </div>
                                            </div>
                                            {driver.isAssignedToCurrentTour
                                                ? <Button variant="outline-danger" size="sm" onClick={() => unassignDriver(driver.driverId)}>
                                                    <FontAwesomeIcon icon={faTimes} /> Unassign
                                                </Button>
                                                : <></>}

                                        </div>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleCloseAssignModal}
                >
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleAssignDriverSubmit}
                    disabled={driverData.find(driver => driver.toursScheduled !== null && driver.toursScheduled === tourId ? true : false)}
                >
                    Assign
                </Button>
            </Modal.Footer>
        </>
    );
};
