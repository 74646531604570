import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faStar as faStarEmpty } from '@fortawesome/free-solid-svg-icons';

export default ({ rating, onRate, disabled, previousRating  }) => {
    const [hoverIndex, setHoverIndex] = useState(null);
    const [selectedRating, setSelectedRating] = useState(rating);

    const handleClick = (value) => {
      if (!disabled) {
        const prevRating = parseFloat(previousRating);
        if (value === prevRating) {
          setSelectedRating(0); // Reset rating if the same star is clicked
          if (onRate) onRate(0); // Notify parent with reset value
        } else {
          setSelectedRating(value); // Update to the new rating
          if (onRate) onRate(value); // Notify parent with new rating
        }
      }
    };
  
    const handleMouseEnter = (index) => {
        if (!disabled) {
            setHoverIndex(index);
        }
    };
  
    const handleMouseLeave = () => {
        if (!disabled) {
          setHoverIndex(null);
        }
    };
  
    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
          let icon;
          if (selectedRating >= i) {
            icon = faStar;
          } else if (selectedRating >= i - 0.5) {
            icon = faStarHalfAlt;
          } else {
            icon = faStarEmpty;
          }
    
          stars.push(
            <FontAwesomeIcon
              key={i}
              icon={icon}
              className={`f-14 star ${i <= (hoverIndex || selectedRating) ? 'active' : ''}`}
              onClick={() => handleClick(i)}
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={handleMouseLeave}
            />
          );
        }
        return stars;
      };
  
    return <div>{renderStars()}</div>;
};
