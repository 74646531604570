import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faChartLine, faCircle, faFlagUsa, faFolderOpen, faGlobeEurope, faPaperclip, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faAngular, faBootstrap, faReact, faVuejs } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Card, Image, Button, ListGroup, ProgressBar, InputGroup, Badge } from '@themesberg/react-bootstrap';
import { CircleChart, BarChart, SalesValueChart, SalesValueChartphone } from "./Charts";
import axios from "axios";
import moment from "moment-timezone";
import GetUserLocation from "../pages/GetUserLocation";
import { Table, Pagination } from '@themesberg/react-bootstrap';
import ReactSelect from "react-select";
import ComponentPreloader from "./ComponentPreloader";
import Avatar from "./Widgets/Avatar";
import OvalLoader from "./SubComponents/Loader/OvalLoader";

export const ProfileCardWidget = ({ data }) => {
  const { image, fullName } = data;
  const photo = image === "Not available" ? 'https://transferappdata.s3.ap-southeast-1.amazonaws.com/user+(2).png' : image;
  return (
    <>
      <Card.Img src={photo} alt={fullName} className="user-avatar large-avatar mx-auto mb-4" />
      <Card.Title>{fullName}</Card.Title>
    </>
  );
};

export const ChoosePhotoWidget = (props) => {
  const { title, photo } = props;

  return (
    <Card border="light" className="bg-white shadow mb-4">
      <Card.Body>
        <h5 className="mb-4">{title}</h5>
        <div className="d-xl-flex align-items-center">
          <div className="user-avatar xl-avatar">
            <Image fluid rounded src={photo} />
          </div>
          <div className="file-field">
            <div className="d-flex justify-content-xl-center ms-xl-3">
              <div className="d-flex">
                <span className="icon icon-md">
                  <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                </span>
                <input type="file" />
                <div className="d-md-block text-start">
                  <div className="fw-normal text-dark mb-1">Choose Image</div>
                  <div className="text-gray small">JPG, GIF or PNG. Max size of 800K</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const CounterWidget = (props) => {
  const { icon, iconColor, category, title, subtitle } = props;

  return (
    <Card border="light" className="shadow mb-3" style={{
      backgroundImage: "url('../assets/img/bg.png')",
      backgroundSize: "cover"
    }}>
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-xl-0">
            <div className={`icon icon-shape icon-md icon-${iconColor} rounded me-4 me-sm-0`}>
              <FontAwesomeIcon icon={icon} />
            </div>
            <div className="d-sm-none">
              <h2 className="h5">{category}</h2>
              <h3 className="mb-1">{subtitle} {title}</h3>
            </div>
          </Col>
          <Col xs={12} xl={7} className="px-xl-0">
            <div className="d-none d-sm-block">
              <h2 className="h5">{category}</h2>
              <h3 className="mb-1">{subtitle} {title}</h3>
            </div>
            {/* <small>{period}, <FontAwesomeIcon icon={faGlobeEurope} size="xs" /> WorldWide</small>
            <div className="small mt-2">
              <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
              <span className={`${percentageColor} fw-bold`}>
                {percentage}%
              </span> Since last month
            </div> */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const CounterWidgetDriver = (props) => {
  const { icon, iconColor, category, title } = props;

  return (
    <Card border="light" className="shadow mb-4">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-xl-0">
            <div className={`icon icon-shape icon-md icon-${iconColor} rounded me-4 me-sm-0`}>
              <FontAwesomeIcon icon={icon} />
            </div>
            <div className="d-sm-none">
              <h5>{category}</h5>
              <h3 className="mb-1">{title}</h3>
            </div>
          </Col>
          <Col xs={12} xl={7} className="px-xl-0">
            <div className="d-none d-sm-block">
              <h5>{category}</h5>
              <h3 className="mb-1">{title}</h3>
            </div>
            {/* <small>{period}, <FontAwesomeIcon icon={faGlobeEurope} size="xs" /> WorldWide</small>
            <div className="small mt-2">
              <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
              <span className={`${percentageColor} fw-bold`}>
                {percentage}%
              </span> Since last month
            </div> */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};


export const CircleChartWidget = (props) => {
  const { title, series, colors, total } = props;

  return (
    <Card border="light" className="shadow">
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0 upperCase-keyword">{title}</h5>
      </Card.Header>
      <Card.Body>
        <div className="counts">
          <div><FontAwesomeIcon icon={faCircle} style={{ color: '#262B40' }} /> Total: {total}</div>
          <div><FontAwesomeIcon icon={faCircle} style={{ color: '#1b998b' }} /> Active: {series[0]}</div>
          <div><FontAwesomeIcon icon={faCircle} style={{ color: colors[1] }} /> Inactive: {series[1]}</div>
        </div>
        <Row className="d-block d-xl-flex align-items-center">
          <Col className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
            <CircleChart series={series} colors={colors} total={total} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const BarChartWidget = (props) => {
  const { title, value, percentage, data = [] } = props;
  const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const series = data.map(d => d.value);
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  return (
    <Card border="light" className="shadow">
      <Card.Body className="d-flex flex-row align-items-center flex-0 border-bottom">
        <div className="d-block">
          <h6 className="fw-normal text-gray mb-2">{title}</h6>
          <h3>{value}</h3>
          <small className="mt-2">
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={`${percentageColor} fw-bold`}>
              {percentage}%
            </span>
          </small>
        </div>
        <div className="d-block ms-auto">
          {data.map(d => (
            <div key={`bar-element-${d.id}`} className="d-flex align-items-center text-end mb-2">
              <span className={`shape-xs rounded-circle bg-${d.color} me-2`} />
              <small className="fw-normal">{d.label}</small>
            </div>
          ))}
        </div>
      </Card.Body>
      <Card.Body className="p-2">
        <BarChart labels={labels} series={series} />
      </Card.Body>
    </Card>
  );
};

export const TeamMembersWidget = () => {

  return (
    <Card border="light" className="shadow">
      <Card.Header className="border-bottom border-light d-flex justify-content-between">
        <h5 className="mb-0">Team members</h5>
        <Button variant="secondary" size="sm">See all</Button>
      </Card.Header>
      <Card.Body>
        <ListGroup className="list-group-flush list my--3">
          {/* {teamMembers.map(tm => <TeamMember key={`team-member-${tm.id}`} {...tm} />)} */}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export const ProgressTrackWidget = () => {
  const Progress = (props) => {
    const { title, percentage, icon, color, last = false } = props;
    const extraClassName = last ? "" : "mb-2";

    return (
      <Row className={`align-items-center ${extraClassName}`}>
        <Col xs="auto">
          <span className={`icon icon-md text-${color}`}>
            <FontAwesomeIcon icon={icon} className="me-1" />
          </span>
        </Col>
        <Col>
          <div className="progress-wrapper">
            <div className="progress-info">
              <h6 className="mb-0">{title}</h6>
              <small className="fw-bold text-dark">
                <span>{percentage} %</span>
              </small>
            </div>
            <ProgressBar variant={color} now={percentage} min={0} max={100} />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Card border="light" className="shadow">
      <Card.Header className="border-bottom border-light">
        <h5 className="mb-0">Progress track</h5>
      </Card.Header>
      <Card.Body>

        <Progress title="Rocket - SaaS Template" color="purple" icon={faBootstrap} percentage={34} />
        <Progress title="Pixel - Design System" color="danger" icon={faAngular} percentage={60} />
        <Progress title="Spaces - Listings Template" color="tertiary" icon={faVuejs} percentage={45} />
        <Progress title="Stellar - Dashboard" color="info" icon={faReact} percentage={35} />
        <Progress last title="Volt - Dashboard" color="purple" icon={faBootstrap} percentage={34} />
      </Card.Body>
    </Card>
  );
};

export const RankingWidget = () => {
  return (
    <Card border="light" className="shadow">
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
          <div>
            <h6><FontAwesomeIcon icon={faGlobeEurope} className="icon icon-xs me-3" /> Global Rank</h6>
          </div>
          <div>
            <Card.Link href="#" className="text-primary fw-bold">
              #755 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between border-bottom border-light py-3">
          <div>
            <h6 className="mb-0"><FontAwesomeIcon icon={faFlagUsa} className="icon icon-xs me-3" />Country Rank</h6>
            <div className="small card-stats">
              United States <FontAwesomeIcon icon={faAngleUp} className="icon icon-xs text-success ms-2" />
            </div>
          </div>
          <div>
            <Card.Link href="#top" className="text-primary fw-bold">
              #32 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between pt-3">
          <div>
            <h6 className="mb-0"><FontAwesomeIcon icon={faFolderOpen} className="icon icon-xs me-3" />Category Rank</h6>
            <Card.Link href="#top" className="small card-stats">
              Travel &gt; Accomodation
            </Card.Link>
          </div>
          <div>
            <Card.Link href="#top" className="text-primary fw-bold">
              #16 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
            </Card.Link>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export const DriversLocationWidget = () => {
  const [allDrivers, setAllDrivers] = useState([]);
  const [driverLocations, setDriverLocations] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/location`);
        setAllDrivers(response.data || []);
        const options = response.data.map(user => ({
          value: user.driverId,
          label: `${user.firstName} ${user.lastName}`
        }));
        setSelectOptions(options);
        // Initially, show all drivers' locations
        setDriverLocations(response.data);
      } catch (error) {
        console.error('Error fetching drivers:', error);
      } finally {
        setLoading(false); // End loading
      }
    };
    fetchDrivers();
  }, []);

  useEffect(() => {
    if (selectedDriver) {
      const fetchDriverLocation = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/location?driverId=${selectedDriver.driverId}`);
          setDriverLocations(response.data || []);
        } catch (error) {
          console.error('Error fetching driver location:', error);
        } finally {
          setLoading(false); // End loading
        }
      };
      fetchDriverLocation();
    } else {
      // If no driver is selected, show all drivers' locations
      setDriverLocations(allDrivers);
    }
  }, [selectedDriver, allDrivers]);

  const customStyles = {

    control: (provided) => ({
      ...provided,
      borderRadius: '.5rem'
    }),

    multiValue: (provided) => ({
      ...provided,
      borderRadius: '.5rem'
    }),

    multiValueRemove: (provided) => ({
      ...provided,
      borderRadius: '.5rem'
    }),

    placeholder: (provided) => ({
      ...provided,
      borderRadius: '.5rem',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
      };
    }
  };

  const handleDriverSelect = (selectedOption) => {
    setSelectedDriver(selectedOption ? {
      driverId: selectedOption.value,
      driverName: selectedOption.label
    } : null);
  }

  const selectedDriverOption = selectOptions.find(option => option.value === selectedDriver);
  const baseLocation = { lat: 17.4233, lng: 78.4471 }; // Example base location

  return (
    <>
      {loading ? <ComponentPreloader /> :
        <Card className="shadow">
          <Card.Header>
            <h5 className="mb-0 upperCase-keyword">Track location</h5>
          </Card.Header>
          <Card.Body>
            <div className='d-flex justify-content-start align-items-center mb-2'>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <ReactSelect options={selectOptions} styles={customStyles} theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#61DAFB',
                    primary: '#61DAFB',
                  }
                })}
                  name="tourName"
                  value={selectedDriverOption} // Use selectedTour for value
                  onChange={handleDriverSelect}
                  className="react-select"
                  classNamePrefix="react-select"
                />
              </InputGroup>
            </div>
            <GetUserLocation users={driverLocations} baseLocation={baseLocation} apiKey="AIzaSyBqvc7HP_JL_pjCHyHvIhoet4jdg25l4ig" />
          </Card.Body>
        </Card>
      }
    </>
  );
};

export const DriversAttendanceWidget = () => {
  const [loading, setLoading] = useState(true); // Add loading state
  const [driversStatus, setDriversStatus] = useState();
  const fetchUsers = React.useCallback(async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/leaves?role=admin`);
      setDriversStatus(response.data.leavePlans)
    } catch (error) {
      console.error('Error fetching users:', error);
    }
    finally {
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      {loading ? <ComponentPreloader /> :
        <Card className="shadow">
          <Card.Header>
            <h5 className="mb-0 upperCase-keyword">Track Activity</h5>
          </Card.Header>
          <Card.Body>
            {driversStatus.map(item => {
              return (
                <div className="d-flex justify-content-between align-items-center">
                  <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                    {Avatar(item.firstName, item.lastName)}
                    <div className='px-2 f-14'>
                      {item.firstName} {item.lastName}
                    </div>
                  </div>
                  <Badge className={`f-14 upperCase-keyword badge-md badge bg-${item.isAvailable === 0 ? 'warning' : 'tertiary'}`}>
                    {item.isAvailable === 0 ? 'On Leave' : 'Available'}
                  </Badge>
                </div>
              )
            })
            }
          </Card.Body>
        </Card>
      }
    </>
  );
};


export const ShowDriversActivity = () => {
  const [drivers, setDrivers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [expandedId, setExpandedId] = useState(null); // State to manage expanded driver ID
  const [visibleCount, setVisibleCount] = useState(5); // State to manage number of visible records

  useEffect(() => {
    fetchDrivers();
  }, [currentPage]);

  const fetchDrivers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchDriversAvailable`);
      setDrivers(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const toggleDetails = (driverId) => {
    if (expandedId === driverId) {
      setExpandedId(null); // Collapse if already expanded
    } else {
      setExpandedId(driverId); // Expand if not expanded
    }
  };

  const formatFullName = (firstName, lastName) => {
    const formattedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    const formattedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
    return `${formattedFirstName} ${formattedLastName}`;
  };

  const handleShowMore = () => {
    setVisibleCount(prevCount => Math.min(prevCount + 5, drivers.length));
  };

  return (
    <div>
      <Card border="light" className="table-wrapper table-responsive shadow">
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h5 className="fw-normal mb-2">
              Track drivers
            </h5>
          </div>
          <div className="d-flex ms-auto">
          </div>

        </Card.Header>
        <Card.Body className="pb-0">
          <Table hover responsive className="table table-centered table-nowrap mb-0 rounded">
            <thead className='thead-light'>
              <tr>
                <th className="border-bottom">Driver Name</th>
                <th className="border-bottom">Contact Number</th>
                <th className="border-bottom">Joining Date</th>
                <th className="border-bottom">Car Number</th>
                <th className="border-bottom">Licence No.</th>
                <th className="border-bottom">Tours Completed</th>
                <th className="border-bottom">Tours Scheduled</th>
                <th className="border-bottom">Status</th>
              </tr>
            </thead>
            <tbody>
              {drivers.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center my-4 no-bottom-border">
                    No data found.
                  </td>
                </tr>
              ) : (
                drivers.slice(0, visibleCount).map(driver => (
                  <React.Fragment key={driver.driverId}>
                    {/* Main row */}
                    <tr className='cursor-pointer' onClick={() => toggleDetails(driver.driverId)}>
                      <td className='upperCase-keyword fw-bold'>
                        <span className="d-block">
                          {formatFullName(driver.firstName, driver.lastName)}
                        </span>
                        <small className='small text-gray'>
                          {driver.email}
                        </small>
                      </td>
                      <td>
                        <span className='d-block'>
                          {driver.contactNumber}
                        </span>

                      </td>
                      <td>{moment(driver.joiningDate).format('Do MMMM, YYYY')}</td>
                      <td>{driver.carNumber}</td>
                      <td>{driver.driverLicence}</td>
                      <td>{driver.numToursCompleted}</td>
                      <td>{driver.numToursScheduled}</td>
                      <td>{driver.driverAvailability}</td>
                    </tr>
                    {/* Expanded row */}
                    {expandedId === driver.driverId && (
                      <tr className='expandable-row'>
                        <td colSpan="6">
                          <div className='expanded-details'>
                            <p><strong>Full Name:</strong> {formatFullName(driver.firstName, driver.lastName)}</p>
                            <p><strong>Contact:</strong> {driver.contactNumber}</p>
                            <p><strong>Email:</strong> {driver.email}</p>
                            <p><strong>Joining Date:</strong> {moment(driver.joiningDate).format('Do MMMM, YYYY')}</p>
                            <p><strong>Car Number:</strong> {driver.carNumber}</p>
                            <p><strong>Driver Licence:</strong> {driver.driverLicence}</p>
                            {/* Additional details as needed */}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <Pagination>
            <Pagination.Prev onClick={prevPage} disabled={currentPage === 1}>Previous</Pagination.Prev>
            <Pagination.Next onClick={nextPage} disabled={currentPage === totalPages}>Next</Pagination.Next>
          </Pagination>
          {visibleCount < drivers.length && (
            <Button variant="primary" type="submit" className=" btn btn-primary d-inline-flex align-items-center" onClick={handleShowMore}>
              Show More <FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon>
            </Button>
          )}
        </Card.Footer>
      </Card>
    </div>
  );
};


export const SalesValueWidget = (props) => {
  const [chartData, setChartData] = useState();
  const buttonRef = useRef(null);

  // Use useEffect to trigger the button click on page load
  useEffect(() => {
    // Check if buttonRef is set
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }, []); // Empty dependency array ensures this runs only on mount

  const fetchBookingDataMonthly = async () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/monthly`)
      .then(response => {
        const daysOfWeek = [];
        response.data.forEach(item => {
          daysOfWeek.push(moment(item.date).format('DD'));
        });
        let seriesArray = new Array(daysOfWeek.length).fill(0);
        response.data.forEach(item => {
          const index = daysOfWeek.indexOf(moment(item.date).format('DD'));
          if (index !== -1) {
            seriesArray[index] = parseFloat(item.count); // Convert string to float
          }
        });
        const updated = {
          labels: daysOfWeek,
          series: [seriesArray]
        };

        setChartData(updated);
      })
      .catch(error => {
        console.error('Error fetching sales data:', error);
      });
  }

  const fetchBookingDataWeekly = async () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/weekly`)
      .then(response => {
        const daysOfWeek = [];
        response.data.forEach(item => {
          daysOfWeek.push(item.day);
        });
        let seriesArray = new Array(daysOfWeek.length).fill(0);
        response.data.forEach(item => {
          const index = daysOfWeek.indexOf(item.day);
          if (index !== -1) {
            seriesArray[index] = parseFloat(item.count); // Convert string to float
          }
        });
        const updated = {
          labels: daysOfWeek,
          series: [seriesArray]
        };

        setChartData(updated);
      })
      .catch(error => {
        console.error('Error fetching sales data:', error);
      });
  }

  return (
    <Card className="bg-yellow-100 shadow">
      <Card.Header className="d-flex flex-row align-items-center flex-0">
        <div className="d-block">
          <h5 className="fw-semibold mb-2">
            Trips Analytics
          </h5>
        </div>
        <div className="d-flex ms-auto">
          <div className="btn-group ms-auto" role="group">
            <Button variant="primary" size="sm" className='animate-up-2 mb-3 ml-18 shadow-3d' ref={buttonRef} onClick={fetchBookingDataWeekly}>Weekly</Button>
            <Button variant="primary" size="sm" className='animate-up-2 mb-3 ml-18 shadow-3d' onClick={fetchBookingDataMonthly}>Month</Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <SalesValueChart data={chartData} />
      </Card.Body>
    </Card>
  );
};

export const PaymentChart = (props) => {
  const [chartData, setChartData] = useState();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [activePeriod, setActivePeriod] = useState('today'); // default active period
  const [period, setPeriod] = useState('today'); // Default period
  const weeklyButtonRef = useRef(null);

  // UseEffect to trigger the correct button click based on default period
  useEffect(() => {
    if (weeklyButtonRef.current) {
      weeklyButtonRef.current.click(); // Trigger a click on the default button
    }
  }, []);

  // Fetch data based on the selected period
  const fetchBookingData = async () => {
    setIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/dashboard/salesData`;

    try {
      let response;
      if (period === 'today') {
        setActivePeriod('today');
        response = await axios.get(`${apiUrl}?period=today`);
      } else if (period === 'week') {
        setActivePeriod('week');
        response = await axios.get(`${apiUrl}?period=week`);
      } else if (period === 'month') {
        setActivePeriod('month')
        response = await axios.get(`${apiUrl}?period=month`);
      } else if (period === 'year') {
        setActivePeriod('year')
        response = await axios.get(`${apiUrl}?period=year`);
      }


      setData(response.data)
      setChartData(response.data.chartData);
    } catch (error) {
      console.error('Error fetching sales data:', error);
    }
    finally{
      setIsLoading(false);
    }
  };

  const handlePeriodChange = (selectedPeriod) => {
    setPeriod(selectedPeriod);
  };

  // Trigger fetch when period changes
  useEffect(() => {
    fetchBookingData();
  }, [period]);

  return (
    <>
     <Card className="bg-yellow-100 shadow height-650">
      <Card.Header className="d-flex flex-row align-items-center flex-0">
        <div className="d-block">
          <h5 className="mb-2">Sales Analytics</h5>
        </div>
        <div className="d-flex ms-auto">
          <div className="btn-group ms-auto" role="group">
            <Button
              variant="primary"
              size="sm"
              className={`animate-up-2 mb-3 ml-18 shadow-3d me-2 ${activePeriod === 'today' ? 'active' : ''}`}
              ref={weeklyButtonRef}
              onClick={() => handlePeriodChange('today')}
            >
              Today
            </Button>
            <Button
              variant="primary"
              size="sm"
              className={`animate-up-2 mb-3 ml-18 shadow-3d me-2 ${activePeriod === 'week' ? 'active' : ''}`}
              onClick={() => handlePeriodChange('week')}
            >
              Weekly
            </Button>
            <Button
              variant="primary"
              size="sm"
              className={`animate-up-2 mb-3 ml-18 shadow-3d me-2 ${activePeriod === 'month' ? 'active' : ''}`}
              onClick={() => handlePeriodChange('month')}
            >
              Month
            </Button>
            <Button
              variant="primary"
              size="sm"
              className={`animate-up-2 mb-3 ml-18 shadow-3d me-2 ${activePeriod === 'year' ? 'active' : ''}`}
              onClick={() => handlePeriodChange('year')}
            >
              Year
            </Button>
          </div>
        </div>
      </Card.Header>
      { isLoading 
      ? <OvalLoader /> 
      : <Card.Body className="p-2 pt-4">
          <Card className="bg-transparent card mx-3 bg-card mb-6 shadow-inset">
            <Card.Body className="bg-transparent">
              <div className="d-flex justify-content-start align-items-center">
                <div className="min-w-fit-content me-5">
                  <p className="mb-1 f-12 fw-semibold">Total Earnings</p>
                  <h4 className="font-medium mb-0 f-24 fw-semibold">
                    {data.earnings}
                    <span className="badge bg-success ms-2 text-[0.5625rem]">
                      <i className="ti ti-trending-up me-1"></i>
                      {data.currentPercentage === "+Infinity%" ? "0%" : data.currentPercentage}
                    </span>
                  </h4>
                </div>
                <div className="min-w-fit-content me-5">
                  <p className="mb-1 f-12 fw-semibold">Total Bookings</p>
                  <h4 className="font-medium mb-0 f-24 fw-semibold">
                    {data.bookings}
                    <span className="badge bg-success ms-2 text-[0.5625rem]">
                      <i className="ti ti-trending-up me-1"></i>
                      {data.currentBookingPercentage}
                    </span>
                  </h4>
                </div>
                <div className="min-w-fit-content me-5">
                  <p className="mb-1 f-12 fw-semibold">Completed Bookings</p>
                  <h4 className="font-medium mb-0 f-24 fw-semibold">
                    {data.completedTrips}
                    <span className="badge bg-success ms-2 text-[0.5625rem]">
                      <i className="ti ti-trending-up me-1"></i>
                      {data.currentCompletedTripPercent}
                    </span>
                  </h4>
                </div>
              </div>
            </Card.Body>
          </Card>
          <SalesValueChart data={chartData} />
        </Card.Body>
      }
    </Card>
    </>
  );
};


export const BookingsChart = (props) => {
  const [chartData, setChartData] = useState();
  const weeklyButtonRef = useRef(null);
  // UseEffect to select the weekly button by default
  useEffect(() => {
    if (weeklyButtonRef.current) {
      weeklyButtonRef.current.click(); // Trigger a click on the weekly button
    }
  }, []);

  const fetchBookingDataMonthly = async () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/dashboard/bookings/monthly`)
      .then(response => {
        const daysOfWeek = [];
        response.data.forEach(item => {
          daysOfWeek.push(moment(item.date).format('DD'));
        });
        let seriesArray = new Array(daysOfWeek.length).fill(0);
        response.data.forEach(item => {
          const index = daysOfWeek.indexOf(moment(item.date).format('DD'));
          if (index !== -1) {
            seriesArray[index] = parseFloat(item.count); // Convert string to float
          }
        });
        const updated = {
          labels: daysOfWeek,
          series: [seriesArray]
        };

        setChartData(updated);
      })
      .catch(error => {
        console.error('Error fetching sales data:', error);
      });
  }

  const fetchBookingDataWeekly = async () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/dashboard/bookings/weekly`)
      .then(response => {
        const daysOfWeek = [];
        response.data.forEach(item => {
          daysOfWeek.push(item.day);
        });
        let seriesArray = new Array(daysOfWeek.length).fill(0);
        response.data.forEach(item => {
          const index = daysOfWeek.indexOf(item.day);
          if (index !== -1) {
            seriesArray[index] = parseFloat(item.count); // Convert string to float
          }
        });
        const updated = {
          labels: daysOfWeek,
          series: [seriesArray]
        };

        setChartData(updated);
      })
      .catch(error => {
        console.error('Error fetching sales data:', error);
      });
  }

  return (
    <Card className="bg-yellow-100 shadow">
      <Card.Header className="d-flex flex-row align-items-center flex-0">
        <div className="d-block">
          <h5 className="mb-2">
            Bookings Analytics
          </h5>
        </div>
        <div className="d-flex ms-auto">
          <div className="btn-group ms-auto" role="group">
            <Button variant="primary" size="sm" className='animate-up-2 mb-3 ml-18 shadow-3d' ref={weeklyButtonRef} onClick={fetchBookingDataWeekly}>Weekly</Button>
            <Button variant="primary" size="sm" className='animate-up-2 mb-3 ml-18 shadow-3d' onClick={fetchBookingDataMonthly}>Month</Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-2 pt-5">
        <SalesValueChart data={chartData} />
      </Card.Body>
    </Card>
  );
};

export const SalesValueWidgetPhone = (props) => {
  const { title, value, percentage } = props;
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";

  return (
    <Card className="bg-secondary-alt shadow">
      <Card.Header className="d-md-flex flex-row align-items-center flex-0">
        <div className="d-block mb-3 mb-md-0">
          <h5 className="fw-normal mb-2">
            {title}
          </h5>
          <h3>${value}</h3>
          <small className="fw-bold mt-2">
            <span className="me-2">Yesterday</span>
            <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} />
            <span className={percentageColor}>
              {percentage}%
            </span>
          </small>
        </div>
        <div className="d-flex ms-auto">
          <Button variant="secondary" size="sm" className="me-2">Month</Button>
          <Button variant="primary" size="sm" className="me-3">Week</Button>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <SalesValueChartphone />
      </Card.Body>
    </Card>
  );
};

export const WeeklySalesValueWidget = (props) => {
  const { title, value } = props;
  const downloadWeeklyReport = async () => {
    try {
      // Request the CSV file from the server
      const response = await axios({
        url: `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/download-weekly-sales`, // URL of the API endpoint
        method: 'GET',
        responseType: 'blob', // Important for file downloads
      });

      // Create a URL for the CSV blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'weekly_sales_data.csv'); // Set the file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };
  return (
    <Card className="shadow">
      <Card.Header className="d-flex flex-row align-items-center flex-0">
        <div className="d-block mb-3 mb-md-0">
          <h5 className="fw-normal mb-2">
            {title}
          </h5>
        </div>
      </Card.Header>
      <Card.Body className="text-center py-4 py-xl-5">
        <h3 className="display-3 fw-extrabold mb-0">RM {value}</h3>
        <p>Total Sales</p>
        <Button variant="primary" type="submit" className=" btn btn-primary d-inline-flex align-items-center" onClick={downloadWeeklyReport}>
          Generate Report
        </Button>
      </Card.Body>
      {/* <Card.Footer className="p-2 border-0">
        <SalesValueChartphone />
      </Card.Footer> */}
    </Card>
  );
};


export const MultiValueChart = (props) => {
  const { labels, series } = props;
  return (
    <Card className="bg-secondary-alt shadow">
      <Card.Header className="d-flex flex-row align-items-center flex-0">
        <div className="d-block">
          <h5 className="fw-normal mb-2">
            Bookings
          </h5>
          {/* <h3>REM {value}</h3> */}
          <small className="fw-bold mt-2">
            {/* <span className="me-2">Yesterday</span> */}
            {/* <FontAwesomeIcon icon={percentageIcon} className={`${percentageColor} me-1`} /> */}
            {/* <span className={percentageColor}>
          {percentage}%
        </span> */}
          </small>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <div className="text-center">
          <Button variant="primary" type="submit" className=" btn btn-primary d-inline-flex align-items-center" >
            Generate Report
          </Button>
        </div>
        <SalesValueChart labels={labels} series={series} />
      </Card.Body>
    </Card>
  );
}  