import React from 'react';

const Avatar = (firstName = '', lastName = '') => {
    let initials = '';
    
    // Extract the first letters and convert them to uppercase
    const firstLetter = firstName.charAt(0).toUpperCase();
    const lastLetter = lastName.charAt(0).toUpperCase();

    // Determine the initials based on available letters
    switch (true) {
        case (firstLetter!==  '' && lastLetter !== ''): 
            initials = `${firstLetter}${lastLetter}`;
            break;
        case (firstLetter!==  '' && lastLetter === ''):
            initials = `${firstLetter}`;
            break;
        case (lastLetter!== '' && firstLetter === ''):
            initials = `${lastLetter}`;
            break;
        default:
            initials = '?'; // Fallback for missing initials
    }

    return (
        <div className="avatar">
            {initials}
        </div>
    );
};

export default Avatar;