import React, { useEffect, useState } from 'react';
import { Table, Card, OverlayTrigger, Tooltip, Button } from '@themesberg/react-bootstrap';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import FormatTz from '../../utility/FormatTz';
import Avatar from '../../components/Widgets/Avatar';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../routes';

export default () => {
  const [tours, setTours] = useState([]);
  const currentPage = 1;
  const [isLoading, setIsLoading] = useState(true);
  const perPage = 5;
  const searchQuery = '';
  const history = useHistory();

  useEffect(() => {
    const fetchTours = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/cancelled?page=${currentPage}&pageSize=${perPage}&search=${searchQuery}`);
        setTours(response.data.data);
      } catch (error) {
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      }
    };

    fetchTours();
  }, [currentPage, perPage, searchQuery]);

  const showAllTrips = () => {
    history.push(Routes.CancelledToursHistory.path)
  };
  
  return (
    <div>
      <Card border="light" className="shadow">
        <Card.Header className='d-flex justify-content-between align-items-center'>
            <h5 className="mb-0 upperCase-keyword">Cancelled Trips</h5>
            <Button className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" size='sm' onClick={() => showAllTrips()}>
              See all
            </Button>
        </Card.Header>
        <Card.Body className="pb-5 table-wrapper table-responsive">
          <Table className="table table-centered table-nowrap mb-0 rounded align-items-center">
            <thead className='thead-light'>
              <tr>
                <th className="border-0 rounded-start">Guest name</th>
                <th className="border-0">Phone</th>
                <th className="border-0">Pax</th>
                <th className="border-0">Pick Up Point</th>
                <th className="border-0">Drop Off</th>
                <th className="border-0">Booking At </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                  </tr>
                ))
              ) : (
                tours.length === 0 ? (
                  <tr>
                    <td colSpan="9" className="text-center my-4 no-bottom-border">
                      No data found.
                    </td>
                  </tr>
                ) : (
                  tours.map((tour) => (
                    <tr key={tour.tourId}>
                      <td className='text-capitalize'>
                        <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                          {Avatar(
                            tour.customerName.split(" ")[0], tour.customerName.split(" ")[1]
                          )}
                          <div className='px-2'>
                            {tour.customerName}
                          </div>
                        </div>
                      </td>
                      <td>{tour.customerPhone}</td>
                      <td>{tour.pax}</td>
                      <td>
                        <span className="text-danger">
                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.startLocation}</Tooltip>} >
                            <div className="truncate-text">
                              {tour.startLocation}
                            </div>
                          </OverlayTrigger>
                        </span>
                      </td>
                      <td>
                        <span className="text-success">
                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.endLocation}</Tooltip>} >
                            <div className="truncate-text">
                              {tour.endLocation}
                            </div>
                          </OverlayTrigger>
                        </span>
                      </td>
                      <td>{FormatTz(tour.createdAt)}</td>
                    </tr>
                  ))
                )
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};
