import React from 'react';
import { Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';

export default ({ status, driverId, handleUnassignDriver }) => {
  const canUnassignDriver = ["pending", "driverUnassigned", "driverAssigned", "acceptedByDriver"].includes(status);
  return (
    <div>
      {canUnassignDriver && (
        <Button
          variant="secondary"
          size="sm"
          className="animate-up-2"
          onClick={() => handleUnassignDriver(driverId)}
        >
          <FontAwesomeIcon icon={faUserTie} className="me-2" />
          Unassign Driver
        </Button>
      )}
    </div>
  );
};
