import React, { useEffect, useState } from 'react';
import { Table, Button, Card, Modal, Pagination, OverlayTrigger, Tooltip, InputGroup, Form } from '@themesberg/react-bootstrap';
import axios from 'axios';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import CreateBookingForm from '../components/CreateBookingForm'; // Example import, adjust as needed
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faSearch } from '@fortawesome/free-solid-svg-icons';
import FormatTz from '../../utility/FormatTz';
import Avatar from '../../components/Widgets/Avatar';

export default ({ data }) => {
  const [tours, setTours] = useState([]);
  const [showCreateBookingModal, setShowCreateBookingModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const perPage = 10;
  const [searchQuery, setSearchQuery] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);

  const history = useHistory();

  const fetchTours = React.useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/activeTours?pageNumber=${currentPage}&pageSize=${perPage}&search=${searchQuery}`);
      setTours(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotalRecords(response.data.totalRecords);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.error('Error fetching tours:', error);
    }
  },[currentPage, perPage, searchQuery]);
  
  useEffect(() => {
    fetchTours();
  }, [fetchTours]);

  const handleCloseCreateBookingModal = () => {
    setShowCreateBookingModal(false);
  };

  const handleCreateBookingSubmit = async (formData) => {
    try {
      fetchTours();
      setShowCreateBookingModal(false);
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

  const showTripDetails = (tourId) => {
    history.push(`/tripdetails/${tourId}`);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const startEntry = totalRecords === 0 ? 0 : (currentPage - 1) * perPage + 1;
  const endEntry = Math.min(currentPage * perPage, totalRecords);

  return (
    <div className='mb-6'>
      <Card border="light" className="table-wrapper table-responsive shadow ">
        <Card.Header className='d-flex justify-content-between align-items-center'>
          <div>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control 
                type="text" 
                placeholder="Search by name..."
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1); // Reset to first page when searching
                }}
              />
            </InputGroup>
          </div>
        </Card.Header>
        <Card.Body className="pb-0">
          <Table hover className="table table-centered table-nowrap mb-0 rounded">
            <thead className='thead-light'>
              <tr>
                <th className="border-0 rounded-start">Guest Name</th>
                <th className="border-0">Pick Up Point</th>
                <th className="border-0">Drop Off</th>
                <th className="border-0">Start Date & Time</th>
                <th className="border-0">Net Cost</th>
                <th className="border-0">Commission</th>
                <th className="border-0">assigned driver</th>
                <th className="border-0 rounded-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: perPage }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                  </tr>
                ))
              ) : tours.length === 0 ? (
                <tr>
                  <td colSpan="10" className="text-center my-4 no-bottom-border">
                    No data found.
                  </td>
                </tr>
              ) : (
                tours.map((tour) => (
                  <tr key={tour.tourId}>
                    <td>
                    <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                        {Avatar(
                          tour.customerFullName.split(" ")[0], tour.customerFullName.split(" ")[1]
                        )}
                        <div className='px-2'>
                          {tour.customerFullName}
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-danger">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.startLocation}</Tooltip>} >
                          <div className="truncate-text">
                            {tour.startLocation}
                          </div>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td>
                      <span className="text-success">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.endLocation}</Tooltip>} >
                          <div className="truncate-text">
                            {tour.endLocation}
                          </div>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td>{FormatTz(tour.tourDate)}, {moment(tour.tourTime, 'HH:mm:ss').format('hh:mm A')}</td>
                    <td><span className="fw-bold">RM {tour.netCost}</span></td>
                    <td><span className="fw-bold">RM {tour.fare}</span></td>
                    <td>
                    <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                        {Avatar(
                          tour.driverFirstName, tour.driverLastName
                        )}
                        <div className='px-2'>
                          {tour.driverFirstName} {tour.driverLastName}
                        </div>
                      </div>
                    </td> 
                    <td className='text-center'>
                      <Button className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" size='sm' onClick={() => showTripDetails(tour.tourId)}>
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </Button>
                    </td>                 
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
        {/* Pagination */}
        <Card.Footer className="d-flex justify-content-between align-items-center">
        <small className="fw-bold">
            Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
        </small>
        {tours.length !== 0 && <Pagination className="justify-content-center">
              <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
              {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination> }
        </Card.Footer>
      </Card>


      {/* Create Booking Modal */}
      <Modal as={Modal.Dialog} centered show={showCreateBookingModal} onHide={handleCloseCreateBookingModal} dialogClassName="custom-modal-width">
        <Modal.Header>
          <Modal.Title>Create Booking</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseCreateBookingModal} />
        </Modal.Header>
        <Modal.Body>
          <CreateBookingForm data={data} onSubmit={handleCreateBookingSubmit} onCancel={handleCloseCreateBookingModal} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
