import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import ManageDriversLeaves from "./components/ManageDriversLeaves";

export default (data) => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 pb-5">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Leaves Management</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <h4>Manage Leaves</h4>
            <p className="mb-0">Here is the list of all drivers leaves details.</p>
          </div>
        </div>
      </div>
      <ManageDriversLeaves data={data} />
    </>
  );
};
