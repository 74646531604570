import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button, Card, Pagination, Form, InputGroup, Spinner, Badge } from '@themesberg/react-bootstrap';
import { faDownload, faSortAlphaUp, faSortAlphaDown, faCalendarAlt, faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import Datetime from 'react-datetime';
import toast, { Toaster } from 'react-hot-toast';
import FormatTz from '../../utility/FormatTz';

export default ({ data }) => {
    const [reportsRow, setReportsRow] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const perPage = 10;
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortValue, setSortValue] = useState("asc");
    const filterParam= "";
    const [formData, setFormData] = useState({
        adminName: '',
        reportType: '',
        startDate: '',
        endDate: ''
    });
    const [reportLoading, setReportLoading] = useState(false); // State to handle report loading

    const fetchReports = React.useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/reports`);
            setReportsRow(response.data.data);
            setTotalPages(response.data.totalPages);
            setTotalRecords(response.data.totalRecords);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }, [currentPage, perPage, sortValue, filterParam]);

    useEffect(() => {
        fetchReports();
    }, [fetchReports, reportLoading]);

    const SuccessToast = () => (
        <div className='pxy-2 successToast' style={{ display: 'flex', alignItems: 'center', background:'#fff' }}>
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '8px' }} />
          <span>Your report is ready to download.</span>
        </div>
      );
      
      const ErrorToast = () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faTimes} style={{ color: 'red', marginRight: '8px' }} />
          <span>Oops! Something went wrong.</span>
        </div>
      );

    const downloadWeeklyReport = async () => {
        setReportLoading(true); // Start loading when the report generation starts
        try {
            // Add a new row with a loading indicator
            const newReport = {
                fileName: 'Generating Report...',
                startDate: formData.startDate,
                endDate: formData.endDate,
                reportType: formData.reportType,
                generatedBy: formData.adminName, // You can adjust this value
                link: null, // Initial state before getting the URL
            };
            setReportsRow([newReport, ...reportsRow]);

            // Send request to generate report
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/reports/download`, {
                ...formData
              });

            const updatedReports = reportsRow.map((reportItem) => {
                if (reportItem.fileName === 'Generating Report...') {
                    return {
                        ...reportItem,
                        fileName: 'Weekly Sales Report', // Replace with actual file name if available
                        link: response.data.url, // Assuming response contains URL field
                    };
                }
                return reportItem;
            });
            setReportsRow(updatedReports);
            toast.custom(<SuccessToast />);
            
            setReportLoading(false);
        } catch (error) {
            console.error('Error generating the report', error);
            // Update the row to show failed status
            const updatedReports = reportsRow.map((reportItem) => {
                if (reportItem.fileName === 'Generating Report...') {
                    return {
                        ...reportItem,
                        fileName: 'Report Generation Failed',
                        link: null,
                    };
                }
                return reportItem;
            });

            setReportsRow(updatedReports);
            setReportLoading(false);
            toast.custom(<ErrorToast />);
        }
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const startEntry = (currentPage - 1) * perPage + 1;
    const endEntry = Math.min(currentPage * perPage, totalRecords);

    const handleSort = () => {
        setSortValue(sortValue === "asc" ? "desc" : "asc");
    };

    const handleStartDateChange = (date) => {
        setFormData({
            ...formData,
            startDate: moment(date).format('YYYY-MM-DD'),
        });
    };

    const handleEndDateChange = (date) => {
        setFormData({
            ...formData,
            endDate: moment(date).format('YYYY-MM-DD')
        });
    };

    const today = moment(); // Current date and time
    const valid = function (current) {
        return current.isSameOrBefore(today);
    };

    const handleReportType = (event) => {
        // Get the selected value from the event target
        const selectedValue = event.target.value;

        // Update the state with the selected value
        setFormData({
            ...formData,
            reportType: selectedValue,
            adminName: data.data.username
        });
    };

    const validateForm = () => {
        let error = '';
        let isValid = true;

        if (!formData.reportType || formData.reportType === "select") {
            error = 'Report Type is required';
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            downloadWeeklyReport();
        }
    };

    const getBadgeColor = (reportType) => {
        switch (reportType) {
          case 'active trips':
            return 'success'; // Green
          case 'upcoming trips':
            return 'info'; // Blue
          case 'completed trips':
            return 'primary'; // Dark Blue/Green
          case 'cancelled trips':
            return 'danger'; // Red
          case 'driver reports':
            return 'warning'; // Yellow/Gold
          case 'expense reports':
            return 'orange'; // Orange
          case 'tour reports':
            return 'secondary'; // Purple/Lavender
          case 'driver quality':
            return 'teal'; // Teal
          default:
            return 'tertiary'; // Default color
        }
      };
      

    return (
        <div>
            <Card border="light" className="table-wrapper table-responsive shadow">
                <Card.Header>
                    <Toaster />
                    <Form onSubmit={handleSubmit} className='d-block-sm-md d-flex justify-content-between align-items-end'>
                        <Form.Group>
                            <Form.Label>Report Type</Form.Label>
                            <Form.Select
                                defaultValue="0"
                                className='f-14 w-200 mb-1-sm-md w-100-sm-md'
                                value={formData.reportType}
                                onChange={handleReportType}
                            >
                                <option value="select">Select Type</option>
                                <option value="completed trips">Completed Trips</option>
                                <option value="driver expenses">Driver Expenses</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="startDate" className='mb-1-sm-md'>
                            <Form.Label>Start Date</Form.Label>
                            <Datetime
                                timeFormat={false}
                                closeOnSelect={true}
                                onChange={handleStartDateChange}
                                isValidDate={valid}
                                value={formData.startDate}
                                inputProps={{
                                    name: 'startDate',
                                    required: true,
                                    placeholder: 'mm/dd/yyyy',
                                }}
                                required
                                renderInput={(props, openCalendar) => (
                                    <InputGroup onClick={openCalendar}>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                        </InputGroup.Text>
                                        <Form.Control
                                            value={formData.startDate ? moment(formData.startDate).format('YYYY-MM-DD') : ''}
                                            placeholder='Select date'
                                            required
                                        />
                                    </InputGroup>
                                )}
                            />
                        </Form.Group>
                        <Form.Group controlId="endDate" className='mb-1-sm-md'>
                            <Form.Label>End Date</Form.Label>
                            <Datetime
                                timeFormat={false}
                                closeOnSelect={true}
                                onChange={handleEndDateChange}
                                isValidDate={valid}
                                value={formData.endDate}
                                inputProps={{
                                    name: 'endDate',
                                    required: true,
                                    placeholder: 'mm/dd/yyyy',
                                }}
                                required
                                renderInput={(props, openCalendar) => (
                                    <InputGroup onClick={openCalendar}>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                        </InputGroup.Text>
                                        <Form.Control
                                            value={formData.endDate ? moment(formData.endDate).format('YYYY-MM-DD') : ''}
                                            placeholder='Select date'
                                            required
                                        />
                                    </InputGroup>
                                )}
                            />
                        </Form.Group>
                        <div>
                            <Button variant="primary" className="animate-up-2" type="submit" disabled={loading || reportLoading}>
                                {reportLoading ? (
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        <span className="ms-2">Generating...</span>
                                    </>
                                ) : (
                                    'Generate Report'
                                )}
                            </Button>
                        </div>
                    </Form>
                </Card.Header>
                <Card.Body className="pb-0">
                    <Table hover className="table table-centered table-nowrap mb-0 rounded align-items-center">
                        <thead className='thead-light'>
                            <tr>
                                <th className="border-0">
                                    <Button bsPrefix="text" href="#" variant="primary" onClick={handleSort}>
                                        <span className='f-12 me-2'>
                                            File Name
                                        </span>
                                        {sortValue === "desc"
                                            ? <FontAwesomeIcon icon={faSortAlphaUp} className='f-12 me-2' />
                                            : <FontAwesomeIcon icon={faSortAlphaDown} className='f-12 me-2' />}
                                    </Button>
                                </th>
                                <th className="border-0">Start - End Date</th>
                                <th className="border-0">Report Type</th>
                                <th className="border-0">Generated By</th>
                                <th className='border-0'>Date</th>
                                <th className="border-0 text-center rounded-end">Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                Array.from({ length: 5 }).map((_, index) => (
                                    <tr key={index}>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                    </tr>
                                ))
                            ) : reportsRow.length === 0 ? (
                                <tr>
                                    <td colSpan="7" className="text-center my-4 no-bottom-border">
                                        No data found.
                                    </td>
                                </tr>
                            ) : (
                                reportsRow.map((report, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {report.fileName || 'No Name'}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {moment(report.startDate).format('D MMM YYYY')} 
                                                </span>
                                                 <span> - </span> 
                                                 <span className='upperCase-keyword'>
                                                {moment(report.endDate).format('D MMM YYYY')}
                                            </span>
                                        </td>
                                        <td>
                                            <Badge className={`f-12 upperCase-keyword badge-lg badge bg-${getBadgeColor(report.reportType)}`}>
                                                {report.reportType}
                                            </Badge>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {report.generatedBy || 'Unknown'}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {FormatTz(report.createdDate) || 'Unknown'}
                                            </span>
                                        </td>
                                        <td className='text-center'>
                                            {report.link ? (
                                                <a href={report.link} className="btn btn-success me-2 f-12 cursor-pointer animate-up-2" size='sm' target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </a>
                                            ) : reportLoading && report.fileName === 'Generating Report...' ? (
                                                <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                                            ) : (
                                                <span>Failed</span>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between align-items-center">
                    <small className="fw-bold">
                        Showing <b>{startEntry} to {endEntry}</b> out of <b>{totalRecords}</b> entries
                    </small>
                    {reportsRow.length !== 0 && <Pagination className="justify-content-center">
                        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                        {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        />
                    </Pagination>}
                </Card.Footer>
            </Card>
        </div>
    );
};
