import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Breadcrumb } from '@themesberg/react-bootstrap';
import CreateBookingForm from '../components/CreateBookingForm';

const CreateBooking = ({ data }) => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Manual Booking</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Create new booking</h4>
          <p className="mb-0">Use this form to create a new booking.</p>
        </div>
      </div>
      <Row>
        <Col xs={12} md={12}>
          <CreateBookingForm data={data} />
        </Col>
      </Row>
    </>
  );
};

export default CreateBooking;