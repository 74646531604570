import React, { useEffect, useState } from "react";
import Chartist from "react-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export const SalesValueChart = ({ data }) => {
  
 // Add a December data point before January to start the graph from 0
 const modifiedData = {
  ...data,
  labels: ['', ...data.labels], // Add December at the beginning
  series: data.series.map(series => ({
    ...series,
    data: [0, ...series.data]  // Add 0 for December in all series
  }))
};

const options = {
  low: 0,  // Ensure the graph starts from 0
  showArea: true,
  fullWidth: true,
  axisX: {
    position: 'end',
    showGrid: true
  },
  axisY: {
    showGrid: true,
    showLabel: true,
    labelInterpolationFnc: function(value, index) {
      // Calculate the label as a sequence number starting from 1
      let sequenceNumber = Math.round(value);
  
      // Display the label only for even indices
      return index % 2 === 0 ? sequenceNumber : null;
    }
  },
  showLine: true,
  lineSmooth: true,
  plugins: [ChartistTooltip()]
};

  const seriesColors = {
    Bookings: '#e24e2b',          // Blue color for Bookings
    'Completed Trips': '#fa6869', // Green color for Completed Trips
    Cancellations: '#a44742'      // Red color for Cancellations
  };

  const gradients = {
    Bookings: { from: '#468CD8', to: 'rgba(70, 140, 216, 0)' },
    'Completed Trips': { from: '#05A677', to: 'rgba(5, 166, 119, 0)' },
    Cancellations: { from: '#DC2E48', to: 'rgba(220, 46, 72, 0)' }
  };

  const createGradientDefs = () => (
    <defs>
      <linearGradient id="gradient-bookings" x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor={gradients.Bookings.from} />
        <stop offset="100%" stopColor={gradients.Bookings.to} />
      </linearGradient>
      <linearGradient id="gradient-completed" x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor={gradients['Completed Trips'].from} />
        <stop offset="100%" stopColor={gradients['Completed Trips'].to} />
      </linearGradient>
      <linearGradient id="gradient-cancellations" x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor={gradients.Cancellations.from} />
        <stop offset="100%" stopColor={gradients.Cancellations.to} />
      </linearGradient>
    </defs>
  );

  // Apply the color to the series dynamically
  const modifiedSeries = modifiedData.series.map((series, index) => ({
    ...series,
    className: `ct-series-${String.fromCharCode(97 + index)}`, // Create a class for each series (e.g., ct-series-a, ct-series-b)
    lineSmooth: true
  }));
  
  return (
    <div>
      <Chartist
        data={{ ...modifiedData, series: modifiedSeries }}
        options={{ ...options }}
        type="Line"
        className="ct-chart-sales-value ct-double-octave bg-transparent"
        style={{ position: 'relative' }}
      >
                {createGradientDefs()}

        </Chartist>
          {/* Legend Section */}
    <div className="chart-legend my-2 mb-4 d-flex justify-content-center align-items-center">
      <div className="legend-item me-4">
        <FontAwesomeIcon icon={faCircle} style={{ color: seriesColors.Bookings }} className="me-2"/>
        <span className="legend-text">Bookings</span>
      </div>
      <div className="legend-item me-4">
        <FontAwesomeIcon icon={faCircle} style={{ color: seriesColors['Completed Trips'] }} className="me-2"/>
        <span className="legend-text">Completed</span>
      </div>
      <div className="legend-item me-4">
        <FontAwesomeIcon icon={faCircle} style={{ color: seriesColors.Cancellations }} className="me-2"/>
        <span className="legend-text">Cancellations</span>
      </div>
    </div>
            <style jsx>{`
        .ct-series-a .ct-line, .ct-series-a .ct-point {
          stroke: ${seriesColors.Bookings};
        }
        .ct-series-b .ct-line, .ct-series-b .ct-point {
          stroke: ${seriesColors['Completed Trips']};
        }
        .ct-series-c .ct-line, .ct-series-c .ct-point {
          stroke: ${seriesColors.Cancellations};
        }
        .ct-chart-sales-value {
          background-color: #DC2E48; /* Set background color */
        }
      `}</style>
    </div>
  );
};


export const SalesValueChartphone = () => {
  const [data, setChartData] = useState({
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    series: [[1, 2, 2, 3, 3, 4, 3]]
  });

  const options = {
    low: 0,
    showArea: true,
    fullWidth: false,
    axisX: {
      position: 'end',
      showGrid: false
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: false,
      labelInterpolationFnc: value => `$${value / 1}k`
    },
    plugins: [
      ChartistTooltip()
    ]
  };

  useEffect(() => {
    // Fetch data using Axios (assuming an endpoint)
    axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchDataForOrdersWidget`)
      .then(response => {
        const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        let seriesArray = new Array(daysOfWeek.length).fill(0);
        response.data.forEach(item => {
            const index = daysOfWeek.indexOf(item.day_of_week);
            if (index !== -1) {
                seriesArray[index] = parseFloat(item.total_revenue); // Convert string to float
            }
        });
        const updated = {
            labels: daysOfWeek,
            series: [seriesArray]
        };

        setChartData(updated);
      })
      .catch(error => {
        console.error('Error fetching sales data:', error);
      });
  }, []); // Empty dependency array means it runs once on mount

  return (
    <Chartist data={data} options={{...options}} type="Bar" />
  );
};


export const CircleChart = (props) => {
  const { series = [0, 0] } = props;
  const sum = (a, b) => a + b;

  const options = {
    labelInterpolationFnc: value => {
      const totalSeries = series.reduce(sum);
      return totalSeries > 0 
        ? `${Math.round((value / totalSeries) * 100)}%` 
        : '0%'; // Avoid NaN by returning '0%' if total is 0
    },
    classNames: {
      slice: 'ct-chart', // Class name for slices
    },
  };

  const plugins = [
    ChartistTooltip()
  ];

  // Generate the chart data with colors
  const chartData = { series: series };

  return (
    <Chartist 
      data={chartData} 
      options={{ ...options, plugins }} 
      type="Pie" 
      className="ct-golden-section" 
    />
  );
};


export const BarChart = (props) => {
  const { labels = [], series = [], chartClassName = "ct-golden-section" } = props;
  const data = { labels, series };

  const options = {
    low: 0,
    showArea: true,
    axisX: {
      position: 'end'
    },
    axisY: {
      showGrid: false,
      showLabel: false,
      offset: 0
    }
  };

  const plugins = [
    ChartistTooltip()
  ]

  return (
    <Chartist data={data} options={{...options, plugins}} type="Bar" className={chartClassName} />
  );
};

export const StackedBarChart = (props) => {
  const { labels = [], series = [], chartClassName = "ct-golden-section" } = props;

  // Ensure series is structured for stacked bars
  const stackedSeries = series.map((s, index) => {
    return { name: `Series ${index + 1}`, data: s }; // Add a name for each series
  });

  const data = {
    labels,
    series: stackedSeries,
  };

  const options = {
    high: Math.max(...series.flat()) + 10, // Adjust for max value
    low: 0,
    stackBars: true, // Enable stacked bars
    showArea: true,
    axisX: {
      position: 'end',
    },
    axisY: {
      showGrid: false,
      showLabel: false,
      offset: 0,
    },
  };

  const plugins = [
    ChartistTooltip()
  ];

  return (
    <Chartist data={data} options={{ ...options, plugins }} type="Bar" className={chartClassName} />
  );
};
