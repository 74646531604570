import React from "react";
import { Col, Row } from '@themesberg/react-bootstrap';
import  ProfilePage  from "../components/ProfilePage";

export default (data) => {
  return (
    <>
      <Row>
        <Col xs={12} xl={12}>
        <ProfilePage userData={data} />
        </Col>
      </Row>
    </>
  );
};
