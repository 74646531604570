import { Card, ListGroup } from '@themesberg/react-bootstrap';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Avatar from '../../components/Widgets/Avatar';
import AvatarImage from '../../components/Widgets/AvatarImage';


// React component for displaying upcoming leaves
export default () => {
  const [tours, setTours] = useState([]);


  const fetchUsers = React.useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/dashboard/drivers/top`);
      setTours(response.data);
    } catch (error) {
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <div>
      <Card border="light" className="table-wrapper table-responsive shadow height-600">
        <Card.Header className='d-flex justify-content-between align-items-center'>
          <h5 className="mb-0 upperCase-keyword">Top Drivers
            <span className='f-10 mx-2 text-muted d-iblock text-italic'>(Based on trips completed)</span>
          </h5>
        </Card.Header>
        <Card.Body className="py-0">
          <ListGroup>
            {tours.length === 0 ? (
              <tr>
                <td colSpan="10" className="text-center my-4 no-bottom-border">
                  No data found.
                </td>
              </tr>
            ) : (
              tours.map((tour) => (
                <ListGroup.Item
                  key={tour.tourId}
                  className="d-flex justify-content-between align-items-center bg-transparent border-bottom py-3 px-0"
                >
                  <div className="d-flex align-items-center">
                  {tour.image || tour.image !== "" ? AvatarImage(tour.image) : Avatar(
                                                        tour.firstName, tour.lastName
                                                    )}
                    <div className="px-2">
                      <span className="fw-bold">{`${tour.firstName} ${tour.lastName}`}</span>
                    </div>
                  </div>
                  <div className="fw-bold">#{tour.completedTrips}</div>
                </ListGroup.Item>
              )))}
          </ListGroup>
        </Card.Body>
      </Card>
    </div>
  )
};