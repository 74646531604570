import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import 'react-loading-skeleton/dist/skeleton.css';
import DashboardOverview from "./dashboard/DashboardOverview";
import ToursHistory from "./ToursHistory";
import Signin from "./examples/Signin";
import Customers from './Customers';
import Profile from './Profile';
import Drivers from './Drivers';
import CreateBooking from './examples/CreateBooking';

import WithAuth from '../components/Authentication/WithAuth';
import RouteWithLoader from '../RoutesWithLoader';
import RouteWithSidebar from '../RoutesWithSidebar';
import UpcomingTours from './UpcomingTours';
import Signup from './examples/Signup';
import CreateTour from './CreateTour';
import DriverDetailsPage from './examples/DriversDetailsPage';
import TripDetails from '../components/SubComponents/TripDetails';
import ActiveTours from './ActiveTours';
import NewBookings from './NewBookings';
import AdminConfirmation from './examples/AdminConfirmation';
import ManageAdminsManagement from './ManageAdminsManagement';
import ManageDriversManagement from './ManageDriversManagement';
import Reports from './Reports';
import ManageExpensesManagement from './ManageExpensesManagement';
import PastTripDetails from '../components/SubComponents/PastTripDetails';
import ManageVehiclesManagement from './ManageVehiclesManagement';
import AllNotifications from './AllNotifications';
import CancelledTripsWrapper from './CancelledTripsWrapper';
import PendingExpensesManagement from './PendingExpensesManagement';
import ReviewedExpensesManagement from './ReviewedExpensesManagement';
import CreateDriverForm from './components/CreateDriverForm';
import ManageDriversLeavesManagement from './ManageDriversLeavesManagement';
import ManageDriverComplaint from './ManageDriverComplaint';
import ComplaintDetails from '../components/SubComponents/ComplaintComponents/ComplaintDetails';
import ManagePayoutsManagement from './ManagePayoutsManagement';


const RoutesConfig = () => (
  <>
    <Switch>
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
      <RouteWithLoader exact path={Routes.AdminConfirmation.path} component={AdminConfirmation} />

      {/* Admin routes */}
      <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={WithAuth(DashboardOverview)} />
      <RouteWithSidebar exact path={Routes.NewBookings.path} component={WithAuth(NewBookings)} role="admin" />
      <RouteWithSidebar exact path={Routes.UpcomingTours.path} component={WithAuth(UpcomingTours)} role="admin" />
      <RouteWithSidebar exact path={Routes.ActiveTours.path} component={WithAuth(ActiveTours)} role="admin" />
      <RouteWithSidebar exact path={Routes.ToursHistory.path} component={WithAuth(ToursHistory)} role="admin" />
      <RouteWithSidebar exact path={Routes.CancelledToursHistory.path} component={WithAuth(CancelledTripsWrapper)} role="admin" />
      <RouteWithSidebar exact path={Routes.Customers.path} component={WithAuth(Customers)} role="admin" />
      <RouteWithSidebar exact path={Routes.GetAllDrivers.path} component={WithAuth(Drivers)} role="admin" />
      <RouteWithSidebar exact path={Routes.Profile.path} component={WithAuth(Profile)} role="admin" />
      <RouteWithSidebar exact path={Routes.CreateBooking.path} component={WithAuth(CreateBooking)} role="admin" />
      <RouteWithSidebar exact path={Routes.CreateTour.path} component={WithAuth(CreateTour)} role="admin" />
      <RouteWithSidebar exact path={Routes.GetDriverDetails.path} component={WithAuth(DriverDetailsPage)} />
      <RouteWithSidebar exact path={Routes.GetTripDetails.path} component={WithAuth(TripDetails)} />
      <RouteWithSidebar exact path={Routes.GetPastTripDetails.path} component={WithAuth(PastTripDetails)} />
      <RouteWithSidebar exact path={Routes.CreateDriver.path} component={WithAuth(CreateDriverForm)} role="admin" />
      <RouteWithSidebar exact path={Routes.AdminManagement.path} component={WithAuth(ManageAdminsManagement)} />
      <RouteWithSidebar exact path={Routes.DriverManagement.path} component={WithAuth(ManageDriversManagement)} />
      <RouteWithSidebar exact path={Routes.LeavesManagement.path} component={WithAuth(ManageDriversLeavesManagement)} />
      <RouteWithSidebar exact path={Routes.VehiclesManagement.path} component={WithAuth(ManageVehiclesManagement)} />
      <RouteWithSidebar exact path={Routes.ExpensesManagement.path} component={WithAuth(ManageExpensesManagement)} />
      <RouteWithSidebar exact path={Routes.DriverComplaintManagement.path} component={WithAuth(ManageDriverComplaint)} />
      <RouteWithSidebar exact path={Routes.ReviewPendingExpenses.path} component={WithAuth(PendingExpensesManagement)} />
      <RouteWithSidebar exact path={Routes.ReviewCompletedExpenses.path} component={WithAuth(ReviewedExpensesManagement)} />
      <RouteWithSidebar exact path={Routes.Reports.path} component={WithAuth(Reports)} />
      <RouteWithSidebar exact path={Routes.Notifications.path} component={WithAuth(AllNotifications)} />
      <RouteWithSidebar exact path={Routes.DriverComplaintDetails.path} component={WithAuth(ComplaintDetails)} /> 
      <RouteWithSidebar exact path={Routes.Payouts.path} component={WithAuth(ManagePayoutsManagement)} /> 

    
      <Redirect to={Routes.NotFound.path} />
    </Switch>
  </>
);

export default RoutesConfig;