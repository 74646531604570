import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import ManageComplaints from "./components/ManageComplaints";

export default (data) => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Driver Complaint</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Driver Complaints</h4>
          <p className="mb-0">Here, you can review all violations committed by drivers in the system. Each entry includes detailed information about the violation, including the driver’s details, violation category, summary, and priority level. Take necessary actions like assigning penalties, reviewing the violation history, and improving driver performance.</p>
        </div>
      </div>
      <ManageComplaints data={data} />
    </>
  );
};
