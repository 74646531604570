import React, { useState, useEffect, useRef, useCallback } from "react";
import { Image, Dropdown, Nav, ListGroup, Row, Col } from "@themesberg/react-bootstrap";
import Bell from "../../assets/img/icons/bell.svg";
import axios from "axios";
import moment from "moment-timezone";

export default ({ data }) => {
    const driverId = data.id;
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const perPage = 10;
    const containerRef = useRef(null);

    const fetchNotifications = useCallback(
        async (page = 1) => {
            if (page > totalPages) return;
            setIsLoading(true);
            try {
                const offset = (page - 1) * perPage;
                const response = await axios.get(
                    `https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/notifications/admins`,
                    {
                        params: { driverId, limit: perPage, offset },
                    }
                );
                const { data, totalPages } = response.data;
                setNotifications((prev) => [...prev, ...data]);
                setTotalPages(totalPages);
                setCurrentPage(page);
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        },
        [driverId, perPage, totalPages]
    );

    useEffect(() => {
        fetchNotifications(1);
    }, [fetchNotifications]);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;
        if (!container) return;

        const { scrollTop, scrollHeight, clientHeight } = container;
        if (scrollHeight - scrollTop <= clientHeight + 10 && !isLoading && currentPage < totalPages) {
            fetchNotifications(currentPage + 1);
        }
    }, [fetchNotifications, isLoading, currentPage, totalPages]);

    const formatCreatedAt = (createdAt) => {
        const malaysiaTimezone = "Asia/Kuala_Lumpur";
        const now = moment.tz(moment(), malaysiaTimezone);
        const created = moment.tz(createdAt, malaysiaTimezone);
        const minutesAgo = now.diff(created, "minutes");
        const hoursAgo = now.diff(created, "hours");
        const daysAgo = now.diff(created, "days");

        if (minutesAgo < 1) return "Just now";
        if (minutesAgo < 60) return `${minutesAgo} min${minutesAgo > 1 ? "s" : ""} ago`;
        if (hoursAgo < 24) return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
        if (daysAgo === 1) return "1 day ago";
        if (daysAgo <= 7) return `${daysAgo} days ago`;
        return created.format("D MMM, YYYY, h:mm A");
    };

    const NotificationItem = ({ id, createdAt, message, isRead }) => {
        const updatedCreateAt = formatCreatedAt(createdAt);
        const readClassName = isRead ? "" : "text-danger";

        return (
            <ListGroup.Item className="border-bottom border-light" key={`notification-${id}`}>
                <Row className="align-items-center">
                    <Col>
                        <p className="font-small mt-1 mb-2">{message}</p>
                    </Col>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="text-end f-12"><small className={readClassName}>{updatedCreateAt}</small></div>
                    </div>
                </Row>
            </ListGroup.Item>
        );
    };

    return (
        <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link} className="icon-notifications py-0">
                <span className="icon navbar-avatar-20">
                    <Image src={Bell} className="rounded-circle text-dark bell-shake" />
                    {notifications.some((notif) => !notif.isRead) && (
                        <span className="icon-badge rounded-circle unread-notifications" />
                    )}
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                    <Nav.Link
                        className="text-center text-primary fw-bold border-bottom border-light py-3"
                    >
                        Notifications
                    </Nav.Link>
                    <div
                        style={{ maxHeight: "600px", overflowY: "auto" }}
                        ref={containerRef}
                        onScroll={handleScroll}
                    >
                        {notifications.map((n) => (
                            <NotificationItem key={`notification-${n.id}`} {...n} />
                        ))}
                        {isLoading && (
                            <ListGroup.Item className="text-center">
                                <span>Loading...</span>
                            </ListGroup.Item>
                        )}
                        {!isLoading && notifications.length === 0 && (
                            <ListGroup.Item className="text-center">
                                <span>No notifications</span>
                            </ListGroup.Item>
                        )}
                    </div>
                </ListGroup>
            </Dropdown.Menu>
        </Dropdown>
    );
};