import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Image, Navbar, Dropdown, Container, Nav } from '@themesberg/react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';

import Preloader from "./Preloader";
import NotificationsWrapper from "./Widgets/NotificationsWrapper";
import RegisterDriverComplaint from "../pages/components/RegisterDriverComplaint";
import DriverLeavePlan from "../pages/components/DriverLeavePlan";
import { Routes } from "../routes";


const NavbarComponent = ({ data }) => {
  const history = useHistory();
  const [redirect, setRedirect] = useState(false); // State to control redirection
  const [loading, setLoading] = useState(false); // State to control loading spinner
  const image = data.image === "Not available" ? "https://transferappdata.s3.ap-southeast-1.amazonaws.com/user.png" : data.image;
  
  const handleShowProfile = () => {
    history.push(Routes.Profile.path);
  };

  const handleLogout = () => {
    setLoading(true); 
    localStorage.removeItem('token');
    setTimeout(() => {
      setRedirect(true);
      setLoading(false);
    }, 1000);
  };

  if (loading) {
    return <Preloader show={true} />; // Show loading spinner while logging out
  }

  if (redirect) {
    return <Redirect to="/sign-in" />; // Redirect to login page after logout
  }

  return (
    <Navbar expanded className="ps-0 pe-4 pb-0 d-none d-sm-block">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div>
            <RegisterDriverComplaint data={data}/>
            <DriverLeavePlan data={data} />
          </div>
          <Nav className="align-items-center">
            <NotificationsWrapper data={data} />
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <span className="navbar-avatar-30">
                    <Image src={image} className="rounded-circle profile-image-nav-size" />
                  </span>
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="d-lg-block mb-0 font-small fw-bold text-capitalize">{data.username}</span>                
                    <span className="d-lg-block mb-0 font-small fw-italic text-capitalize">{data.role}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold" onClick={handleShowProfile}>
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
