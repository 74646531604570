import React from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export default (date) => {
  return moment.utc(date).tz('Asia/Kuala_Lumpur').format('D MMM')
};

export const createdATFormat = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD MMM hh:mm A");
};

export const validateExpiryDate = (expiryDate) => {
  const expiryMoment = moment.utc(expiryDate).tz('Asia/Kuala_Lumpur');
  // Get the current date in Malaysia time
  const now = moment.tz('Asia/Kuala_Lumpur');
  const daysRemaining = expiryMoment.diff(now, 'days');

  if (daysRemaining <= 90 && daysRemaining >= 0) {
    return true
  } else {
    return false
  }
}

// Function to check for missing or invalid fields
export const checkMissingFields = (record) => {
  const missingFields = [];

  // Validate expiry dates and check for missing fields
  if (!record.rcExpiryDate || record.rcExpiryDate.trim() === "") {
    missingFields.push("RC Expiry Date");
  } else {
    const rcExpValidation = validateExpiryDate(record.rcExpiryDate);
    if (rcExpValidation) {
      missingFields.push(`RC Expiry Date`);
    }
  }

  if (!record.insuranceExpiryDate || record.insuranceExpiryDate.trim() === "") {
    missingFields.push("Insurance Expiry Date");
  } else {
    const insuranceExpValidation = validateExpiryDate(record.insuranceExpiryDate);
    if (insuranceExpValidation) {
      missingFields.push(`Insurance Expiry Date`);
    }
  }

  if (!record.permitExpiryDate || record.permitExpiryDate.trim() === "") {
    missingFields.push("Permit Expiry Date");
  } else {
    const permitExpValidation = validateExpiryDate(record.permitExpiryDate);
    if (permitExpValidation) {
      missingFields.push(`Permit Expiry Date`);
    }
  }

  // Return the result
  if (missingFields.length > 0) {
    return (
      <ul>
        {missingFields.map((field, index) => (
          <li className="f-14" key={index}>
            <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red", marginRight: "8px" }} />
            {field}
          </li>
        ))}
      </ul>
    );
  } else {
    return (
      <p className="f-14 mb-0">
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green", marginRight: "8px" }} />
        <span>All fields are valid.</span>
      </p>
    );
  }
};