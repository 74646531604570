import { Table, Card, Button } from '@themesberg/react-bootstrap';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Avatar from '../../components/Widgets/Avatar';
import AvatarImage from '../../components/Widgets/AvatarImage';
import { Routes } from '../../routes';
import { useHistory } from 'react-router-dom';

// React component for displaying upcoming leaves
const UpcomingLeaves = () => {
  const history = useHistory();
  const [drivers, setDrivers] = useState('');
  const fetchUsers = React.useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/upcomingLeaves?role=admin`);
      setDrivers(response.data.leavePlans);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);


  // Convert date string to a readable format
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  const formatFullName = (firstName, lastName) => {
    const formattedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    const formattedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
    return `${formattedFirstName} ${formattedLastName}`;
  };

  const showAllLeaves = () => {
    console.log("leaves")
      history.push(Routes.LeavesManagement.path)
  };
  
  return (
    <Card border="light" className="table-wrapper table-responsive shadow mb-5 height-600">
      <Card.Header className='d-flex justify-content-between align-items-center'>
        <h5 className="mb-0 upperCase-keyword">driver leaves</h5>
        <Button className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" size='sm' onClick={() => showAllLeaves()}>
              See all
        </Button>
      </Card.Header>
      <Card.Body className="pb-0">

        {drivers.length > 0 ? (
          <Table className="table table-centered table-nowrap mb-4 rounded align-items-center">
            <thead className='thead-light'>
              <tr>
                <th className="border-0 rounded-start">Name</th>
                <th className="border-0" >Leave Type</th>
                <th className="border-0 rounded-end">Leave Period</th>
              </tr>
            </thead>
            <tbody>
              {drivers.map((leave) => (
                <tr key={leave.id}>
                  <td>
                    <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>

                      {leave.image ? AvatarImage(leave.image) : Avatar(
                              leave.firstName, leave.lastName
                      )}
                      <div className='px-2'>
                        {formatFullName(leave.firstName, leave.lastName)}
                        <p className='text-lowercase f-12 mb-0'> {leave.email} </p>
                      </div>
                    </div>
                  </td>
                  <td className='text-capitalize text-center'>{leave.leaveType}</td>
                  <td>{formatDate(leave.leaveStartDate)} - {formatDate(leave.leaveEndDate)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No leaves are scheduled for any driver.</p>
        )}
      </Card.Body>
    </Card>
  );
};

export default UpcomingLeaves;