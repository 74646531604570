import React, { useEffect, useState } from 'react';
import { Table, Card, Pagination } from '@themesberg/react-bootstrap';
import axios from 'axios';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';

export default () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const perPage = 10;
    const searchQuery = '';
    const [totalRecords, setTotalRecords] = useState(0);
    const sortValue = "asc";
    const filterParam = "";

    const fetchUsers = React.useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/expenses/all?pageNumber=${currentPage}&pageSize=${perPage}`);
            setUsers(response.data.data);
            setTotalPages(response.data.totalPages);
            setTotalRecords(response.data.totalCount);
            setLoading(false); 
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }, [currentPage, perPage, searchQuery, sortValue, filterParam]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const startEntry = (currentPage - 1) * perPage + 1;
    const endEntry = Math.min(currentPage * perPage, totalRecords);


    return (
        <div>
            <Card border="light" className="table-wrapper table-responsive shadow">
                <Card.Body className="pb-0">
                    <Table hover className="table table-centered table-nowrap mb-0 rounded align-items-center">
                        <thead className='thead-light'>
                            <tr>
                                <th className="border-0">Guest Name</th>
                                <th className="border-0">Driver Name</th>
                                <th className="border-0">Expenses added on</th>
                                <th className="border-0">Total Expenses</th>
                                <th className="border-0">Approved Expenses</th>
                                <th className="border-0">Rejected Expenses</th>
                                <th className="border-0">Reviewed by</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                Array.from({ length: 5 }).map((_, index) => (
                                    <tr key={index}>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                    </tr>
                                ))
                            ) : users.length === 0 ? (
                                <tr>
                                    <td colSpan="9" className="text-center my-4 no-bottom-border">
                                        No data found.
                                    </td>
                                </tr>
                            ) : (
                                users.map((user) => (
                                    <tr className='cursor-pointer' key={user.userId}>
                                        {/* <td>
                                            {user.tourListTourName}
                                        </td> */}
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {user.guestName}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {user.driverName}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {moment(user.bookingDate).format('D MMM HH:mm')}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                RM {user.totalExpenses}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                RM {user.approvedExpenses}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                RM {user.rejectedExpenses}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {user.adminFirstName} {user.adminLastName} 
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between align-items-center">
                    <small className="fw-bold">
                        Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
                    </small>
                    {users.length !== 0 && <Pagination className="justify-content-center">
                        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                        {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        />
                    </Pagination>}
                </Card.Footer>
            </Card>
        </div>
    );
};
