import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Table, Badge, Card } from '@themesberg/react-bootstrap';
import axios from 'axios';
import toast from 'react-hot-toast';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';

export default ({ adminId, tourId }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [reviewedExpenseId, setReviewedExpenseId] = useState(null);
    const [selectedExpense, setSelectedExpense] = useState(null);
    const [reviewComment, setReviewComment] = useState('');
    const [selectedReason, setSelectedReason] = useState('');
    const reasons = [
        "Missing Documentation",
        "Incorrect Amount",
        "Non-Eligible Expense",
        "Policy Violation",
        "Duplicate Submission",
        "Incomplete Information",
        "Incorrect Format",
        "Expired Documentation",
        "Unreasonable Amount",
        "Other"
    ];

    // Fetch trip data
    const fetchTripData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/${tourId}/Expenses`);
            setData(response.data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }, [tourId]);

    useEffect(() => {
        fetchTripData();
    }, [fetchTripData, tourId]);

    const handleReviewClick = (expense) => {
        setSelectedExpense(expense);
        setReviewedExpenseId(expense.expenseId);
    };

    const handleSubmitReview = async (action, expense = selectedExpense) => {
        if (action === 'reject' && !selectedReason) {
            toast.error('Please select a reason for rejection.');
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/expenses/${expense.expenseId}/review`, {
                status: action === "reject" ? "rejected" : "approved",
                reason: selectedReason,
                comment: reviewComment,
                adminId: adminId || null,
                expenseAmount: expense.amount
            });
            fetchTripData();
            toast.success('Review submitted successfully');
            setReviewedExpenseId(null); // Close the review div
            setSelectedExpense(null); // Reset selected expense
            setReviewComment(''); // Clear comment
            setSelectedReason(''); // Clear reason
        } catch (error) {
            toast.error(error.response.data.error);
        }
    };

    const handleApproveExpense = async (status, expense) => {
        setSelectedExpense(expense);
        await handleSubmitReview(status, expense);
    };

    return (
        <div className='table-overflow'>
            <Table className="table table-centered table-nowrap mb-0 rounded align-items-center">
                <thead className='thead-light thead-sticky'>
                <tr>
                <th className="border-0 rounded-start text-center" style={{ width: '5%' }}>#</th>
                <th className="border-0 text-center" style={{ width: '20%' }}>Amount</th>
                <th className="border-0 text-center" style={{ width: '10%' }}>Type</th>
                <th className="border-0 text-center" style={{ width: '20%' }}>Description</th>
                <th className="border-0 text-center" style={{ width: '25%' }}>Date</th>
                <th className="border-0 rounded-end text-center" style={{ width: '30%' }}>Action</th>
            </tr>
                </thead>
                <tbody>
                    {loading ? <Skeleton width={150} /> : (
                        data.map((tour, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td className='text-center'>{index + 1}.</td>
                                    <td className='text-center'>RM {tour.amount}</td>
                                    <td className='upperCase-keyword text-center'>{tour.expenseType}</td>
                                    <td className='upperCase-keyword text-center'>{tour.description === "" || tour.description === "undefined" ? "NA" : tour.description }</td>
                                    <td className='text-center'>{moment(tour.dateAdded).format('YYYY-MM-DD')}</td>
                                    <td className='text-center'>
                                        {tour.status === "pending" ? (
                                            <>
                                                <Button 
                                                    className="btn btn-warning me-2" 
                                                    size='sm' 
                                                    onClick={() => handleReviewClick(tour)}
                                                >
                                                    Review
                                                </Button>
                                                <Button 
                                                    className="btn btn-success me-2" 
                                                    size='sm' 
                                                    onClick={() => handleApproveExpense('approved', tour)}
                                                >
                                                    Accept
                                                </Button>
                                            </>
                                        ) : (
                                            <Badge className={`upperCase-keyword badge-lg badge bg-${tour.status === 'rejected' ? 'danger' : 'tertiary'}`}>
                                                {tour.status}
                                            </Badge>
                                        )}
                                    </td>
                                </tr>
                                {reviewedExpenseId === tour.expenseId && (
                                    <tr>
                                        <td colSpan="4"></td>
                                        <td colSpan="4">
                                            <Card>
                                            <Card.Body >
                                            <div className="review-section">
                                                <Form.Group className='mb-2'>
                                                    <Form.Label>Review Comment</Form.Label>
                                                    <Form.Control 
                                                        as="textarea" 
                                                        rows={2} 
                                                        value={reviewComment} 
                                                        onChange={(e) => setReviewComment(e.target.value)} 
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Reason for Rejection</Form.Label>
                                                    <Form.Select 
                                                        value={selectedReason} 
                                                        onChange={(e) => setSelectedReason(e.target.value)}
                                                    >
                                                        <option value="">Select reason</option>
                                                        {reasons.map((reason, index) => (
                                                            <option key={index} value={reason}>{reason}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                                <div className='mt-4'>
                                                <Button 
                                                    className="btn btn-danger me-2" 
                                                    size='sm'
                                                    onClick={() => handleSubmitReview('reject')}
                                                >
                                                    Reject
                                                </Button>
                                                </div>
                                            </div>
                                            </Card.Body>
                                            </Card>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))
                    )}
                </tbody>
            </Table>
        </div>
    );
};
