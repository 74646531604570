import React, { useState, useEffect, useRef } from 'react';
import { Form, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLandmark } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import SelectComponent from './SelectComponent';

export default ({ selectedTour, handleTourChange }) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false); // Control dropdown visibility
  const [showLoader, setShowLoader] = useState(false); // Control the loader display
  const selectRef = useRef(); // Ref to detect clicks outside of the ReactSelect

  const fetchTourList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchTourList?limit=-1`
      );
      const options = response.data.data.map(tour => ({
        value: tour.tourId,
        label: tour.tourname,
      }));
      setSelectOptions(options);
    } catch (error) {
    } finally {
      // Set a timeout to simulate the 5-second wait before setting loading to false
      setTimeout(() => {
        setLoading(false);
      }, 5000); // 5 seconds delay before hiding the loader
    }
  };

  // Fetch data on field focus if not already fetched
  const handleFocus = () => {
    if (selectOptions.length === 0) {
      fetchTourList();
    }
    setMenuIsOpen(true); // Open dropdown on focus
    setShowLoader(true); // Show loader when the field is focused
  };

  // Close dropdown when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setMenuIsOpen(false); // Close dropdown when clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px", // Smaller font size
      borderRadius: "0.2rem",
      borderColor: "#ddd",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#aaa",
      },
      color: "white"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#0080a8" // Selected option background
        : state.isFocused
        ? "#f1f1f1" // Focused option background
        : "white", // Default background
      color: state.isSelected ? "white" : "black", // Text color
      padding: 10,
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "14px", // Adjust font size in dropdown menu
    }),
  };

  return (
    <Form.Group controlId="tourName">
      <Form.Label>Tour Name</Form.Label>
      <InputGroup className='d-flex'>
        <InputGroup.Text>
          <FontAwesomeIcon icon={faLandmark} />
        </InputGroup.Text>
        <SelectComponent
          options={selectOptions}
          value={selectedTour}
          onChange={handleTourChange}
          onFocus={handleFocus}
          isLoading={loading}
          menuIsOpen={menuIsOpen}
          setMenuIsOpen={setMenuIsOpen}
          showLoader={showLoader}
          customStyles={customStyles}
        />
      </InputGroup>
    </Form.Group>
  );
};
