import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import Avatar from '../../Widgets/Avatar';

export default ({ loading, data }) => (
    <div className='guest-details-wrapper'>
        <h6 className='mb-4'>Passengers details</h6>
        <div className="d-flex justify-content-start align-items-center mb-3">
            {loading ? <Skeleton width={150} /> : (
                <>
                    {Avatar(data.customerName.split(" ")[0], data.customerName.split(" ")[1])}
                    <div className="px-3">
                        <p className='mb-0'>
                            <span className="upperCase-keyword">
                                {data.customerName}
                            </span>
                        </p>
                        <div className="d-flex justify-content-start align-items-center">
                            <span className="circle-svg">
                                <FontAwesomeIcon icon={faEnvelope} className="progress-label text-danger mt-1" />
                            </span>
                            <div className="px-2">
                                <p className="f-12 mb-0">
                                    {loading ? <Skeleton width={100} /> : (<>
                                        {data.email}
                                    </>
                                    )}
                                </p>
                            </div>
                            <span className="circle-svg">
                                <FontAwesomeIcon icon={faPhoneAlt} className="progress-label text-danger mt-1" />
                            </span>
                            <div className="px-2">
                                <p className="f-12 mb-0">
                                    {loading ? <Skeleton width={100} /> : (<>
                                        {data.phone}
                                    </>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
        <div className='alert alert-info bg-light-blue mb-4'>
            {loading ? <Skeleton width={150} /> : (
                <div className="d-flex justify-content-start">
                    <div className="px-2">
                        <p className="f-12 mb-0 fw-bold">
                            {loading ? <Skeleton width={100} /> : (<>
                                Number of pax: {data.pax}
                            </>
                            )}
                        </p>
                    </div>
                </div>
            )}
        </div>
    </div>
);