import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import ManageReports from "./components/ManageReports";

export default (data) => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Reports</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Reports Generation Dashboard</h4>
          <p className="mb-0">This page allows administrators to generate various detailed reports for drivers, expenses, tours, upcoming tours, active tours, and more. It provides insights into key metrics, performance, and status across multiple categories, enabling data-driven decision-making and efficient management of operational processes.</p>
        </div>
      </div>
      <ManageReports data={data} />
    </>
  );
};
