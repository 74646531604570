import React from "react";
import moment from 'moment-timezone';

const BookingStatus = Object.freeze({
    BOOKING_CREATED: 'Created new booking. Awaiting Driver assignment.',
    DRIVER_ASSIGNED: 'Driver assigned successfully. Waiting for driver confirmation.',
    DRIVER_UNASSIGNED: 'Driver unassigned successfully. Waiting for driver assignment.',
    DRIVER_ACCEPTED: 'Driver Accepts the assignment. Waiting for driver to start the trip',
    DRIVER_DECLINED: 'Driver declined the assignment. Awaiting Driver assignment.',
    TRIP_STARTED: 'Driver started the trip.',
    TRIP_COMPLETED: 'Trip completed successfully.',
    TRIP_CANCELLED: 'Trip cancelled',
    TRIP_UPDATED: 'Trip updated'
});

const pendingActions = [
    "BOOKING_CREATED",
    "DRIVER_ASSIGNED",
    "DRIVER_UNASSIGNED",
    "DRIVER_ACCEPTED",
    "TRIP_STARTED",
    "TRIP_COMPLETED"
];

export default ({data}) => {
    const timelineActions = data;
    const currentState = timelineActions[timelineActions.length-1].action;
    let pendingActionsIndex = pendingActions.indexOf(currentState);
    pendingActionsIndex = pendingActionsIndex === -1 ? 1 : pendingActionsIndex+1;

    const remainingTimeLineStates = () => {
        return (pendingActionsIndex < pendingActions.length && 
            pendingActions.map((item, index) => 
            (pendingActionsIndex <= index) &&
                <div className="pending circle-wrapper ">
                    <span className="f-14 fc-faded">{BookingStatus[item]}</span>
                </div>
         ));
    }

    const actiontaken = () => {
     return timelineActions.map((item, index) => 
         <div className="circle-wrapper ">
            <span className="f-12">{moment(`${item.timestamp}`, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY, hh:mm A')}  </span>
            <p className="f-14 mb-0">{BookingStatus[item.action]}</p>
        </div>
        );
    }
    
return (
    <>
    {actiontaken()}
    {remainingTimeLineStates()}
</>)
    
}