import React, { useEffect, useState } from 'react';
import { Table, Card, Pagination, OverlayTrigger, Tooltip, InputGroup, Form } from '@themesberg/react-bootstrap';
import axios from 'axios';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toaster } from 'react-hot-toast';
import FormatTz from '../../utility/FormatTz';
import Avatar from '../../components/Widgets/Avatar';

export default () => {
  const [tours, setTours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const perPage = 10;
  const [searchQuery, setSearchQuery] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const fetchTours = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/cancelled?page=${currentPage}&pageSize=${perPage}&search=${searchQuery}`);
        setTotalPages(response.data.totalPages);
        setTotalRecords(response.data.totalRecords);
        setTours(response.data.data);
      } catch (error) {
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      }
    };

    fetchTours();
  }, [currentPage, perPage, searchQuery]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const startEntry = totalRecords === 0 ? 0 : (currentPage - 1) * perPage + 1;
  const endEntry = Math.min(currentPage * perPage, totalRecords);

  return (
    <div className='mb-6'>
      <Toaster />
      <Card border="light" className="shadow">
        <Card.Header className='d-flex justify-content-between align-items-center'>
          <div>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search by name..."
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1);
                }}
              />
            </InputGroup>
          </div>
        </Card.Header>
        <Card.Body className="pb-0 table-wrapper table-responsive">
          <Table hover className="table table-centered table-nowrap mb-0 rounded align-items-center">
            <thead className='thead-light'>
              <tr>
                <th className="border-0 rounded-start">Guest name</th>
                <th className="border-0">Email</th>
                <th className="border-0">Phone</th>
                <th className="border-0">Pax</th>
                <th className="border-0">Pick Up Point</th>
                <th className="border-0">Drop Off</th>
                <th className="border-0">Booking At </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                  </tr>
                ))
              ) : (
                tours.length === 0 ? (
                  <tr>
                    <td colSpan="9" className="text-center my-4 no-bottom-border">
                      No data found.
                    </td>
                  </tr>
                ) : (
                  tours.map((tour) => (
                    <tr key={tour.tourId}>
                      <td className='text-capitalize'>
                        <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                          {Avatar(
                            tour.customerName.split(" ")[0], tour.customerName.split(" ")[1]
                          )}
                          <div className='px-2'>
                            {tour.customerName}
                          </div>
                        </div>
                      </td>
                      <td>{tour.customerEmail}</td>
                      <td>{tour.customerPhone}</td>
                      <td>{tour.pax}</td>
                      <td>
                        <span className="text-danger">
                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.startLocation}</Tooltip>} >
                            <div className="truncate-text">
                              {tour.startLocation}
                            </div>
                          </OverlayTrigger>
                        </span>
                      </td>
                      <td>
                        <span className="text-success">
                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.endLocation}</Tooltip>} >
                            <div className="truncate-text">
                              {tour.endLocation}
                            </div>
                          </OverlayTrigger>
                        </span>
                      </td>
                      <td>{FormatTz(tour.tourDate)}, {moment(tour.tourTime, 'HH:mm:ss').format('hh:mm A')}</td>
                    </tr>
                  ))
                )
              )}
            </tbody>
          </Table>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-between align-items-center">
          <small className="fw-bold">
            Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
          </small>
          {tours.length !== 0 && <Pagination className="justify-content-center">
            <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
            {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>}
        </Card.Footer>
      </Card>
    </div>
  );
};
