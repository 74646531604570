import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Avatar from '../../Widgets/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import AvatarImage from '../../Widgets/AvatarImage';

export default ({ loading, data }) => (
    <div className="d-flex justify-content-start align-items-center">
        {data.driverImage 
        ? AvatarImage(data.driverImage) 
        : Avatar(data.driverName.split(" ")[0], data.driverName.split(" ")[1]
        )}
        <div className="px-3">
            <p className='mb-0'>
                <span className="upperCase-keyword">
                    {data.driverName}
                </span>
            </p>
            <div className="d-flex justify-content-start align-items-center">
                <span className="circle-svg">
                    <FontAwesomeIcon icon={faEnvelope} className="progress-label text-danger mt-1" />
                </span>
                <div className="px-2">
                    <p className="f-12 mb-0">
                        {loading ? <Skeleton width={100} /> : (<>
                            {data.driverEmail}
                        </>
                        )}
                    </p>
                </div>
                <span className="circle-svg">
                    <FontAwesomeIcon icon={faPhoneAlt} className="progress-label text-danger mt-1" />
                </span>
                <div className="px-2">
                    <p className="f-12 mb-0">
                        {loading ? <Skeleton width={100} /> : (<>
                            {data.driverPhone}
                        </>
                        )}
                    </p>
                </div>
            </div>
        </div>
    </div>
);