import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faEnvelope, faLocationArrow, faMapMarkerAlt, faPhoneAlt, faReceipt, faUserAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import { Col, Row, Button, Card, Form, Alert, InputGroup } from '@themesberg/react-bootstrap';
import axios from 'axios';
import { Hourglass } from 'react-loader-spinner';
import TourSelect from '../../components/SubComponents/TourSelect';
import PaymentModeSelect from '../../components/SubComponents/PaymentModeSelect';

const CreateBookingForm = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    guestName: '',
    tourName: '',
    tourId: '',
    pax: '',
    email: '',
    contactNumber: '',
    journeyDate: '',
    journeyTime: '00:00',
    pickUpPoint: '',
    destination: '',
    netCost: '',
    rates: '',
    remarks: '',
    adminId: data.id,
    driverId: '0',
    paymentStatus: '',
    bookingRefNumber:''
  });

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const scrollRef = React.useRef(null);

  const scrollToMessageAfterSubmitForm = () => {
    window.scrollTo({
      behavior: "smooth",
      top: scrollRef.current
    });
  };

  useEffect(() => {
    const initMap = (mapElementId, searchInputId) => {
      // Create map
      const map = new window.google.maps.Map(document.getElementById(mapElementId), {
        center: { lat: 30.266666, lng: -97.733330 },
        zoom: 8,
      });

      // Reset the value of the search box
      document.getElementById(searchInputId).value = '';

      const input = document.getElementById(searchInputId);

      // Create new searchbox instance
      const searchBox = new window.google.maps.places.SearchBox(input);

      // When user selects prediction from list
      searchBox.addListener('places_changed', () => {
        // Get places from search box
        const places = searchBox.getPlaces();

        // If no places then return (do nothing)
        if (places.length === 0) {
          return;
        }

        // Create bounds object
        const bounds = new window.google.maps.LatLngBounds();

        // Loop through each place
        places.forEach(place => {
          if (!place.geometry) {
            return;
          }

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        // Fit map to bounds
        map.fitBounds(bounds);
      });
    }

    // Load Google Maps API script
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBqvc7HP_JL_pjCHyHvIhoet4jdg25l4ig&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        // Initialize maps
        initMap('map1', 'search1'); // First map
        initMap('map2', 'search2'); // Second map
      };
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    };

    loadGoogleMapsScript();
  }, [successMessage, error]);

  const validateForm = () => {
    let error = '';
    let isValid = true;

    // Existing validations...

    if (!formData.journeyDate || !formData.journeyTime) {
      error = !formData.journeyDate ? 'Pick-Up Date is required' : 'Journey Time is required';
      isValid = false;
    }

    if (formData.contactNumber === '') {
      error = 'Enter valid contact number';
      isValid = false;
    }

    if (formData.paymentStatus === '' || formData.paymentStatus === "Open this select menu" || formData.paymentStatus === null) {
      error = 'Select payment status correctly';
      isValid = false;
    }

    // Other validations...
    setError(error);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTourChange = (selectedOption) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        tourId: selectedOption.value,
        tourName: selectedOption.label
      });
    }
  }

  const handleSelectPayment = (event) => {
    setFormData({
      ...formData,
      paymentStatus: event.target.value,  // Get the value from the selected option
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      journeyDate: moment(date).format('YYYY-MM-DD'),
    });
  };

  const handleTimeChange = (time) => {
    setFormData({
      ...formData,
      journeyTime: moment(time).format('HH:mm'),
    });
  };

  const getCoordinates = async (address) => {
    const apiKey = 'AIzaSyBqvc7HP_JL_pjCHyHvIhoet4jdg25l4ig'; // Replace with your actual API key
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: {
        address: address,
        key: apiKey
      }
    });

    if (response.data.status === 'OK') {
      const location = response.data.results[0].geometry.location;
      return {
        lat: location.lat,
        lng: location.lng
      };
    } else {
      throw new Error('Unable to get coordinates');
    }
  };


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const isValid = validateForm();

    if (isValid) {
      const pickUpPoint = document.getElementById("search1").value;
      const destination = document.getElementById("search2").value;
      const pickUpCoords = await getCoordinates(pickUpPoint)
      const destinationCoords = await getCoordinates(destination);
      // https://www.google.com/maps/dir/?api=1&
      const mapUrl = `origin=Current+Location&destination=${destinationCoords.lat},${destinationCoords.lng}&waypoints=${pickUpCoords.lat},${pickUpCoords.lng}`;
      clearMessages();

  // Combine the selected date with the time from journeyTime
  const combinedDateTime = moment
    .utc(formData.journeyDate) // Convert the date to UTC
    .tz('Asia/Kuala_Lumpur') // Convert it to Malaysia time
    .set({
      hour: moment(formData.journeyTime, 'HH:mm').hour(), // Set hour from journeyTime
      minute: moment(formData.journeyTime, 'HH:mm').minute(), // Set minute from journeyTime
    })
    .format('YYYY-MM-DD HH:mm:ss'); // Final formatted datetime string
    
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/booking`, {
          ...formData,
          journeyDateTime: combinedDateTime,
          pickUpPoint: pickUpPoint,
          destination: destination,
          mapUrl
        });
        await new Promise(resolve => setTimeout(resolve, 3000));
        setSuccessMessage(
          <>
            {response.data.message}{" "}
            <a href={`/tripdetails/${response.data.tourId}`} target="_blank" rel="noopener noreferrer">
              Click here to see details
            </a>
          </>
        );
        setLoading(false);
        scrollToMessageAfterSubmitForm();
        resetForm();
      } catch (error) {
        await new Promise(resolve => setTimeout(resolve, 3000)); // 3000ms = 3 seconds
        setLoading(false);
        scrollToMessageAfterSubmitForm();
        if (error.response && error.response.data && error.response.data.message) {
          handleRequestError(error.response.data.message);
        } else {
          handleRequestError('An unexpected error occurred. Please try again.');
        }
      }
    } else {
      setLoading(false);
      scrollToMessageAfterSubmitForm();
    }
  };

  const handleRequestError = (errorMessage) => {
    setError(errorMessage);
  };

  const clearMessages = () => {
    setError('');
    setSuccessMessage('');
  };

  const resetForm = () => {
    setFormData({
      guestName: '',
      tourName: '',
      pax: '',
      email: '',
      contactNumber: '',
      journeyDate: '',
      journeyTime: '00:00',
      pickUpPoint: '',
      destination: '',
      rates: '',
      netCost: '',
      remarks: '',
      adminId: data.id,
      driverId: '0',
      paymentStatus: '',
      bookingRefNumber: ''
    });
  };

  const yesterday = moment().subtract(1, 'day');
  const valid = function (current) {
    return current.isAfter(yesterday);
  };

  const selectedTour = selectOptions.find(option => option.value === formData.tourId);


  return (
    <>
      <Card border="light" className="shadow mb-6">
        <Card.Body>
          {loading ? (
            <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
              <Hourglass
                visible={true}
                height="80"
                width="80"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#306cce', '#72a1ed']}
              />
              <p className="mt-2">Just a moment, your booking is being confirmed.</p>
            </div>
          ) : <></>}
          <Form onSubmit={handleSubmit} className='mt-4 mb-4 form-input-pl-0'>
            {error && <Alert ref={scrollRef} variant="danger">{error}</Alert>}
            {successMessage && <Alert ref={scrollRef} variant="success">{successMessage}</Alert>}

            <Row>
            <Col xs={12} md={12} className="mb-3 required">
                <Form.Group controlId="guestName">
                  <Form.Label>Booking Reference Number</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faReceipt} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder='Enter Reference Number'
                      name="bookingRefNumber"
                      value={formData.bookingRefNumber}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              </Row>
              <Row>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group controlId="guestName">
                  <Form.Label>Guest Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUserAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder='Enter Guest Name'
                      name="guestName"
                      value={formData.guestName}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter email"
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="mb-3 required">
                <TourSelect
                  selectedTour={selectedTour}
                  handleTourChange={handleTourChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group controlId="journeyDate">
                  <Form.Label>Pick-Up Date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleDateChange}
                    isValidDate={valid}
                    value={formData.journeyDate}
                    inputProps={{
                      name: 'journeyDate',
                      required: true,
                      placeholder: 'mm/dd/yyyy',
                    }}
                    required
                    renderInput={(props, openCalendar) => (
                      <InputGroup onClick={openCalendar}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          value={formData.journeyDate ? moment(formData.journeyDate).format('YYYY-MM-DD') : ''}
                          placeholder='Select date'
                          required
                        />
                      </InputGroup>
                    )}
                  />
                  {error.journeyDate && (
                    <Form.Text className="text-danger">{error.journeyDate}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group controlId="journeyTime">
                  <Form.Label>Pick-Up Time</Form.Label>
                  <Datetime
                    dateFormat={false}
                    timeFormat={true}
                    closeOnSelect={true}
                    onChange={handleTimeChange}
                    inputProps={{
                      name: 'journeyTime',
                      required: true,
                      placeholder: 'hh:mm',
                    }}
                    required
                    renderInput={(props, openCalendar) => (
                      <InputGroup onClick={openCalendar}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faClock} />
                        </InputGroup.Text>
                        <Form.Control
                          value={formData.journeyTime ? formData.journeyTime : ''}
                          placeholder='Enter Journey Time'
                          required
                        />
                      </InputGroup>
                    )}
                  />
                  {error.journeyTime && (
                    <Form.Text className="text-danger">{error.journeyTime}</Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group controlId="search1">
                  <Form.Label>Pick-Up Point</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faLocationArrow} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="pickUpPoint"
                      placeholder="Search..."
                      onChange={handleChange}
                      onFocus={handleChange}
                      onBlur={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <div id="map1" style={{ display: 'none' }}></div>
              </Col>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group controlId="search2">
                  <Form.Label>Drop-Off Point</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="destination"
                      placeholder="Search..."
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <div id="map2" style={{ display: 'none' }}></div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group controlId="contactNumber">
                  <Form.Label>Contact Number</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faPhoneAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="contactNumber"
                      value={formData.contactNumber}
                      onChange={handleChange}
                      placeholder="Enter contact number"
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group>
                  <PaymentModeSelect
                    paymentStatus={formData.paymentStatus}
                    handleSelectPayment={handleSelectPayment}                    
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={4} className="mb-3 required">
                <Form.Group controlId="rates">
                  <Form.Label>Commission</Form.Label>
                  <InputGroup>
                    <InputGroup.Text> RM </InputGroup.Text>
                    <Form.Control
                      type="number"
                      name="rates"
                      value={formData.rates}
                      onChange={handleChange}
                      placeholder="Enter Commission"
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={12} md={4} className="mb-3 required">
                <Form.Group controlId="netCost">
                  <Form.Label>Net Cost</Form.Label>
                  <InputGroup>
                    <InputGroup.Text> RM </InputGroup.Text>
                    <Form.Control
                      type="number"
                      name="netCost"
                      value={formData.netCost}
                      onChange={handleChange}
                      placeholder="Enter cost to company"
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={12} md={4} className="mb-3 required">
                <Form.Group controlId="pax">
                  <Form.Label>Number of Pax</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUserPlus} />
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      name="pax"
                      value={formData.pax}
                      onChange={handleChange}
                      placeholder="Enter number of pax"
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="mb-3 required">
                <Form.Group controlId="remarks">
                  <Form.Label>Remarks

                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    className='textarea-field'
                    rows={3}
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleChange}
                    placeholder="Enter remarks"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" className="animate-up-2 theme-bg-color-1" type="submit" disabled={loading}>
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default CreateBookingForm;