import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import TourListHistory from "./examples/TourListHistory";

export default ({data}) => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Completed Trips</Breadcrumb.Item>
          </Breadcrumb>
          <h4 className="text-capitalize">Completed Trips overview</h4>
          <p className="mb-0">This section provides a list of all successfully completed tours.</p>
        </div>
      </div>
      <TourListHistory data={data}/>
    </>
  );
};
