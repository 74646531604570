import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Table,
    Button,
    Card,
    Modal,
    Pagination,
    Form,
    InputGroup,
    Spinner,
    Image,
    Row,
    Col,
} from '@themesberg/react-bootstrap';
import {
    faPlus,
    faExclamationCircle,
    faCalendarAlt,
    faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import toast, { Toaster } from 'react-hot-toast';
import Setting from '../../assets/img/icons/setting-icon.svg';
import ReactSelect from "react-select";
import GenericModal from '../../components/SubComponents/GenericModal/GenericModal';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import { checkMissingFields } from '../../utility/FormatTz';
import UpdateVehicle from './UpdateVehicle';
import { formattedTimeStamp } from '../../utility/FormatTime';
import { Hourglass } from 'react-loader-spinner';

export default ({ data }) => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const perPage = 10;
    const [totalRecords, setTotalRecords] = useState(0);
    const filterParam = "";
    const [errors, setErrors] = useState({});
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const adminId = data.data.id;
    const [vehicleData, setSelectedVehicleData] = useState([]);
    const [showAddVehicleModal, setShowAddVehicleModal] = useState(false);
    const [showUpdateVehicleModal, setShowUpdateVehicleModal] = useState(false);
    const actionType = '';
    const [showModal, setShowModal] = useState(false);
    const vehicleId = null; // or an appropriate initial value
    const [formData, setFormData] = useState({});


    const handleCloseAddModal = () => setShowAddVehicleModal(false);
    const handleCloseUpdateModal = () => setShowUpdateVehicleModal(false);

    useEffect(() => {
        const fetchDrivers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/manage/users?filterParam=approved&pageSizeNumber=-1`);
                const options = response.data.data.map(user => ({
                    value: user.driverId,
                    label: `${user.firstName} ${user.lastName}`
                }));
                setSelectOptions(options);
                // Initially, show all drivers' locations
            } catch (error) {
            } finally {
                setLoading(false); // End loading
            }
        };
        fetchDrivers();
    }, []);


    const fetchUsers = React.useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/vehicle/all?pageNumber=${currentPage}&pageSize=${perPage}&roleParam=admin&filter=status=${filterParam}`);
            setUsers(response.data.data);
            setTotalPages(response.data.totalPages);
            setTotalRecords(response.data.totalCount);
            setLoading(false);
        } catch (error) {
        }
    }, [currentPage, perPage, filterParam]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const startEntry = (currentPage - 1) * perPage + 1;
    const endEntry = Math.min(currentPage * perPage, totalRecords);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setErrors({});

        const newErrors = {};

        // Validate required fields
        if (!formData.licensePlateNumber) newErrors.licensePlateNumber = "License Plate Number is required.";
        if (!formData.seatCapacity) newErrors.seatCapacity = "Seat Capacity is required.";
        // if (formData.rcNumber && !/^RC[0-9]+$/.test(formData.rcNumber)) newErrors.rcNumber = "Invalid RC number format.";
        if (!formData.insuranceNumber) newErrors.insuranceNumber = "Insurance number is required.";
        if (!formData.permitNumber) newErrors.permitNumber = "Permit number is required.";

        // Validate expiry dates
        if (!formData.rcExpDate) newErrors.rcExpDate = "RC expiry date is required.";
        if (!formData.insuranceExpDate) newErrors.insuranceExpDate = "Insurance expiry date is required.";
        if (!formData.permitExpDate) newErrors.permitExpDate = "Permit expiry date is required.";

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setLoading(false);
            return;
        }

        const submissionData = {
            licensePlateNumber: formData.licensePlateNumber,
            seatCapacity: parseInt(formData.seatCapacity),
            id: formData.id || null,
            createdBy: parseInt(data.data.id),
            rcNumber: formData.rcNumber || "",
            insuranceNumber: formData.insuranceNumber || "",
            permitNumber: formData.permitNumber || "",
            rcExpDate: formData.rcExpDate,
            insuranceExpDate: formData.insuranceExpDate,
            permitExpDate: formData.permitExpDate,
            carModel: formData.carModel
        };

        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/vehicle/addVehicle`,
                submissionData
            );
            fetchUsers();
            handleCloseAddModal();
            await new Promise(resolve => setTimeout(resolve, 3000));
            toast.success("Vehicle added successfully!");
        } catch (error) {
            await new Promise(resolve => setTimeout(resolve, 3000));
            if (error.response && error.response.data && error.response.data.message) {
                const serverErrors = error.response.data.message;
                toast.error(serverErrors);
            } else {
                toast.error("Error submitting the form. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '.5rem'
        }),
        multiValue: (provided) => ({
            ...provided,
            borderRadius: '.5rem'
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            borderRadius: '.5rem'
        }),
        placeholder: (provided) => ({
            ...provided,
            borderRadius: '.5rem',
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            return {
                ...provided,
                opacity,
                transition,
            };
        }
    };

    const handleDriverSelect = async (selectedOption, vehicleId, vehicleStatus) => {
        setLoading(true);
        setSelectedDriver(selectedOption ? {
            driverId: selectedOption.value,
            driverName: selectedOption.label
        } : null);
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/vehicle/vehicleDetails`, {
                driverId: selectedOption.value,
                vehicleId,
                adminId,
                status: vehicleStatus
            });
            fetchUsers();
            await new Promise(resolve => setTimeout(resolve, 3000));
            toast.success('Driver updated successfully');
        } catch (error) {
            await new Promise(resolve => setTimeout(resolve, 3000));
            if (error.response && error.response.data && error.response.data.message) {
                const serverErrors = error.response.data.message;
                toast.error(serverErrors);
            } else {
                toast.error('Failed to assign a driver to the vehicle. Please check and try again.');
            }
        }
        finally {
            setLoading(false);
        }
    };

    const getDriverName = (driverId) => {
        return selectOptions.find(option => option.value === driverId);
    }

    const selectedDriverOption = selectOptions.find(option => option.value === selectedDriver);

    const handleUpdateDetails = (data) => {
        setSelectedVehicleData(data);
        setShowUpdateVehicleModal(true);
    }

    const handleAddNewVehicle = () => {
        setShowAddVehicleModal(true);
    }

    const handleCloseModal = () => setShowModal(false);

    const handleConfirmAction = async () => {
        setLoading(true); // Show loading state
        try {
            let response;
            const requestBody = { vehicleId, status: actionType }; // Include vehicleId in the request body

            switch (actionType) {
                case 'active':
                    response = await axios.patch(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/vehicle/updateVehicle/status`, requestBody);
                    break;
                case 'inactive':
                    response = await axios.patch(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/vehicle/updateVehicle/status`, requestBody);
                    break;
                case 'terminated':
                    response = await axios.patch(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/vehicle/updateVehicle/status`, requestBody);
                    break;
                case 'maintenance':
                    response = await axios.patch(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/vehicle/updateVehicle/status`, requestBody);
                    break;
                default:
                    break;
            }
            toast.success('Vehicle updated successfully');
            fetchUsers();
        } catch (error) {
            toast.error('Failed to update vehicle status.');
        } finally {
            setLoading(false); // Hide loading state
            setShowModal(false); // Close the modal
        }
    };

    const yesterday = moment().subtract(1, 'day');
    const valid = function (current) {
        return current.isAfter(yesterday);
    };

    return (
        <>
            {loading ? (
                <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
                    <Hourglass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#306cce', '#72a1ed']}
                    />
                    <p className="mt-2">Just a moment, your request is being processed.</p>
                </div>
            ) : <></>}
            <div className='mb-6'>
                <Toaster />
                <div className='mb-3'>
                    <Button variant="primary" className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" onClick={handleAddNewVehicle} >
                        <FontAwesomeIcon icon={faPlus} />
                        <span className='px-2 upperCase-keyword'>Add vehicle</span>
                    </Button>
                </div>
                <Card border="light" className="table-wrapper table-responsive shadow">
                    <Card.Body className="pb-0">
                        <Table hover className="table table-centered table-nowrap mb-0 rounded align-items-center">
                            <thead className='thead-light'>
                                <tr>
                                    <th className="border-0 rounded-start">Vehicle Number</th>
                                    <th className="border-0">VIN & License plate</th>
                                    <th className="border-0">Assignment</th>
                                    <th className="border-0">Vehicle Docs</th>
                                    <th className="border-0">Added on</th>
                                    <th className="border-0 rounded-end text-center">
                                        <span className="icon navbar-avatar-20">
                                            <Image src={Setting} className='rounded-circle text-dark bell-shake' />
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    Array.from({ length: 5 }).map((_, index) => (
                                        <tr key={index}>
                                            <td><Skeleton /></td>
                                            <td><Skeleton /></td>
                                            <td><Skeleton /></td>
                                            <td><Skeleton /></td>
                                            <td><Skeleton /></td>
                                            <td><Skeleton /></td>
                                        </tr>
                                    ))
                                ) : users.length === 0 ? (
                                    <tr>
                                        <td colSpan="9" className="text-center my-4 no-bottom-border">
                                            No data found.
                                        </td>
                                    </tr>
                                ) : (
                                    users.map((user) => (
                                        <tr key={user.id}>
                                            <td>
                                                <span className='upperCase-keyword'>
                                                    {user.vehicleId}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='upperCase-keyword'>
                                                    {user.licensePlateNumber}
                                                </span>
                                            </td>

                                            <td>
                                                {user.status === "active"
                                                    ? <div className='d-flex justify-content-start align-items-center'>
                                                        <InputGroup>
                                                            <ReactSelect options={selectOptions} styles={customStyles} theme={theme => ({
                                                                ...theme,
                                                                borderRadius: '0.5rem !important',
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#61DAFB',
                                                                    primary: '#61DAFB',
                                                                }
                                                            })}
                                                                name="tourName"
                                                                value={user.driverId ? getDriverName(user.driverId) : selectedDriverOption} // Use selectedTour for value
                                                                onChange={(selectedOption) => handleDriverSelect(selectedOption, user.id, user.status)} // Pass user.id here
                                                                className="react-select custom-react-select"
                                                                classNamePrefix="react-select"
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                    : <>
                                                        {user.status === "documentation_required"
                                                            ? <><FontAwesomeIcon icon={faExclamationCircle} style={{ color: 'red' }} /><span className='px-2'>Missing Details</span></>
                                                            : <span className='px-2 text-center'>--</span>}
                                                    </>
                                                }
                                            </td>
                                            <td className='f-12'>
                                                {checkMissingFields(user)}
                                            </td>
                                            <td className='f-12'>
                                                {formattedTimeStamp(user.createdAt)}
                                            </td>
                                            <td className='text-center'>
                                                <Button className="f-14 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" size='sm' onClick={() => handleUpdateDetails(user)}>
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-between align-items-center">
                        <small className="fw-bold">
                            Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
                        </small>
                        {users.length !== 0 && <Pagination className="justify-content-center">
                            <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                            {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => paginate(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            />
                        </Pagination>}
                    </Card.Footer>
                </Card>
            </div>
            <Modal show={showAddVehicleModal} centered onHide={handleCloseAddModal} size='xl'>
                <Card>
                    <Card.Header className='d-flex align-items-center justify-content-between'>
                        <h5 className='mb-0'> Add vehicle</h5>
                        <Button variant="close" aria-label="Close" onClick={handleCloseAddModal} />
                    </Card.Header>
                    <Card.Body className='pt-1'>
                        <Form className="form-withvalidation" onSubmit={handleSubmit}>
                            <Row>
                                <Col xl={4} sm={4}>
                                    <Form.Group controlId="formLicensePlateNumber" className="mb-1">
                                        <Form.Label column>License Plate Number</Form.Label>
                                        <InputGroup className="d-flex">
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter License Plate Number"
                                                onChange={(e) =>
                                                    setFormData({ ...formData, licensePlateNumber: e.target.value })
                                                }
                                                isInvalid={!!errors.licensePlateNumber}
                                                value={formData.licensePlateNumber || ""}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.licensePlateNumber}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col xl={4} sm={4}>
                                    <Form.Group controlId="formCarModel" className="mb-1">
                                        <Form.Label column>Car Model</Form.Label>
                                        <InputGroup className="d-flex">
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Car Model"
                                                onChange={(e) =>
                                                    setFormData({ ...formData, carModel: e.target.value })
                                                }
                                                value={formData.carModel || ""}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col xl={4} sm={4}>
                                    <Form.Group controlId="formSeatCapacity" className="mb-1">
                                        <Form.Label column>Seat Capacity</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter number of passengers"
                                                onChange={(e) =>
                                                    setFormData({ ...formData, seatCapacity: e.target.value })
                                                }
                                                isInvalid={!!errors.seatCapacity}
                                                value={formData.seatCapacity || ""}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.seatCapacity}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} sm={6}>
                                    <Form.Group controlId="formRC" className="mb-1">
                                        <Form.Label column>Registration Certificate (RC)</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter RC number"
                                                onChange={(e) =>
                                                    setFormData({ ...formData, rcNumber: e.target.value })
                                                }
                                                isInvalid={!!errors.rcNumber}
                                                value={formData.rcNumber || ""}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.rcNumber}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>

                                <Col xs={12} md={6} className="required">
                                    <Form.Group controlId="rcExpDate">
                                        <Form.Label column>Expiry Date</Form.Label>
                                        <Datetime
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(date) =>
                                                setFormData({
                                                    ...formData,
                                                    rcExpDate: moment(date).format("YYYY-MM-DD"),
                                                })
                                            }
                                            isValidDate={valid}
                                            value={formData.rcExpDate || ""}
                                            inputProps={{
                                                name: "rcExpDate",
                                                required: true,
                                                placeholder: "mm/dd/yyyy",
                                            }}
                                            required
                                            renderInput={(props, openCalendar) => (
                                                <InputGroup onClick={openCalendar}>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        value={
                                                            formData.rcExpDate
                                                                ? moment(formData.rcExpDate).format("YYYY-MM-DD")
                                                                : ""
                                                        }
                                                        placeholder="Select date"
                                                        required
                                                    />
                                                </InputGroup>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} sm={6}>
                                    <Form.Group controlId="formInsurance" className="mb-1">
                                        <Form.Label column>Vehicle Insurance</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Insurance number"
                                                onChange={(e) =>
                                                    setFormData({ ...formData, insuranceNumber: e.target.value })
                                                }
                                                isInvalid={!!errors.insuranceNumber}
                                                value={formData.insuranceNumber || ""}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.insuranceNumber}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} className="required">
                                    <Form.Group controlId="insuranceExpDate">
                                        <Form.Label column>Expiry Date</Form.Label>
                                        <Datetime
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(date) =>
                                                setFormData({
                                                    ...formData,
                                                    insuranceExpDate: moment(date).format("YYYY-MM-DD"),
                                                })
                                            }
                                            isValidDate={valid}
                                            value={formData.insuranceExpDate || ""}
                                            inputProps={{
                                                name: "insuranceExpDate",
                                                required: true,
                                                placeholder: "mm/dd/yyyy",
                                            }}
                                            required
                                            renderInput={(props, openCalendar) => (
                                                <InputGroup onClick={openCalendar}>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        value={
                                                            formData.insuranceExpDate
                                                                ? moment(formData.insuranceExpDate).format("YYYY-MM-DD")
                                                                : ""
                                                        }
                                                        placeholder="Select date"
                                                        required
                                                    />
                                                </InputGroup>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} sm={6}>
                                    <Form.Group controlId="formPermit" className="mb-3">
                                        <Form.Label column>Vehicle Permit</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Permit number"
                                                onChange={(e) =>
                                                    setFormData({ ...formData, permitNumber: e.target.value })
                                                }
                                                isInvalid={!!errors.permitNumber}
                                                value={formData.permitNumber || ""}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.permitNumber}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} className="required">
                                    <Form.Group controlId="permitExpDate">
                                        <Form.Label column>Expiry Date</Form.Label>
                                        <Datetime
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(date) =>
                                                setFormData({
                                                    ...formData,
                                                    permitExpDate: moment(date).format("YYYY-MM-DD"),
                                                })
                                            }
                                            isValidDate={valid}
                                            value={formData.permitExpDate || ""}
                                            inputProps={{
                                                name: "permitExpDate",
                                                required: true,
                                                placeholder: "mm/dd/yyyy",
                                            }}
                                            required
                                            renderInput={(props, openCalendar) => (
                                                <InputGroup onClick={openCalendar}>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        value={
                                                            formData.permitExpDate
                                                                ? moment(formData.permitExpDate).format("YYYY-MM-DD")
                                                                : ""
                                                        }
                                                        placeholder="Select date"
                                                        required
                                                    />
                                                </InputGroup>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button
                                variant="primary"
                                className="animate-up-2 mt-2"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="ms-2">Loading...</span>
                                    </>
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Modal>
            {/* Update Vehicle Modal */}
            <Modal show={showUpdateVehicleModal} centered onHide={handleCloseUpdateModal} size='lg'>
                <Card className='pb-5'>
                    <Card.Header className="d-flex justify-content-between">
                        <h5>Update Vehicle</h5>
                        <Button variant="close" aria-label="Close" onClick={handleCloseUpdateModal} />
                    </Card.Header>
                    <Card.Body>
                        <UpdateVehicle
                            vehicleId={vehicleId}
                            vehicleData={vehicleData}
                            onClose={() => { fetchUsers(); }}
                        />
                    </Card.Body>
                </Card>
            </Modal>

            <GenericModal
                show={showModal}
                onHide={handleCloseModal}
                title="Confirm Action"
                confirmationMessage={`Are you sure you want to ${actionType} this vehicle?`}
                onConfirm={handleConfirmAction}
                isConfirmationModal={true}
            />
        </>
    );
};