import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Form, InputGroup, Row } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';

export default ({ vehicleData, onClose }) => {
    const [editingField, setEditingField] = useState(null); // Track which field is being edited
    const [updatedValue, setUpdatedValue] = useState(''); // Track the updated value
    const [message, setMessage] = useState('');

    const handleEdit = (field, currentValue) => {
        setEditingField(field);
        setUpdatedValue(currentValue);
    };

    const handleValueChange = (e) => {
        setUpdatedValue(e.target.value);
    };

    const handleUpdate = async (field) => {
        if (!updatedValue) {
            setMessage('Please enter a value to update.');
            return;
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/vehicle/updateVehicle/${vehicleData.id}`, { field, value: updatedValue });
            setMessage(response.data.message);
            setEditingField(null); // Hide the input field after update
            if (onClose) {
                onClose(); // Notify parent component after a successful update
            }
        } catch (error) {
            setMessage('Failed to update vehicle details.');
        }
    };

    const renderField = (fieldName, fieldLabel, isDateTime = false) => {
        const currentValue = vehicleData[fieldName] || 'Not Available';
    
        return (
            <Form.Group controlId={fieldLabel} className="mb-1">
                <Form.Label column>{fieldLabel}</Form.Label>
                {editingField === fieldName ? (
                    <InputGroup>
                        {isDateTime ? (
                            <Datetime
                                timeFormat={false}
                                closeOnSelect={true}
                                value={updatedValue ? moment(updatedValue) : ''}
                                onChange={(date) => {
                                    if (moment.isMoment(date)) {
                                        setUpdatedValue(date.format('YYYY-MM-DD'));
                                    }
                                }}
                                inputProps={{
                                    placeholder: `Select ${fieldLabel}`,
                                    required: true,
                                }}
                                renderInput={(props, openCalendar) => (
                                    <InputGroup onClick={openCalendar}>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                        </InputGroup.Text>
                                        <Form.Control
                                            {...props}
                                            value={
                                                updatedValue
                                                    ? moment(updatedValue).format('YYYY-MM-DD')
                                                    : ''
                                            }
                                        />
                                    </InputGroup>
                                )}
                            />
                        ) : (
                            <Form.Control
                                type="text"
                                value={updatedValue}
                                onChange={handleValueChange}
                                placeholder={`Enter new ${fieldLabel}`}
                            />
                        )}
                        <Button
                            size="sm"
                            variant="primary"
                            className="animate-left-2 f-12 btn-inside-input text-uppercase"
                            onClick={() => handleUpdate(fieldName)}
                        >
                            <FontAwesomeIcon icon={faSave} /> Update
                        </Button>
                    </InputGroup>
                ) : (
                    <InputGroup>
                        <Form.Control
                            type="text"
                            value={
                                isDateTime && currentValue !== 'Not Available'
                                    ? moment(currentValue).format('YYYY-MM-DD')
                                    : currentValue
                            }
                            readOnly
                        />
                        <Button
                            size="sm"
                            variant="primary"
                            className="animate-left-2 f-12 btn-inside-input text-uppercase"
                            onClick={() => handleEdit(fieldName, currentValue)}
                        >
                            Edit
                        </Button>
                    </InputGroup>
                )}
            </Form.Group>
        );
    };

    return (
        <div>
            <Row>
                <Col xl={4} sm={6}>
                    {renderField('licensePlateNumber', 'License Plate Number')}
                </Col>
                <Col xl={4} sm={6}>
                    {renderField('carModel', 'Car Model')}
                </Col>
                <Col xl={4} sm={6}>
                    {renderField('seatCapacity', 'Seat Capacity')}
                </Col>
            </Row>
            <Row>
                <Col xl={6} sm={6}>
                    {renderField('rcNumber', 'RC Number')}
                </Col>
                <Col xl={6} sm={6}>
                    {renderField('insuranceNumber', 'Insurance Number')}
                </Col>
            </Row>
            <Row>
                <Col xl={6} sm={6}>
                    {renderField('permitNumber', 'Permit Number')}
                </Col>
                <Col xl={6} sm={6}>
                    {renderField('permitExpiryDate', 'Permit Expiry Date', true)}
                </Col>
            </Row>
            <Row>
                <Col xl={6} sm={6}>
                    {renderField('insuranceExpiryDate', 'Insurance Expiry Date', true)}
                </Col>
                <Col xl={6} sm={6}>
                    {renderField('rcExpiryDate', 'RC Expiry Date', true)}
                </Col>
            </Row>

            {message && <p>{message}</p>}
        </div>
    );
};