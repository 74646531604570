import { Table, Card, Button } from '@themesberg/react-bootstrap';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Avatar from '../../components/Widgets/Avatar';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import FormatTz from '../../utility/FormatTz';
import moment from 'moment-timezone';
import AvatarImage from '../../components/Widgets/AvatarImage';

// React component for displaying upcoming leaves
export default () => {
  const history = useHistory();
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentPage = 1;
  const perPage = 8;

  const fetchUsers = React.useCallback(async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/activeTours?pageNumber=${currentPage}&pageSize=${perPage}`);
        setTours(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const showTripDetails = (tourId) => {
    history.push(`/tripdetails/${tourId}`);
  };

  return (
    <div>
      <Card border="light" className="table-wrapper table-responsive shadow height-600 mb-5">
        <Card.Header className='d-flex justify-content-between align-items-center'>
            <h5 className="mb-0 upperCase-keyword">Drivers on trip</h5>
        </Card.Header>
        <Card.Body className="pb-0">
        {tours.length > 0 ? (
          <Table hover className="table table-centered table-nowrap mb-0 rounded">
            <thead className='thead-light'>
              <tr>
                <th className="border-0 rounded-start">Driver Name</th>
                <th className="border-0">Start Date & Time</th>
                <th className="border-0 rounded-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                  </tr>
                ))
              ) : tours.length === 0 ? (
                <tr>
                  <td colSpan="10" className="text-center my-4 no-bottom-border">
                    No data found.
                  </td>
                </tr>
              ) : (
                tours.map((tour) => (
                  <tr key={tour.tourId}>
                    <td>
                    <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                        {tour.driverImage ? AvatarImage(tour.driverImage) : Avatar(
                              tour.driverFirstName, tour.driverLastName
                          )}
                        <div className='px-2'>
                          {tour.driverFirstName} {tour.driverLastName}
                        </div>
                      </div>
                    </td> 
                    <td>{FormatTz(tour.tourDate)}, {moment(tour.tourTime, 'HH:mm:ss').format('hh:mm A')}</td>
                    <td className='text-center'>
                      <Button className="dropdown-toggle animate-up-2 theme-bg-color-1 text-center action-btn border-0 bg-transparent" size='sm' onClick={() => showTripDetails(tour.tourId)}>
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </Button>
                    </td>                 
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          ) : (
            <p>Currently, there are no drivers on an active trip.</p>
          )}
        </Card.Body>
        </Card>
        </div>
    )
};