import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Breadcrumb, Modal } from '@themesberg/react-bootstrap';
import { faPlus, faHome } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import AllDrivers from "./examples/AllDrivers";

const TourList = ({ data }) => {
 // const [tours, setTours] = useState([]);
 // const [showCreateBookingModal, setShowCreateBookingModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
 // const [loading, setLoading] = useState(true);
  const history = useHistory();

  const handleCreateDriver = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirmationClose = (confirmed) => {
    setShowConfirmationModal(false);
    if (confirmed) {
      history.push('/create-driver');
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 pb-0">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Drivers</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Drivers</h4>
          <p>Efficiently manage your fleet of drivers with real-time updates and status tracking. This section allows you to view driver details, monitor their availability, and perform bulk actions such as updating statuses or removing drivers from the system. Keep your driver information up to date to ensure smooth operations and improve the overall management of your travel services.</p>
        </div>
      </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Button variant="primary" size="sm" className='animate-up-2 shadow-3d' onClick={handleCreateDriver}>
          <FontAwesomeIcon icon={faPlus} /> 
          <span className="px-2">Add Driver</span>
        </Button>
      </div>
      <AllDrivers />
    
      {/* Confirmation Modal */}
      <Modal as={Modal.Dialog} centered show={showConfirmationModal} onHide={() => handleConfirmationClose(false)} dialogClassName="custom-modal-width">
        <Modal.Header>
          <Modal.Title>Confirm Navigation</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => handleConfirmationClose(false)} />
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to navigate to the add new driver page?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleConfirmationClose(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => handleConfirmationClose(true)}>Yes, Go to Add New Driver</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TourList;
