import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPersonBooth, faUnlockAlt, faCalendarAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Card, Button, Container, InputGroup, Spinner, Alert, Image } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import TvgLogo from '../../assets/img/brand/TV-Logo.png'
import DefaultImage from '../../assets/img/user.png'

export class AdminSignUpPage extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      birthday: '',
      address: '',
      joinedDate: '',
      userName: '',
      contactNumber: '',
      password: '',
      confirmPassword: '',
      userRole: 'admin',
      loading: false,
      error: '',
      success: '',
      profileImage: null, // Added profileImage state
      profileImagePreview: DefaultImage, // Added profileImagePreview state
      imageFormatError: '', // Added imageFormatError state
      imageSizeError: '', // Added imageSizeError state,
      imageLocation: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.changeBirthDate = this.changeBirthDate.bind(this);
    this.changeJoiningDate = this.changeJoiningDate.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this); // Added handleImageChange method
    this.uploadFile = this.uploadFile.bind(this); // Added handleImageChange method
    this.onSubmit = this.onSubmit.bind(this);
    this.valid = this.valid.bind(this);
    this.scrollRef = React.createRef();
    this.scrollToMessageAfterSubmitForm = this.scrollToMessageAfterSubmitForm.bind(this);
  }

  scrollToMessageAfterSubmitForm = () => {
    window.scrollTo({
      behavior: "smooth",
      top: this.scrollRef.current // Updated to ensure correct scroll position
    });
  };

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  changeBirthDate(event) {
    this.setState({ birthday: event.format('YYYY-MM-DD') });
  }

  changeJoiningDate(event) {
    this.setState({ joinedDate: event.format('YYYY-MM-DD') });
  }
    // Function to handle file and store it to file state
    handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
  
      // File size limit (e.g., 5 MB)
      const MAX_SIZE_MB = 5;
      // Allowed file extensions
      const ALLOWED_EXTENSIONS = ["image/jpeg", "image/png", "image/gif"];
  
      // Validate file size and type
      if (selectedFile) {
        if (selectedFile.size > MAX_SIZE_MB * 1024 * 1024) {
          this.setState({
            profileImage: null,
            profileImagePreview: '',
            imageSizeError: 'File size exceeds 2 MB. Please upload a smaller image.',
            imageFormatError: '' // Clear format error if size is invalid
          });
          return;
        }
  
        if (!ALLOWED_EXTENSIONS.includes(selectedFile.type)) {
          this.setState({
            profileImage: null,
            profileImagePreview: '',
            imageFormatError: 'Invalid file format. Please upload a JPEG or PNG image.',
            imageSizeError: '' // Clear size error if format is invalid
          });
          return;
        }
  
      const objectUrl = URL.createObjectURL(selectedFile);
      this.setState({  
        profileImage: selectedFile,
        profileImagePreview: objectUrl,
        imageFormatError: '',
        imageSizeError: '' // Clear any previous errors
      });
  
        // Clean up object URL
        return () => URL.revokeObjectURL(objectUrl);
      }
    };

    uploadFile = async (file) => {
      const base64File = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/file-uploader?bucketType=profileImage&filename=${encodeURIComponent(file.name)}`, base64File, {
        headers: { 'Content-Type': file.type },
      });
      return response.data.message.fileLocation;
    };
  

  async onSubmit(e) {
    e.preventDefault();
    const { confirmPassword, password } = this.state;

    if (password !== confirmPassword) {
      this.scrollToMessageAfterSubmitForm();
      this.setState({ error: 'Passwords do not match.' });
      return;
    }

    if (password.length < 8) {
      this.scrollToMessageAfterSubmitForm();
      this.setState({ error: 'Password should be at least 8 characters long.' });
      return;
    }

    if (this.state.birthday === '' || this.state.joinedDate === '') {
      this.scrollToMessageAfterSubmitForm();
      const errorMsg = this.state.birthday === '' ? 'Enter your birth date' : 'Enter your joining date';
      this.setState({ error: errorMsg });
      return;
    }

    if(this.state.contactNumber.length !== 10 || isNaN(this.state.contactNumber)){
      this.scrollToMessageAfterSubmitForm();
      this.setState({ error: 'Enter valid contact number' });
      return;
    }

    this.setState({ loading: true, error: '', success: '' });

    let imageUrl = '';
    if (this.state.profileImage) {
      try {
        imageUrl = await this.uploadFile(this.state.profileImage);
      } catch (error) {
        this.setState({ loading: false }); 
        return;  // Stop further processing if image upload fails
      }
    }

    const formData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      birthday: this.state.birthday,
      address: this.state.address,
      joinedDate: this.state.joinedDate,
      contactNumber: this.state.contactNumber,
      password: this.state.password,
      profileImage: imageUrl || 'Not available',
      userRole: this.state.userRole
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/signup`, formData);
      if (response.status === 200 || response.status === 201) {
        this.setState({
          success: (
            <div>
              Signup successful. <a href={Routes.Signin.path}>Click here to login</a>
            </div>
          ),
          loading: false
        });
      } else {
        this.setState({
          error: 'Failed to signup.',
          loading: false
        });
      }
      
      this.scrollToMessageAfterSubmitForm();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        this.setState({ error: error.response.data.message });
      } else {
        this.setState({ error: 'An error occurred. Please try again later.' });
      }
      this.setState({ loading: false });
      this.scrollToMessageAfterSubmitForm();
    } finally{
      this.setState({
        profileImagePreview: "",
        profileImage: null
      })
    }
  }

  valid(current) {
      const yesterday = moment().subtract(1, 'day').endOf('day');
      return current.isSameOrBefore(yesterday);
  };

  render() {
    const { loading, error, success, profileImagePreview, imageFormatError, imageSizeError } = this.state;

    return (
      <main>
        <section className="d-flex my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Row className="justify-content-center form-bg-image">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-800">
                <div className="text-center mb-2">
                    <Image src={TvgLogo} className="w-48Percent" />
                  </div>
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Create an account</h3>
                  </div>
                  {error && <Alert ref={this.scrollRef} variant="danger">{error}</Alert>}
                  {success && <Alert ref={this.scrollRef} variant="success">{success}</Alert>}
                  {imageFormatError && <Alert ref={this.scrollRef} variant="danger">{imageFormatError}</Alert>} {/* Display image format error */}
                  {imageSizeError && <Alert ref={this.scrollRef} variant="danger">{imageSizeError}</Alert>} {/* Display image size error */}
                  <Form className="mt-4" onSubmit={this.onSubmit}>

                    <Form.Group className="mb-4">
                      <Form.Label>Profile Image</Form.Label>
                      <div className='d-md-none'>
                      {profileImagePreview && (
                        <div className="mt-2 mb-2 text-center">
                          <span>
                            <img src={profileImagePreview} alt="Profile Preview" className="img-fluid  card-img avatar-xl rounded-circle signup-avatar" style={{ maxWidth: '100px' }} />
                          </span>
                        </div>
                      )}
                      <InputGroup>
                        <Form.Control
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={this.handleFileChange}
                        />
                      </InputGroup>
                      </div>
                      <div className='d-flex align-items-center justify-content-between  d-none d-sm-flex'>
                      {profileImagePreview && (
                        <div className="mt-2 mr-30">
                          <span>
                            <img src={profileImagePreview} alt="Profile Preview" className="img-fluid  card-img avatar-xl rounded-circle signup-avatar" style={{ maxWidth: '150px' }} />
                          </span>
                        </div>
                      )}
                      <InputGroup>
                        <Form.Control
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={this.handleFileChange}
                        />
                      </InputGroup>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-4 required">
                      <Form.Label>First Name</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPersonBooth} />
                        </InputGroup.Text>
                        <Form.Control
                          name="firstName"
                          value={this.state.firstName}
                          required
                          type="text"
                          placeholder="First Name"
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-4 required">
                      <Form.Label>Last Name</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          name="lastName"
                          value={this.state.lastName}
                          required
                          type="text"
                          placeholder="Last Name"
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-4 required">
                      <Form.Label>Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          name="email"
                          value={this.state.email}
                          required
                          type="email"
                          placeholder="Enter your email address"
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-4 required">
                      <Form.Label>User Name</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUserCircle} />
                        </InputGroup.Text>
                        <Form.Control
                          name="userName"
                          value={this.state.userName}
                          required
                          type="text"
                          placeholder=""
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-4 required">
                      <Form.Label>Contact Number</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          name="contactNumber"
                          value={this.state.contactNumber}
                          required
                          type="text"
                          placeholder="Contact Number"
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-4 required">
                      <Form.Label>Date of birth</Form.Label>
                      <Datetime
                        timeFormat={false}
                        closeOnSelect={true}
                        onChange={this.changeBirthDate}
                        value={this.state.birthday}
                        isValidDate={this.valid}
                        inputProps={{
                          name: 'birthday',
                          required: true,
                          placeholder: 'mm/dd/yyyy',
                        }}
                        renderInput={(props, openCalendar) => (
                          <InputGroup onClick={openCalendar}>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              value={this.state.birthday ? moment(this.state.birthday).format('YYYY-MM-DD') : ''}
                              readOnly
                            />
                          </InputGroup>
                        )}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 required">
                      <Form.Label>Enter Address</Form.Label>
                      <Form.Control
                        name="address"
                        value={this.state.address}
                        required
                        as="textarea"
                        rows="4"
                        placeholder="Enter your address..."
                        onChange={this.handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 required">
                      <Form.Label>Joined date</Form.Label>
                      <Datetime
                        timeFormat={false}
                        closeOnSelect={true}
                        onChange={this.changeJoiningDate}
                        value={this.state.joinedDate}
                        inputProps={{
                          name: 'joinedDate',
                          required: true,
                          placeholder: 'mm/dd/yyyy',
                        }}
                        renderInput={(props, openCalendar) => (
                          <InputGroup onClick={openCalendar}>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              value={this.state.joinedDate ? moment(this.state.joinedDate).format('YYYY-MM-DD') : ''}
                              readOnly
                            />
                          </InputGroup>
                        )}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 required">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          name="password"
                          value={this.state.password}
                          required
                          type="password"
                          placeholder="Password"
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-4 required">
                      <Form.Label>Confirm Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          name="confirmPassword"
                          value={this.state.confirmPassword}
                          required
                          type="password"
                          placeholder="Confirm Password"
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={loading}>
                      {loading ? (
                        <>
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          <span className="ms-2">Loading...</span>
                        </>
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </Form>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      Already have an account?
                      <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                        {' Login here '}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
}

export default AdminSignUpPage;