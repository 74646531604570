import React, { useEffect, useRef } from "react";
import ReactSelect from "react-select";
import { Hourglass } from "react-loader-spinner";

export default function CustomReactSelect({
  options,
  value,
  onChange,
  onFocus,
  isLoading,
  menuIsOpen,
  setMenuIsOpen,
  showLoader,
  customStyles,
  placeholder = "Select an option",
}) {
  const selectRef = useRef(null);

  // Handle outside clicks or touches to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setMenuIsOpen(false); // Close menu when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside); // Handle touch events for mobile

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [setMenuIsOpen]);

  // Toggle dropdown manually on click
  const handleSelectClick = () => {
    setMenuIsOpen((prev) => !prev); // Toggle menu open/close state
  };

  return (
    <div ref={selectRef} style={{ flexGrow: 1 }}>
      <ReactSelect
        options={options}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#61DAFB",
            primary: "#61DAFB",
          },
        })}
        value={value}
        onChange={(selected) => {
          onChange(selected);
          setMenuIsOpen(false); // Close menu after selection
        }}
        onMenuOpen={() => setMenuIsOpen(true)} // Sync state when menu opens
        onMenuClose={() => setMenuIsOpen(false)} // Sync state when menu closes
        menuIsOpen={menuIsOpen}
        placeholder={placeholder}
        className="react-select"
        classNamePrefix="react-select"
        isLoading={isLoading}
        onFocus={onFocus}
        loadingMessage={() =>
          showLoader ? (
            <div className="flex-column justify-content-center align-items-center bg-transparent-white">
              <Hourglass
                visible={true}
                height="80"
                width="80"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                colors={["#306cce", "#72a1ed"]}
              />
              <p className="mt-2">Just a moment, fetching tours</p>
            </div>
          ) : null
        }
      />
      {/* Add clickable area for toggling menu */}
      <div onClick={handleSelectClick} style={{ position: "absolute", width: "100%", height: "100%" }} />
    </div>
  );
}
