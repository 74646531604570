import React, { useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUnlockAlt, faCalendarAlt, faIdCardAlt, faPhoneAlt, faUser, faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Card, Button, InputGroup, Spinner, Alert, Breadcrumb } from '@themesberg/react-bootstrap';
import DefaultImage from '../../assets/img/user.png';
import { Hourglass } from 'react-loader-spinner';

const InputField = ({ label, icon, ...props }) => (
  <Form.Group className="mb-4 required">
    <Form.Label>{label}</Form.Label>
    <InputGroup>
      {icon ? <InputGroup.Text>
        <FontAwesomeIcon icon={icon} />
      </InputGroup.Text>
        : <></>}
      <Form.Control {...props} />
    </InputGroup>
  </Form.Group>
);

export default () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const clearMessages = () => {
    setError('');
    setSuccessMessage('');
  };

  const handleRequestError = (errorMessage) => {
    setError(errorMessage);
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthday: '',
    address: '',
    joinedDate: '',
    drivingLicense: '',
    licensePlateNumber: '',
    contactNumber: '',
    password: '',
    confirmPassword: '',
    userRole: 'driver',
    profileImage: null,
    profileImagePreview: DefaultImage,
    error: '',
    success: '',
    loading: false
  });

  const scrollRef = useRef();
  const scrollToMessageAfterSubmitForm = () => {
    if (scrollRef.current) {
      window.scrollTo({
        behavior: "smooth",
        top: scrollRef.current.offsetTop,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleDateChange = (field) => (date) => {
    setFormData(prevState => ({ ...prevState, [field]: date.format('YYYY-MM-DD') }));
  };

  const changeLicenceExpiryDate = (field) => (date) => {
    setFormData(prevState => ({ ...prevState, [field]: date.format('YYYY-MM-DD') }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const MAX_SIZE_MB = 5;
    const ALLOWED_EXTENSIONS = ["image/jpeg", "image/png", "image/gif"];

    if (file) {
      if (file.size > MAX_SIZE_MB * 1024 * 1024) {
        setFormData(prevState => ({ ...prevState, profileImage: null, error: 'File size exceeds 5 MB.' }));
        scrollToMessageAfterSubmitForm();
        return;
      }
      if (!ALLOWED_EXTENSIONS.includes(file.type)) {
        setFormData(prevState => ({ ...prevState, profileImage: null, error: 'Invalid file format.' }));
        scrollToMessageAfterSubmitForm();
        return;
      }
      const objectUrl = URL.createObjectURL(file);
      setFormData(prevState => ({ ...prevState, profileImage: file, profileImagePreview: objectUrl, error: '' }));
      return () => URL.revokeObjectURL(objectUrl);
    }
  };

  const uploadFile = async (file) => {
    const base64File = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/file-uploader?bucketName=profileImage&filename=${encodeURIComponent(file.name)}`, base64File, {
      headers: { 'Content-Type': file.type },
    });
    return response.data.message.fileLocation;
  };

  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    setFormData({ loading: true, error: '', success: '' });
    const { confirmPassword, password, birthday, joinedDate, contactNumber } = formData;

    if (password !== confirmPassword) {
      setFormData({ ...formData, error: 'Passwords do not match.' });
      scrollToMessageAfterSubmitForm();
      return
    }
    if (password.length < 8) {
      setFormData({ ...formData, error: 'Password must be at least 8 characters long.' });
      scrollToMessageAfterSubmitForm();
      return
    }
    if (!birthday) {
      setFormData({ ...formData, error: 'Enter birth and joining date.' });
      scrollToMessageAfterSubmitForm();
      return
    }

    if (!joinedDate) {
      setFormData({ ...formData, error: 'Enter joining date.' });
      scrollToMessageAfterSubmitForm();
      return
    }
    if (!/^\d{10}$/.test(contactNumber)) {
      setFormData({ ...formData, error: 'Enter a valid contact number.' });
      scrollToMessageAfterSubmitForm();
      return
    }
    clearMessages();

    setFormData(prevState => ({ ...prevState, loading: true, error: '', success: '' }));
    try {
      let imageUrl = '';

      try {
        if (formData.profileImage) {
          imageUrl = await uploadFile(formData.profileImage);
        }
      } catch (error) {
        imageUrl = 'https://transferappdata.s3.ap-southeast-1.amazonaws.com/user+(2).png'; // Replace with default image URL
      }
      await axios.post('${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/createAccount', { ...formData, imageUrl });
      await new Promise(resolve => setTimeout(resolve, 3000));
      setSuccessMessage('Successfully created a new driver account');
      setLoading(false);
      scrollToMessageAfterSubmitForm();
      resetForm();
    } catch (error) {
      await new Promise(resolve => setTimeout(resolve, 3000));
      setLoading(false);
      scrollToMessageAfterSubmitForm();
      handleRequestError('Error submitting booking. Please try again later.');
    } finally {
      scrollToMessageAfterSubmitForm();
    }
  };

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      birthday: '',
      address: '',
      joinedDate: '',
      drivingLicense: '',
      licensePlateNumber: '',
      contactNumber: '',
      password: '',
      confirmPassword: '',
      userRole: 'driver',
      profileImage: null,
      profileImagePreview: DefaultImage,
      error: '',
      success: '',
      loading: false
    })
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Add Driver</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Create driver profile</h4>
        </div>
      </div>
      <Card border="light" className="shadow mb-6">
        <Card.Body>
          <Row>
            {loading ? (
              <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
                <Hourglass
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  colors={['#306cce', '#72a1ed']}
                />
                <p className="mt-2">Just a moment, we are creating a new driver account.</p>
              </div>
            ) : <></>}
            <Form className="mt-4" onSubmit={onSubmit}>
              {error && <Alert ref={scrollRef} variant="danger">{error}</Alert>}
              {successMessage && <Alert ref={scrollRef} variant="success">{successMessage}</Alert>}
              <Form.Group className="mb-4">
                <Form.Label>Profile Image</Form.Label>
                <div className='d-md-none'>
                  {formData.profileImagePreview && (
                    <div className="mt-2 mb-2 text-center">
                      <span>
                        <img src={formData.profileImagePreview} alt="Profile Preview" className="img-fluid  card-img avatar-xl rounded-circle signup-avatar" style={{ maxWidth: '100px' }} />
                      </span>
                    </div>
                  )}
                  <InputGroup>
                    <Form.Control
                      name="image"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </InputGroup>
                  {/* <ImageUploader /> */}
                </div>
                <div className='d-flex align-items-center justify-content-between  d-none d-sm-flex'>
                  {formData.profileImagePreview && (
                    <div className="mt-2 mr-30">
                      <span>
                        <img src={formData.profileImagePreview} alt="Profile Preview" className="img-fluid  card-img avatar-xl rounded-circle signup-avatar" style={{ maxWidth: '150px' }} />
                      </span>
                    </div>
                  )}
                  <InputGroup>
                    <Form.Control
                      name="image"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </InputGroup>
                  {/* <ImageUploader /> */}
                </div>
              </Form.Group>
              <Row>
                <Col lg={6} >
                  <InputField label="First Name" icon={faUser} name="firstName" value={formData.firstName} required onChange={handleInputChange} placeholder="First Name" />
                </Col>
                <Col lg={6} >
                  <InputField label="Last Name" icon={faUser} name="lastName" value={formData.lastName} required onChange={handleInputChange} placeholder="Last Name" />
                </Col>
              </Row>
              <Row>
                <Col lg={6} >
                  <InputField label="Email" icon={faEnvelope} name="email" type="email" value={formData.email} required onChange={handleInputChange} placeholder="Email Address" />
                </Col>
                <Col>
                  <InputField label="Contact Number" icon={faPhoneAlt} name="contactNumber" value={formData.contactNumber} required onChange={handleInputChange} placeholder="Contact Number" />
                </Col>
              </Row>
              <Row>
                <Col lg={6} >
                  <Form.Group className="mb-4 required">
                    <Form.Label>Date of birth</Form.Label>
                    <Datetime timeFormat={false} closeOnSelect onChange={handleDateChange('birthday')} value={formData.birthday}
                      renderInput={(props, openCalendar) => (
                        <InputGroup onClick={openCalendar}>
                          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          <Form.Control readOnly required value={formData.birthday ? moment(formData.birthday).format('YYYY-MM-DD') : ''} />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-4 required">
                    <Form.Label>Joined date</Form.Label>
                    <Datetime timeFormat={false} closeOnSelect onChange={handleDateChange('joinedDate')} value={formData.joinedDate}
                      renderInput={(props, openCalendar) => (
                        <InputGroup onClick={openCalendar}>
                          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          <Form.Control readOnly required value={formData.joinedDate ? moment(formData.joinedDate).format('YYYY-MM-DD') : ''} />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <InputField label="Driving License" icon={faIdCardAlt} name="drivingLicence" value={formData.drivingLicence} required onChange={handleInputChange} placeholder="Licence Number" />
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-4 required">
                    <Form.Label>Expiry Date</Form.Label>
                    <Datetime timeFormat={false} closeOnSelect onChange={changeLicenceExpiryDate('validUpto')} value={formData.validUpto}
                      renderInput={(props, openCalendar) => (
                        <InputGroup onClick={openCalendar}>
                          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          <Form.Control readOnly required value={formData.validUpto ? moment(formData.validUpto).format('YYYY-MM-DD') : ''} />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <InputField label="Enter Address" name="address" value={formData.address} required as="textarea" rows="4" onChange={handleInputChange} placeholder="Enter Address" />
              <Row>
                <Col lg={6}>
                  <InputField label="Password" icon={faUnlockAlt} name="password" type="password" value={formData.password} required onChange={handleInputChange} placeholder="Enter Password" />
                </Col>
                <Col lg={6}>
                  <InputField label="Confirm Password" icon={faUnlockAlt} name="confirmPassword" type="password" value={formData.confirmPassword} required onChange={handleInputChange} placeholder="Confirm Password" />
                </Col>
              </Row>
              <Button variant="primary" type="submit" disabled={formData.loading} className="w-100">
                {formData.loading ? <Spinner animation="border" size="sm" /> : 'Create Account'}
              </Button>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
