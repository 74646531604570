import React from "react";
import AdminDashboardOverview from "./AdminDashboardOverview";

export default ({ data }) => {
  return (
    <>
      <AdminDashboardOverview data={data}/>
    </>
  );
};
