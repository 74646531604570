import React, { useEffect, useState } from 'react';
import { Table, Card, Pagination, OverlayTrigger, Tooltip, InputGroup, Form, Button, Spinner, Dropdown } from '@themesberg/react-bootstrap';
import axios from 'axios';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import TripRating from '../../components/SubComponents/TripRating';
import { faCalendarAlt, faCheckCircle, faEllipsisH, faFilter, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Datetime from 'react-datetime';
import toast, { Toaster } from 'react-hot-toast';
import ShortenAddress from '../../utility/ShortenAddress';
import FormatTz, { createdATFormat } from '../../utility/FormatTz';
import Avatar from '../../components/Widgets/Avatar';
import AvatarImage from '../../components/Widgets/AvatarImage';

const TourListHistory = ({ data }) => {
  const adminId = data.id;
  const [tours, setTours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [ratingLoadingId, setRatingLoadingId] = useState(null); // Track loading state for specific row
  const perPage = 10;
  const [searchQuery, setSearchQuery] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const history = useHistory();
  const [dateFilter, setDateFilter] = useState("");
  const [rangeFilterSelected, setRangeFilterSelected] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Filter By"); // Default label
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleFilterSelect = (filter, label) => {
    if (filter !== "custom_range") {
      setDateFilter(filter);
      setSelectedFilter(label); // Update dropdown label
      setStartDate(null);
      setEndDate(null);
      setRangeFilterSelected(false)
    }
  };


  const handleRangeFilterSelect = (filter, label) => {
    if (filter === "custom_range") {
      setRangeFilterSelected(true);
      setSelectedFilter(label);
    }
  }

  const SuccessToast = () => (
    <div className='pxy-2 successToast' style={{ display: 'flex', alignItems: 'center', background: '#fff' }}>
      <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '8px' }} />
      <span>Your rating has been successfully saved.</span>
    </div>
  );

  const ErrorToast = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', marginRight: '8px' }} />
      <span>Oops! Something went wrong.</span>
    </div>
  );

  const fetchTours = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const filterParams = new URLSearchParams({
        page: currentPage,
        pageSize: perPage,
        search: searchQuery,
        filterByDate: startDate ? "custom_range" : dateFilter,
        startDate: startDate ? startDate.toISOString() : "",
        endDate: endDate ? endDate.toISOString() : startDate ? startDate.toISOString() : "",
      });
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchPastTours?${filterParams.toString()}`);
      const fetchedTours = response.data.data;
      setTotalPages(response.data.totalPages);
      setTotalRecords(response.data.totalRecords);
      setTours(fetchedTours);
      await new Promise(resolve => setTimeout(resolve, 3000));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, perPage, searchQuery, dateFilter, startDate, endDate]);

  useEffect(() => {
    fetchTours();
  }, [currentPage, perPage, searchQuery, dateFilter, startDate, endDate, fetchTours])


  const handleRatingChange = async (tourId, driverId, newRating) => {
    setRatingLoadingId(tourId);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/updateRating`, { tourId, driverId, adminId, rating: newRating });
      await new Promise(resolve => setTimeout(resolve, 3000));
      toast.custom(<SuccessToast />);
      setRatingLoadingId(null);
      fetchTours();
    } catch (error) {
      await new Promise(resolve => setTimeout(resolve, 3000));
      toast.custom(<ErrorToast />);
      setRatingLoadingId(null);
    }
  };

  const showTripDetails = (tourId) => {
    history.push(`/trips/past/${tourId}?adminId=${adminId}`);
  };

  const handleDateChange = (date) => {
    setStartDate(date)
  };

  const handleEndDateChange = (date) => {
    setEndDate(date)
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const startEntry = totalRecords === 0 ? 0 : (currentPage - 1) * perPage + 1;
  const endEntry = Math.min(currentPage * perPage, totalRecords);
  return (
    <div className='mb-6'>
      <Toaster />
      <Card border="light" className="shadow">
        <Card.Header className="d-flex justify-content-between align-items-center flex-wrap">
          {/* Search Field */}
          <div>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search by name..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </InputGroup>
          </div>
          <div className="d-flex align-items-center gap-2">
            {/* Date Filter Dropdown */}
            <Dropdown className="btn-toolbar bg-transparent btn-filter-input">
              <Dropdown.Toggle as="text" variant="primary" size="sm">
                <FontAwesomeIcon icon={faFilter} className="me-2 f-14" />
                {selectedFilter}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2 filterDropDown">
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("today", "Today")}
                >
                  Today
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("yesterday", "Yesterday")}
                >
                  Yesterday
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("week", "Week")}
                >
                  This Week
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("month", "Month")}
                >
                  This Month
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleRangeFilterSelect("custom_range", "Select by date range")}
                >
                  Select by Date Range
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Custom Date Range Picker */}
            {rangeFilterSelected && (
              <div className="d-flex align-items-center">
                <div>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleDateChange}
                    value={startDate}
                    inputProps={{
                      required: true,
                      placeholder: 'mm/dd/yyyy',
                    }}
                    required
                    renderInput={(props, openCalendar) => (
                      <InputGroup onClick={openCalendar}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
                          placeholder='Select date'
                          required
                        />
                      </InputGroup>
                    )}
                  />
                </div>
                <span className="mx-2">to</span>
                <div className='me-2'>
                <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleEndDateChange}
                    value={endDate}
                    inputProps={{
                      required: true,
                      placeholder: 'mm/dd/yyyy',
                    }}
                    required
                    renderInput={(props, openCalendar) => (
                      <InputGroup onClick={openCalendar}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
                          placeholder='Select date'
                          required
                        />
                      </InputGroup>
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </Card.Header>

        <Card.Body className="pb-0 table-wrapper table-responsive">
          <Table className="table table-centered table-nowrap mb-0 rounded align-items-center">
            <thead className='thead-light'>
              <tr>
                <th className="border-0 rounded-start">Guest Name</th>
                <th className="border-0">Pick Up Point</th>
                <th className="border-0">Drop Off</th>
                <th className="border-0">
                  Start Date & Time
                </th>
                <th className="border-0">
                  End Date & Time
                </th>
                <th className="border-0">
                  Created on
                </th>
                <th className="border-0">Driver</th>
                <th className="border-0">Rating</th>
                <th className="border-0 rounded-end text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array.from({ length: perPage }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                  </tr>
                ))
              ) : (
                tours.length === 0 ? (
                  <tr>
                    <td colSpan="9" className="text-center my-4 no-bottom-border">
                      No data found.
                    </td>
                  </tr>
                ) : (
                  tours.map((tour) => (
                    <tr className='cursor-pointer' key={tour.tourId}>
                      <td>
                        <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                          {Avatar(
                            tour.customerName.split(" ")[0], tour.customerName.split(" ")[1]
                          )}
                          <div className='px-2'>
                            {tour.customerName}
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-danger">
                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.startLocation}</Tooltip>} >
                            <div className="truncate-text">
                              {ShortenAddress(tour.startLocation)}
                            </div>
                          </OverlayTrigger>
                        </span>
                      </td>
                      <td>
                        <span className="text-success">
                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.endLocation}</Tooltip>} >
                            <div className="truncate-text">
                              {ShortenAddress(tour.endLocation)}
                            </div>
                          </OverlayTrigger>
                        </span>
                      </td>
                      <td>{FormatTz(tour.tourDate)}, {moment(tour.tourTime, 'HH:mm:ss').format('hh:mm A')}</td>
                      <td>{createdATFormat(`${tour.completedAt}`)}</td>
                      <td>{createdATFormat(`${tour.createdAt}`)}</td>
                      <td>
                        <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                          {tour.driverImage ? AvatarImage(tour.driverImage) : Avatar(
                              tour.driverName.split(" ")[0], tour.driverName.split(" ")[1]
                          )}
                          <div className='px-2'>
                            {tour.driverName}
                          </div>
                        </div>
                      </td>
                      <td>
                        {ratingLoadingId === tour.tourId ? (
                          <Spinner animation="border" size='sm' />
                        ) : (tour.status !== 'cancelled' && tour.status !== 'expired' ? (
                          <TripRating
                            rating={tour.ratingScore || 0}
                            onRate={(newRating) => handleRatingChange(tour.tourId, tour.driverId, newRating)}
                            disabled={false}
                            previousRating={tour.ratingScore}
                          />
                        ) : (
                          <div className="star-rating-disabled">
                            <TripRating
                              rating={tour.ratingScore || 0}
                              onRate={() => { }}
                              disabled={true}
                            />
                          </div>
                        ))}
                      </td>
                      <td className='text-center'>
                        <Button className="dropdown-toggle animate-up-2 theme-bg-color-1 text-center action-btn border-0 bg-transparent" size='sm' onClick={() => showTripDetails(tour.tourId)}>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </Button>
                      </td>
                    </tr>
                  ))
                )
              )}
            </tbody>
          </Table>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-between align-items-center">
          <small className="fw-bold">
            Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
          </small>
          {tours.length !== 0 && <Pagination className="justify-content-center">
            <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
            {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>}
        </Card.Footer>
      </Card>
    </div>
  );
};

export default TourListHistory;
