import React, { useState } from "react";
import { Modal } from '@themesberg/react-bootstrap';
import Signin from "../../pages/examples/Signin";


export default () => {
    const [showSignin, setShowSignin] = useState(false);
    const handleClose = () => setShowSignin(false);
    return(
    <>
        <Modal centered show={showSignin} onHide={handleClose}>
            <Signin />
        </Modal>
  </>
)};