import React, { useEffect, useState } from 'react';
import { Table, Button, Card, Pagination } from '@themesberg/react-bootstrap';
import axios from 'axios';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import ReviewModal from '../../components/SubComponents/CustomModal/ReviewModal';
import { formattedTimeStamp } from '../../utility/FormatTime';

export default ({ data }) => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const perPage = 10;
    const searchQuery = '';
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortValue] = useState("asc");
    const [filterParam] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const fetchUsers = React.useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/expenses/review?page=${currentPage}&pageSize=${perPage}`);
            const responseData = response.data.data;
            const aggregatedData = [];
            
            responseData.forEach((item) => {
              const existingTour = aggregatedData.find((tour) => tour.tourId === item.tourId);
            
              if (!existingTour) {
                // Initialize new entry for the tour
                aggregatedData.push({
                  tourId: item.tourId,
                  driverName: item.driverName,
                  customerName: item.customerName,
                  pax: item.pax,
                  startLocation: item.startLocation,
                  endLocation: item.endLocation,
                  tourNameList: item.tourNameList,
                  totalApprovedExpenses: item.status === "approved" ? parseFloat(item.totalTourExpenses) : 0,
                  totalRejectedExpenses: item.status === "rejected" ? parseFloat(item.totalTourExpenses) : 0,
                  tripStartDate: moment(item.tripStartDate).toISOString(),
                  tourCompletionDate: moment(item.tourCompletionDate).toISOString(),
                  firstExpenseApprovedAt: item.status === "approved" && item.expenseApprovedAt
                    ? moment(item.expenseApprovedAt).toISOString()
                    : null,
                  lastExpenseApprovedAt: item.status === "approved" && item.expenseApprovedAt
                    ? moment(item.expenseApprovedAt).toISOString()
                    : null,
                });
              } else {
                // Update the existing tour entry
                if (item.status === "approved") {
                  existingTour.totalApprovedExpenses += parseFloat(item.totalTourExpenses);
            
                  if (item.expenseApprovedAt) {
                    existingTour.firstExpenseApprovedAt = existingTour.firstExpenseApprovedAt
                      ? moment.min(moment(existingTour.firstExpenseApprovedAt), moment(item.expenseApprovedAt)).toISOString()
                      : moment(item.expenseApprovedAt).toISOString();
            
                    existingTour.lastExpenseApprovedAt = existingTour.lastExpenseApprovedAt
                      ? moment.max(moment(existingTour.lastExpenseApprovedAt), moment(item.expenseApprovedAt)).toISOString()
                      : moment(item.expenseApprovedAt).toISOString();
                  }
                } else if (item.status === "rejected") {
                  existingTour.totalRejectedExpenses += parseFloat(item.totalTourExpenses);
                }
            
                existingTour.tripStartDate = moment.min(moment(existingTour.tripStartDate), moment(item.tripStartDate)).toISOString();
                existingTour.tourCompletionDate = moment.max(moment(existingTour.tourCompletionDate), moment(item.tourCompletionDate)).toISOString();
              }
            });
            setUsers(aggregatedData)
            setTotalPages(response.data.totalPages);
            setTotalRecords(response.data.totalCount);
            setLoading(false); 
        } catch (error) {
        }
    }, [currentPage, perPage, searchQuery, sortValue, filterParam]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const startEntry = totalRecords === 0 ? 0 : (currentPage - 1) * perPage + 1;
    const endEntry = Math.min(currentPage * perPage, totalRecords);
    const handleReviewClick = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);
    };

    return (
        <div className='mb-6'>
            <Card border="light" className="table-wrapper table-responsive shadow">
                <Card.Body className="pb-0">
                    <Table hover className="table table-centered table-nowrap mb-0 rounded align-items-center">
                        <thead className='thead-light'>
                            <tr>
                                <th className="border-0">Guest Name</th>
                                <th className="border-0">Driver Name</th>
                                <th className="border-0">Trip Start Date</th>
                                <th className="border-0">Trip End Date</th>
                                <th className="border-0">Added on</th>
                                <th className="border-0">Reviewed on</th>
                                <th className="border-0">Approved Amount</th>
                                <th className="border-0">Rejected Amount</th>
                                <th className="border-0">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                Array.from({ length: 5 }).map((_, index) => (
                                    <tr key={index}>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                    </tr>
                                ))
                            ) : users.length === 0 ? (
                                <tr>
                                    <td colSpan="9" className="text-center my-4 no-bottom-border">
                                        No data found.
                                    </td>
                                </tr>
                            ) : (
                                
                                users.map((user) => (
                                    <tr className='cursor-pointer' key={user.expenseId}>
                                        <td>
                                            
                                            <span className='upperCase-keyword'>
                                                {user.customerName}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {user.driverName}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {formattedTimeStamp(user.tripStartDate)}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {formattedTimeStamp(user.tourCompletionDate)}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {formattedTimeStamp(user.expenseCreatedAt)}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                {formattedTimeStamp(user.expenseApprovedAt)}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                RM {user.totalApprovedExpenses}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                RM {user.totalRejectedExpenses}
                                            </span>
                                        </td>
                                        <td>
                                            <Button className="dropdown-toggle btn btn-success me-2 animate-up-2 theme-bg-color-1" size='sm' onClick={() => handleReviewClick(user)}>
                                                View
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between align-items-center">
                    <small className="fw-bold">
                        Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
                    </small>
                    {users.length !== 0 && <Pagination className="justify-content-center">
                        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                        {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        />
                    </Pagination>}
                </Card.Footer>
            </Card>
            {selectedUser && <ReviewModal 
                show={showModal} 
                handleClose={handleCloseModal} 
                userData={selectedUser || {}} // Pass the selected user data
                adminData={data}
            />}
        </div>
    );
};
