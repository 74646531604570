import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faCalendarAlt, faCarAlt, faExclamationCircle, faFlag, faInfoCircle, faPenFancy, faTags, faTrashAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import { Col, Row, Button, Form, Alert, InputGroup, Modal } from '@themesberg/react-bootstrap';
import axios from 'axios';
import ReactSelect from 'react-select';
import { Hourglass } from 'react-loader-spinner';

const categoryOptions = [
  { value: 'traffic', label: 'Traffic Violations' },
  { value: 'operational', label: 'Operational Violations' },
  { value: 'customer', label: 'Customer Service Violations' },
];

const subcategoryOptions = {
  traffic: [
    { value: 'speeding', label: 'Speeding' },
    { value: 'red-light', label: 'Running Red Light' },
    { value: 'reckless-driving', label: 'Reckless Driving' },
    { value: 'failure-to-yield', label: 'Failure to Yield' },
  ],
  operational: [
    { value: 'failure-to-use-meter', label: 'Failure to Use Meter' },
    { value: 'overcharging', label: 'Overcharging' },
    { value: 'refusal-to-pickup', label: 'Refusing to Pick Up Passenger' },
  ],
  customer: [
    { value: 'rudeness', label: 'Rudeness or Unprofessional Behavior' },
    { value: 'harassment', label: 'Harassment or Abuse' },
    { value: 'failure-to-assist', label: 'Failure to Assist Disabled Passengers' },
  ],
};

const violationOptions = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' },
];


export default ({ data }) => {
  const [category, setCategory] = useState(null);
  const [subCategory, setSubcategory] = useState(null);
  const [priority, setPriority] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const [imageState, setImageState] = useState({
    images: [],
    imagePreview: '',
    imageSizeError: 'File size exceeds 2 MB. Please upload a smaller image.',
    imageFormatError: ''
  });
  const [formData, setFormData] = useState({
    assignedDriver: '',
    adminId: data.id,
    assignedVehicle: '',
    category: '',
    subCategory: '',
    summary: '',
    description: '',
    attachments: '',
    priority: '',
    violationDate: ''
  });

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const scrollRef = React.useRef(null);

  const scrollToMessageAfterSubmitForm = () => {
    window.scrollTo({
      behavior: "smooth",
      top: scrollRef.current
    });
  };

  const handleRemoveImage = (index) => {
    setImageState(prevState => {
      const updatedImages = prevState.images.filter((_, i) => i !== index);
      return { ...prevState, images: updatedImages };
    });
  };

  useEffect(() => {
    const fetchDriverList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/manage/users?filterParam=approved&pageSizeNumber=-1`);
        const options = response.data.data.map(item => ({
          value: item.driverId,
          label: `${item.firstName} ${item.lastName}`,
        }));
        setSelectOptions(options);
      } catch (error) {
        handleRequestError('Error fetching drivers. Please try again later.');
      }
    };

    const fetchVehicleList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/vehicle/all?pageNumber=1&pageSize=-1&search=&sortDirection=asc&roleParam=admin&filter=status=`);
        const options = response.data.data.map(item => ({
          value: item.id,
          label: `${item.vehicleId}`,
        }));
        setVehicleOptions(options);
      } catch (error) {
        handleRequestError('Error fetching drivers. Please try again later.');
      }
    };

    fetchDriverList();
    fetchVehicleList();
  }, []);

  const validateForm = () => {
    let error = '';
    let isValid = true;

    if (formData.driverId === '') {
      error = 'Select driver';
      isValid = false;
    }

    setError(error);
    return isValid;
  };

  const handleClose = () => {
    setShowDefault(false);
    resetForm();
  }

  const handleDriverChange = (selectedOption) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        assignedDriver: selectedOption.value,
      });
    }
  };

  const handleVehicleChange = (selectedOption) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        assignedVehicle: selectedOption.value,
      });
    }
  };

  const handlePriorityChange = (selectedOption) => {
    setPriority(selectedOption.Label)
    if (selectedOption) {
      setFormData({
        ...formData,
        priority: selectedOption.value,
      });
    }
  }

  const handleVioldationDate = (date) => {
    setFormData({
      ...formData,
      violationDate: moment(date).format('YYYY-MM-DD'),
    });
  };

  const handleCategoryChange = (selectedCategory) => {
    setCategory(selectedCategory);
    setFormData(prevState => ({ ...prevState, "category": selectedCategory.value }));
    setSubcategory(null); // Reset subCategory when category changes
  };

  const handleVoilationChange = (selectedSubcategory) => {
    setFormData(prevState => ({ ...prevState, "subCategory": selectedSubcategory.value }));
    setSubcategory(selectedSubcategory);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      clearMessages();
      try {
        const uploadedFiles = await uploadFiles(imageState.images.map(img => img.file));
        const formWithAttachments = { ...formData, attachments: uploadedFiles };
        await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/violations`, formWithAttachments);
        await new Promise(resolve => setTimeout(resolve, 3000));
        setSuccessMessage('Complaint request submitted successfully.');
        setLoading(false);
        scrollToMessageAfterSubmitForm();
        resetForm();
      } catch (error) {
        await new Promise(resolve => setTimeout(resolve, 3000)); // 3000ms = 3 seconds
        setLoading(false);
        scrollToMessageAfterSubmitForm();
        handleRequestError('Error submitting complaint request. Please try again later.');
      }
    }
  };

  const handleRequestError = (errorMessage) => {
    setError(errorMessage);
    console.error(errorMessage);
  };

  const clearMessages = () => {
    setError('');
    setSuccessMessage('');
  };

  const resetForm = () => {
    setFormData({
      assignedDriver: '',
      adminId: data.id,
      assignedVehicle: '',
      category: '',
      subCategory: '',
      summary: '',
      description: '',
      attachments: '',
      priority: '',
      violationDate: ''
    });
  };

  const valid = function (current) {
    return current.isBefore(moment());
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px", // Smaller font size
      borderRadius: "0.2rem",
      borderColor: "#ddd",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#aaa",
      },
      color: "white"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#0080a8" // Selected option background
        : state.isFocused
          ? "#f1f1f1" // Focused option background
          : "white", // Default background
      color: state.isSelected ? "white" : "black", // Text color
      padding: 10,
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "14px", // Adjust font size in dropdown menu
    }),
  };

  const availableSubcategories = category ? subcategoryOptions[category.value] || [] : [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Get files as an array

    const MAX_SIZE_MB = 2; // Max file size (in MB)
    const ALLOWED_EXTENSIONS = ["image/jpeg", "image/png", "image/gif"];

    // Validate files
    selectedFiles.forEach(file => {
      if (file.size > MAX_SIZE_MB * 1024 * 1024) {
        setImageState(prevState => ({
          ...prevState,
          imageSizeError: 'One or more files exceed the size limit of 2 MB.',
          imageFormatError: ''
        }));
        return;
      }

      if (!ALLOWED_EXTENSIONS.includes(file.type)) {
        setImageState(prevState => ({
          ...prevState,
          imageFormatError: 'One or more files have an invalid format.',
          imageSizeError: ''
        }));
        return;
      }
    });

    // Create previews for valid images
    const newImages = selectedFiles.map(file => ({
      file,
      previewUrl: URL.createObjectURL(file)
    }));

    setImageState(prevState => ({
      ...prevState,
      images: [...prevState.images, ...newImages],
      imageSizeError: '',
      imageFormatError: ''
    }));
  };

  const uploadFiles = async (files) => {
    // Check if files exist
    if (!files || files.length === 0) return [];

    // Function to convert a single file to base64
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };

    // Upload each file to the server
    const uploadPromises = files.map(async (file) => {
      try {
        const base64File = await convertToBase64(file);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/file-uploader?bucketName=profileImage&filename=${encodeURIComponent(file.name)}`,
          base64File,
          { headers: { 'Content-Type': file.type } }
        );

        // Return the file URL (from the Lambda response)
        return response.data.message.fileLocation;
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error; // Optionally, throw error to stop further uploads
      }
    });

    try {
      // Wait for all uploads to finish
      const uploadResults = await Promise.all(uploadPromises);
      return uploadResults; // Return an array of file URLs after all uploads are successful
    } catch (error) {
      console.error("Error uploading files:", error);
      return []; // Return an empty array if there's an error during any of the uploads
    }
  };


  return (
    <>
      <Button variant="primary" size="sm" className='animate-up-2 mb-3 ml-18 shadow-3d' onClick={() => setShowDefault(true)} >
        <FontAwesomeIcon className="me-2" />Register Complaint
      </Button>
      <Modal border="light" className="shadow mb-6" centered show={showDefault} onHide={handleClose} scrollable="true" animation="true" size="lg">
        <Modal.Header>
          <Modal.Title>Create</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
              <Hourglass
                visible={true}
                height="80"
                width="80"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#306cce', '#72a1ed']}
              />
              <p className="mt-2">Just a moment, your request is being processed.
                <strong>Do not refresh or navigate away.</strong>
              </p>
            </div>
          ) : <></>}
          <Form onSubmit={handleSubmit} className='form-input-pl-0'>
            {error && <Alert ref={scrollRef} variant="danger">{error}</Alert>}
            {successMessage && <Alert ref={scrollRef} variant="success">{successMessage}</Alert>}
            <p className='required'>
              <Form.Label>
                Required fields are marked with an asterisk
              </Form.Label>
            </p>
            <Row>
              <Col xs={12} md={12} className="mb-3 required">
                <Form.Group controlId="category">
                  <Form.Label>Complaint Category</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faTags} />
                    </InputGroup.Text>
                    <ReactSelect options={categoryOptions} styles={customStyles} theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#61DAFB',
                        primary: '#61DAFB',
                      }
                    })}
                      name="category"
                      value={category}
                      onChange={handleCategoryChange}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12} className="mb-3 required">
                <Form.Group controlId="subCategory">
                  <Form.Label>Violation Type</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </InputGroup.Text>

                    <ReactSelect options={availableSubcategories} styles={customStyles} theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#61DAFB',
                        primary: '#61DAFB',
                      }
                    })}
                      name="subCategory"
                      value={subCategory}
                      onChange={handleVoilationChange}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="mb-3 required">
                <Form.Group controlId="summary">
                  <Form.Label>Complaint Summary</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="summary"
                      value={formData.summary}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="mb-3 required">
                <Form.Group controlId="description">
                  <Form.Label>Description</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faAlignLeft} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="mb-3">
                <Form.Group>
                  <Form.Label>Attachment</Form.Label>
                  <div className='d-md-none'>
                    {imageState.images.length > 0 && (
                      <div className="attachment-images mt-3">
                        {imageState.images.map((image, index) => (
                          <div key={index} className="position-relative mb-3 mr-3">
                            <img
                              src={image.previewUrl}
                              alt={`Preview ${index}`}
                              className="img-fluid  card-img avatar-xl attachment-image-container-img"
                              style={{ maxWidth: '100px' }}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-danger position-absolute top-0 start-100 translate-middle"
                              onClick={() => handleRemoveImage(index)}
                              style={{ zIndex: 1 }}
                            >
                              <FontAwesomeIcon icon={faPenFancy} />
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                    <InputGroup>
                      <Form.Control
                        name="image"
                        type="file"
                        accept="image/*"
                        multiple // Allow multiple file selection
                        onChange={handleFileChange}
                      />
                    </InputGroup>
                  </div>

                  <div className='d-flex align-items-center justify-content-between d-none d-sm-flex'>
                    {imageState.images.length > 0 && (
                      <div className="d-flex mt-3">
                        {imageState.images.map((image, index) => (
                          <div key={index} className="attachment-images mb-3 mr-1">
                            <img
                              src={image.previewUrl}
                              alt={`Preview ${index}`}
                              className="img-fluid  card-img avatar-xl attachment-image-container-img"
                              style={{ maxWidth: '150px' }}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-danger position-absolute top-0 start-100 translate-middle"
                              onClick={() => handleRemoveImage(index)}
                              style={{ zIndex: 1 }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div>
                    <InputGroup>
                      <Form.Control
                        name="image"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleFileChange}
                      />
                    </InputGroup>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="mb-3 required">
                <Form.Group controlId="assignedDriver">
                  <Form.Label>Driver</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUserAlt} />
                    </InputGroup.Text>
                    <ReactSelect options={selectOptions} styles={customStyles} theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#61DAFB',
                        primary: '#61DAFB',
                        color: '#fff'
                      }
                    })}
                      name="assignedDriver"
                      value={formData.selectedDriver}
                      onChange={handleDriverChange}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group controlId="violationDate">
                  <Form.Label>Violation Date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleVioldationDate}
                    isValidDate={valid}
                    value={formData.violationDate}
                    inputProps={{
                      name: 'violationDate',
                      required: true,
                      placeholder: 'mm/dd/yyyy',
                    }}
                    required
                    renderInput={(props, openCalendar) => (
                      <InputGroup onClick={openCalendar}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          value={formData.violationDate ? moment(formData.violationDate).format('YYYY-MM-DD') : ''}
                          placeholder='Select date'
                          required
                        />
                      </InputGroup>
                    )}
                  />
                  {error.violationDate && (
                    <Form.Text className="text-danger">{error.violationDate}</Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Col xs={12} md={12} className="mb-3 required">
              <Form.Group controlId="assignedVehicle">
                <Form.Label>Assigned Vehicle</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faCarAlt} />
                  </InputGroup.Text>
                  <ReactSelect options={vehicleOptions} styles={customStyles} theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#61DAFB',
                      primary: '#61DAFB',
                      color: '#fff'
                    }
                  })}
                    name="assignedVehicle"
                    value={formData.selectedVehicle}
                    onChange={handleVehicleChange}
                    className="react-select"
                    classNamePrefix="react-select"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Row>
              <Col xs={12} md={12} className="mb-3 required">
                <Form.Group controlId="priority">
                  <Form.Label>Priority</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faFlag} />
                    </InputGroup.Text>
                    <ReactSelect options={violationOptions} styles={customStyles} theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#61DAFB',
                        primary: '#61DAFB',
                      }
                    })}
                      name="priority"
                      value={priority}
                      onChange={handlePriorityChange}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-3">
                <Button variant="primary" size="md" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
