// components/Authentication/WithAuth.js
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import SidebarAdmin from '../SidebarAdmin';
import Navbar from '../../components/Navbar';
import Preloader from '../Preloader'; // Assuming you have a Preloader component
import SignInModal from '../SubComponents/SignInModal';
import { Row } from '@themesberg/react-bootstrap';

export default function withAuth(ComponentInside) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        redirect: false,
        showModal: false, // State to control the sign-in modal
        userData: {
          id: '',
          email: '',
          username: '',
          role: '',  // 'admin' or 'driver'
        },
        adminConfirmation:false
      };
    }

    componentDidMount() {
      this.verifyToken();
    }

    verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        this.setState({ loading: false, redirect: true });
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/token`, { token });
        if (response.status === 200) {
          const { email, userRole } = response.data;
          this.fetchUserData(email, userRole);
        } else if (response.status === 400) {
          this.setState({ loading: false, showModal: true }); // Open sign-in modal on 400 status
        } else {
          throw new Error('Token verification failed');
        }
      } catch (error) {
        this.setState({ loading: false, redirect: true });
      }
    };

    fetchUserData = async (email, userRole) => {
      try {
        const userDataResponse = await axios.get(`https://yci26miwxk.execute-api.ap-southeast-1.amazonaws.com/prod/loginDetails?email=${email}&userRole=${userRole}`);
        const { adminId, firstName, lastName, email: emailAddress, approvedStatus, image } = userDataResponse.data;
        this.setState({
          loading: false,
          userData: {
            id: adminId,
            email: emailAddress,
            username: `${firstName} ${lastName}`,
            role: userRole,  // Assume role is 'admin' or 'driver'
            image: image === "Not available" ? 'https://transferappdata.s3.ap-southeast-1.amazonaws.com/user+(2).png' : image 
          },
          adminConfirmation:approvedStatus
        });
      } catch (error) {
        this.setState({ loading: false, redirect: true });
      }
    };

    closeSignInModal = () => {
      this.setState({ showModal: false });
    };

    render() {
      const { loading, redirect, userData, showModal } = this.state;

      if (loading) {
        return <Preloader show={true} />; // Show Preloader while loading
      }

      if (redirect) {
        return <Redirect to="/sign-in" />;
      }
      
      // if(!adminConfirmation){
      //   return <Redirect to={Routes.AdminConfirmation.path} />
      // }


      return (
        <>
          <SidebarAdmin data={userData} />
          <main className="content">
            <Row className="gutterReset-x">
              <Navbar data={userData} isAdmin={userData.role === 'admin'} />
            </Row>
            <ComponentInside {...this.props} data={userData} isAdmin={true} />
          </main>
          {showModal && <SignInModal onClose={this.closeSignInModal} />}
        </>
      );
    }
  };
}
