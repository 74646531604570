import React from "react";
import { Oval } from "react-loader-spinner";

export default () => {
    return (
            <div className="loader-center-450">
                <div className="text-center">
                    <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="oval-loading"
                        wrapperStyle={{display: "inline-block"}}
                        wrapperClass=""
                    />
                    <p className="text-italic mt-2 fw-semibold">Preparing Your Dashboard Insights...</p>
                </div>
            </div>
    )
}