import React from 'react';
import { Badge, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';

export default ({ loading, data }) => (
    <Card.Header className='py-3 px-4'>
        <div className='d-block-sm-md d-flex justify-content-between align-items-center'>
            {loading ? (
                <Skeleton width={150} />
            ) : (
                <>
                    <div className="mb-1-sm-md d-flex align-items-center">
                        <FontAwesomeIcon icon={faCalendarAlt} className="progress-label text-secondary" />
                        <span className="px-2">{moment.utc(data.tourDate).tz('Asia/Kuala_Lumpur').format('D MMM YYYY')}, {moment(data.tourTime, 'HH:mm:ss').format('hh:mm A')}</span>
                    </div>
                    <p className="f-14 text-muted mb-1-sm-md">{data.tourName}</p>
                    <Badge
                        className={`f-14 upperCase-keyword badge-lg badge bg-${{
                                pending: 'warning',
                                confirmed: 'info',
                                inProgress: 'primary',
                                completed: 'success',
                                cancelled: 'danger',
                                expired: 'secondary',
                                acceptedByDriver: 'primary',
                                rejectedByDriver: 'danger',
                                driverAssigned: 'info',
                                driverUnassigned: 'secondary',
                            }[data.status] || 'light'
                            }`}
                    >
                        {{
                            pending: 'Yet to Start',
                            confirmed: 'Confirmed',
                            inProgress: 'In Progress',
                            completed: 'Completed',
                            cancelled: 'Cancelled',
                            expired: 'Expired',
                            acceptedByDriver: 'Accepted by Driver',
                            rejectedByDriver: 'Rejected by Driver',
                            driverAssigned: 'Driver Assigned',
                            driverUnassigned: 'Driver Unassigned',
                        }[data.status] || 'Unknown'}
                    </Badge>
                </>
            )}
        </div>
    </Card.Header>
);
