import moment from 'moment-timezone';

// Function to format time in `hh:mm A` format
export default (time) => {
  return moment(time).format('hh:mm A');
};

// Function to format timestamp for Asia/Kuala_Lumpur timezone
export const formattedTimeStamp = (timestamp) => {
  return moment.utc(timestamp).tz('Asia/Kuala_Lumpur').format('D MMM HH:mm A');
};
