import React from 'react';
import { Modal, Button } from '@themesberg/react-bootstrap';

export default ({ show, onClose, driverDetails }) => (
    <Modal centered show={show} onHide={onClose}>
        <Modal.Header>
            <Modal.Title>Driver Assigned Successfully</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={onClose} />
        </Modal.Header>
        <Modal.Body>
            <p>Driver: {driverDetails.firstName} {driverDetails.lastName}</p>
            <p>Tour has been successfully assigned.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>Close</Button>
        </Modal.Footer>
    </Modal>
);
