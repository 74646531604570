// ReviewModal.js

import React from 'react';
import { Modal, Button, Card, Image, Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CollapsibleExpensesList from '../../../pages/examples/CollapsibleExpensesList';
import { faGlobeAsia, faLocationArrow, faMapMarkerAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import ShortenAddress from '../../../utility/ShortenAddress';
import Seat from '../../../assets/img/pax.svg';
import Driver from '../../../assets/img/driver.svg';
import { formattedTimeStamp } from '../../../utility/FormatTime';


const ReviewModal = ({ show, handleClose, userData, adminData }) => {
    return (
        <Modal show={show} onHide={handleClose} centered size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Trip summary & Expenses</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card.Body className=' pb-0'>
                    <div className="d-flex justify-content-start mb-2">
                        <FontAwesomeIcon icon={faGlobeAsia} className="progress-label text-default mt-1" />
                        <div className="px-2">
                            <span className="f-12 d-block upperCase-keyword">Tour name</span>
                            <h5>{userData.tourNameList}</h5>

                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                        <div className="d-flex justify-content-start mt-1">
                            <FontAwesomeIcon icon={faUserAlt} className="progress-label text-default mt-1" />
                            <div className="px-2">
                                <p className='upperCase-keyword'>
                                    <span className="f-12 d-block">Guest name</span>
                                    {userData.customerName}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-start mt-1">
                            <Image src={Seat} width={20} className="progress-label text-default mt-1" />
                            <div className="px-2">
                                <p className='upperCase-keyword'>
                                    <span className="f-12 d-block"># Pax</span>
                                    {userData.pax}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-start mt-1">
                            <Image src={Driver} width={20} className="progress-label text-default mt-1" />
                            <div className="px-2">
                                <p className='upperCase-keyword'>
                                    <span className="f-12 d-block">driver name</span>
                                    {userData.driverName}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="location-wrapper">
                        <div className="d-flex justify-content-start align-items-center">
                            <div>
                                <span className="circle-svg text-center w-20 f-14">
                                    <FontAwesomeIcon icon={faLocationArrow} className="progress-label text-secondary mt-1" />
                                </span>
                            </div>
                            <div className="px-2 py-2">
                                <p className="f-14 mb-0 fw-semibold">
                                    <span className="f-12 d-block">{formattedTimeStamp(userData.tripStartDate)}</span>
                                    {ShortenAddress(userData.startLocation)}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center">
                            <div>
                                <span className="circle-svg text-center w-20 f-14">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className="progress-label text-secondary mt-1" />
                                </span>
                            </div>
                            <div className="px-2 py-2">
                                <p className="f-14 mb-0 fw-semibold">
                                    <span className="f-12 d-block">{formattedTimeStamp(userData.tourCompletionDate)}</span>
                                    {ShortenAddress(userData.endLocation)}
                                </p>
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Dropdown.Divider />
                <div className='mt-4'>
                    <CollapsibleExpensesList adminId={adminData.data.id} tourId={userData.tourId} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Confirm Review
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReviewModal;
