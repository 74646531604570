import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlus, faEdit, faTrash, faEllipsisH, faSearch, faGlobeAsia } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Breadcrumb, Form, Button, Card, Pagination, Modal, Alert, InputGroup } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import ListCreation from '../utility/ListCreation';
import { Hourglass } from 'react-loader-spinner';

const CreateTour = ({ data }) => {
  const [formData, setFormData] = useState({
    tourname: '',
    itinerary: '',
    inclusions: '',
    createdBy: data.id
  });

  const [tours, setTours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 5; // Number of items per page
  const [totalRecords, setTotalRecords] = useState(0)
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false); // State for showing the modal
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const [editMode, setEditMode] = useState(false); // State to track if in edit mode
  const [editTourId, setEditTourId] = useState(null); // State to store the ID of the tour being edited
  const [showDeleteAlert, setShowDeleteAlert] = useState(false); // State to control the display of delete confirmation alert
  const [deleteTourId, setDeleteTourId] = useState(null); // State to store the ID of the tour to be deleted
  const [loading, setLoading] = useState(true); // Loading state for fetching tours
  const [updateTourLoading, setUpdateTourLoading] = useState(false); // Loading state for fetching tours
  const [searchQuery, setSearchQuery] = useState('');
  const fetchTours = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchTourList?page=${currentPage}&limit=${perPage}&search=${searchQuery}`);
      setTours(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotalRecords(response.data.totalRecords);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      handleRequestError('Error fetching tours. Please try again later.');
    }
  }, [currentPage, perPage, searchQuery]);

  useEffect(() => {
    fetchTours();
  }, [currentPage, perPage, searchQuery]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/createTourList`, formData);
      setSuccessMessage('Tour created successfully.');
      resetForm();
      handleCloseModal();
      fetchTours();
    } catch (error) {
      handleRequestError('Error creating tour. Please try again later.');
    }
  };

  const handleRequestError = (errorMessage) => {
    setErrorMessage(errorMessage);
  };

  const resetForm = () => {
    setFormData({
      tourname: '',
      itinerary: '',
      inclusions: '',
      createdBy: data.id
    });
  };

  // Modal control functions
  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    resetForm();
    setEditMode(false);
    setEditTourId(null);
  };

  const renderedFormFields = [
    {
      formFieldLabel: 'Tour Name',
      formFieldPlaceHolder: 'Enter tour name',
      formFieldName: 'tourname',
      formFieldType: 'text'
    },
    {
      formFieldLabel: 'Enter Itinerary',
      formFieldPlaceHolder: 'Enter itinerary details',
      formFieldName: 'itinerary',
      formFieldType: 'textarea'
    },
    {
      formFieldLabel: 'Enter Inclusions',
      formFieldPlaceHolder: 'Enter inclusions details',
      formFieldName: 'inclusions',
      formFieldType: 'textarea'
    }
  ].map((field, index) => (
    <Form.Group key={index} className="mb-3 required">
      <Form.Label>{field.formFieldLabel}</Form.Label>
      <Form.Control
        type={field.formFieldType}
        as={field.formFieldType === 'textarea' ? 'textarea' : undefined}
        rows={field.formFieldType === 'textarea' ? 5 : undefined}
        placeholder={field.formFieldPlaceHolder}
        name={field.formFieldName}
        value={formData[field.formFieldName]}
        onChange={handleChange}
        required
      />
    </Form.Group>
  ));

  const handleViewDetails = (tour) => {
    setSelectedTour(tour);
    setShowDrawer(true);
  };

  const handleEditTour = (tour) => {
    setShowDrawer(false);
    setEditMode(true);
    setEditTourId(tour.tourId);
    setFormData({
      tourname: tour.tourname,
      itinerary: tour.itinerary,
      inclusions: tour.inclusions,
      createdBy: tour.createdBy
    });
    setShowModal(true);
  };

  const handleUpdateTour = async (e) => {
    setUpdateTourLoading(true);
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/${editTourId}/update`, formData);
      await new Promise(resolve => setTimeout(resolve, 3000));
      setSuccessMessage('Tour updated successfully.');
      setEditMode(false);
      handleCloseModal();
      setUpdateTourLoading(false);
      resetForm();
      fetchTours();
    } catch (error) {
      await new Promise(resolve => setTimeout(resolve, 3000));
      handleRequestError('Error updating tour. Please try again later.');
      setUpdateTourLoading(false);
    }
  };

  const handleDeleteTour = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/deleteTourList/${deleteTourId}`);
      setSuccessMessage('Tour deleted successfully.');
      fetchTours();
      setShowDeleteAlert(false);
    } catch (error) {
      handleRequestError('Error deleting tour. Please try again later.');
      setShowDeleteAlert(false);
    }
  };

  const toggleDeleteAlert = (tourId) => {
    setDeleteTourId(tourId);
    setShowDeleteAlert(!showDeleteAlert);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const startEntry = totalRecords === 0 ? 0 : (currentPage - 1) * perPage + 1;
  const endEntry = Math.min(currentPage * perPage, totalRecords);

  return (
    <>
      {updateTourLoading ? (
        <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
          <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={['#306cce', '#72a1ed']}
          />
          <p className="mt-2">Just a moment, your request is being processed.</p>
        </div>
      ) : <></>}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Tours</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Tours</h4>
          <p className="mb-0">You can view all the tours and add a new tour also.</p>
        </div>
      </div>
      <div className='mb-5'>
      <Row>
        <Col xs={12} md={12}>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <Button variant="primary" className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" onClick={handleShowModal}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="px-2"> Create Tour </span>
            </Button>
          </div>
          <div className='d-flex justify-content-start mb-2'>
            <div>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search by name..."
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setCurrentPage(1); // Reset to first page when searching
                  }}
                />
              </InputGroup>
            </div>
          </div>

          <Modal show={showModal} onHide={handleCloseModal} size='lg'>
            <Modal.Header>
              <Modal.Title>{editMode ? 'Edit Tour' : 'Add Tour'}</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={handleCloseModal} />
            </Modal.Header>
            <Form onSubmit={editMode ? handleUpdateTour : handleSubmit}>
              <Modal.Body>
                {renderedFormFields}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit">
                  {editMode ? 'Update' : 'Submit'}
                </Button>

              </Modal.Footer>
            </Form>
          </Modal>
        </Col>
        <Col xs={12} md={12}>
          {successMessage && <Alert variant="success" className='mt-2'>{successMessage}</Alert>}
          {errorMessage && <Alert variant="danger" className='mt-2'>{errorMessage}</Alert>}
          {tours.map((tour, index) => (
            <Card key={index} border="light" className="mb-2 shadow">
              <Card.Body>
                <Row className="d-block row d-lg-flex row align-items-center">
                  <Col className="mb-3 mb-sm-0 col-auto">
                    {loading ? (
                      <div className="calendar d-flex">
                        <Skeleton height={60} />
                      </div>
                    ) : (
                      <div className="calendar d-flex">
                        <span className="calendar-month">{moment(tour.tourCreatedAt).format('MMM')}</span>
                        <span className="calendar-day py-2">{moment(tour.tourCreatedAt).format('DD')}</span>
                      </div>
                    )}
                  </Col>
                  <Col className="align-items-center d-block justify-content-between d-xl-flex">
                    {loading ? (
                      <>
                        <Skeleton height={30} width={200} />
                        <Skeleton count={2} />
                        <Skeleton width={100} />
                      </>
                    ) : (
                      <div className="card-body-content">
                        <Card.Title className="title-header">{tour.tourname}</Card.Title>
                        <Card.Text>
                          <span className="small fw-bold">
                            Created By <span className='text-capitalize'>{`${tour.firstName} ${tour.lastName}`}</span> on {moment(tour.tourCreatedAt).format('Do MMMM, YYYY')}
                          </span>
                        </Card.Text>
                      </div>
                    )}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                      {loading ? (
                        <Skeleton height={38} />
                      ) : (
                        <div className="action-items">
                          <Button className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" size="sm" onClick={() => handleViewDetails(tour)}>
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </Button>
                          <Button variant="primary" className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" size="sm" onClick={() => handleEditTour(tour)}>
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          <Button variant="primary" className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" size="sm" onClick={() => toggleDeleteAlert(tour.tourId)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))}
          
          {!loading && (
            <Card.Footer className="d-flex justify-content-between align-items-center">
                      <small className="fw-bold">
                      Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
                      </small>
                      {tours.length !== 0 && <Pagination className="justify-content-center">
                        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                        {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
                          <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => paginate(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={() => paginate(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        />
                      </Pagination>}
                    </Card.Footer>
          )}
        </Col>
      </Row>
      </div>
      <Modal show={showDrawer} onHide={() => setShowDrawer(false)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Tour Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTour && (
            <>
              <div className='pb-3 mb-3 border-bottom'>
                <div className="d-flex justify-content-start align-items-center mt-2">
                  <div>
                    <span className="circle-svg">
                      <FontAwesomeIcon icon={faGlobeAsia} className="progress-label text-secondary mt-1" />
                    </span>
                  </div>
                  <div className="px-2">
                    <p className="f-20 fw-bold mb-0">
                      {selectedTour.tourname}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center px-3">
                  <div className="px-2">
                    <p className="f-12 mb-0 text-muted fw-normal">
                      Created by <span className='text-capitalize fw-bold'>{selectedTour.firstName} {selectedTour.lastName}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='guest-details-wrapper'>
                <h6 className='mb-2'>Itinerary details</h6>
                <div className="d-flex justify-content-start align-items-center mb-3">
                  <div className='f-14 mb-0'>
                    <ListCreation itinerary={selectedTour.itinerary} />
                  </div>
                </div>
              </div>
              <div className='guest-details-wrapper'>
                <h6 className='mb-2'>Inclusions details</h6>
                <div className="d-flex justify-content-start align-items-center">
                  <div className='f-14 mb-0'>
                    <ListCreation itinerary={selectedTour.inclusions} />
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <Button variant="info" onClick={() => handleEditTour(selectedTour)}>
            Edit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteAlert} centered onHide={() => setShowDeleteAlert(false)} >
        <Modal.Header closeButton>
          <Modal.Title className='f-22'>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this tour?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={() => setShowDeleteAlert(false)}>
            Cancel
          </Button>
          <Button variant="danger" size="sm" onClick={handleDeleteTour}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateTour;
