import React from 'react';
import { Modal, Card, Button, Form, InputGroup, Spinner } from '@themesberg/react-bootstrap';

export default ({
    show,
    onHide,
    title,
    fields,
    loading,
    onSubmit,
    confirmationMessage,
    onConfirm,
    isConfirmationModal = false,
}) => {
    return (
        <Modal show={show} centered onHide={onHide}>
            <Card>
                <Card.Header className='d-flex align-items-center justify-content-between'>
                    <h5 className='mb-0'>{title}</h5>
                    <Button variant="close" aria-label="Close" onClick={onHide} />
                </Card.Header>
                <Card.Body className='pt-1'>
                    {isConfirmationModal ? (
                        <>
                            <p>{confirmationMessage}</p>
                            <div className='d-flex justify-content-end'>
                                <Button variant="secondary" onClick={onHide} className="me-2">Cancel</Button>
                                <Button variant="danger" onClick={onConfirm}>Confirm</Button>
                            </div>
                        </>
                    ) : (
                        <Form className="form-withvalidation" onSubmit={onSubmit}>
                            {fields.map((field, index) => (
                                <Form.Group key={index} controlId={field.controlId} className='mb-1'>
                                    <Form.Label column>{field.label}</Form.Label>
                                    <InputGroup className='d-flex'>
                                        <Form.Control
                                            type={field.type}
                                            placeholder={field.placeholder}
                                            defaultValue={field.defaultValue}
                                            onChange={field.onChange}
                                            isInvalid={!!field.error}
                                            name={field.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {field.error}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            ))}
                            <Button variant="primary" className="animate-up-2" type="submit" disabled={loading}>
                                {loading ? (
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        <span className="ms-2">Loading...</span>
                                    </>
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </Form>
                    )}
                </Card.Body>
            </Card>
        </Modal>
    );
};
