import React, { useState, useEffect } from "react";
import {
  faCarAlt,
  faCheckCircle,
  faMapMarkerAlt,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from '@themesberg/react-bootstrap';
import axios from 'axios';


import {
  CounterWidget,
  DriversLocationWidget,
  PaymentChart,
} from "../../components/Widgets";
import CurrentDayAnalytics from "../../components/Widgets/ChartsComponents/CurrentDayAnalytics";
import UpcomingLeaves from "../components/UpcomingLeaves";
import OnGoingTrips from "../components/OnGoingTrips";
import DriversStatus from "../components/DriversStatus";
import UpcomingTrips from "../components/UpcomingTrips";
import TopDrivers from "../components/TopDrivers";
import RecentCompletedTrips from "../examples/RecentCompletedTrips";
import RecentCancelledTrips from "../examples/RecentCancelledTrips";
import DelayedTrips from "../components/DelayedTrips";

const AdminDashboardOverview = (props) => {
  const [stats, setStats] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statsUrl = `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/stats`;
        const [statsResponse] = await Promise.all([
          axios.get(statsUrl)
        ]);
        setStats(statsResponse.data[0])
      } catch (error) {
      }
    };

    fetchData();
  }, [props.data.username]);


  return (
    <>
      <div className="py-4 pb-6">
        <h2>Dashboard</h2>
        <p className="mb-0">Gain a comprehensive overview of your sales, rides, and driver performance with our intuitive dashboard. Stay informed with real-time insights and analytics designed to help you make data-driven decisions and optimize your operations effortlessly.</p>
      </div>
      <Row className="d-none" >
        <Col xs={12} sm={12} lg={12}>
          <DelayedTrips />
        </Col>
      </Row>
      <div className="mb-5 d-none d-sm-block">
        <Row>
          <Col xs={12} sm={12} lg={12}>
            <PaymentChart />
          </Col>
        </Row>
      </div>
      <div className="mt-4 mb-5">
        <h4>Today's Activities</h4>
        <CurrentDayAnalytics />
      </div>
      <Row>
        <Col xs={12} sm={12} lg={12}>
          <OnGoingTrips />
        </Col>
      </Row>
      <div className="mb-5">
        <Row>
          <Col xs={12} sm={8} xl={8}>
            <DriversLocationWidget />
          </Col>
          <Col xs={12} sm={4} xl={4} className="d-none d-sm-block">
            <TopDrivers />
          </Col>
        </Row>
      </div>
      <div className="mb-5 d-block d-sm-none">
        <Row>
          <Col xs={12} sm={4} xl={4}>
            <TopDrivers />
          </Col>
        </Row>
      </div>
      <Row className="mb-5">
        <h4>Monthly Activities</h4>
        <Col xs={12} sm={6} xl={3}>
          <CounterWidget
            category="Total Bookings"
            title={stats.totalBookingsThisMonth}
            period="Feb 1 - Apr 1"
            percentage={18.2}
            icon={faCarAlt}
            iconColor="shape-primary"
          />
        </Col>
        <Col xs={12} sm={6} xl={3}>
          <CounterWidget
            category="Drivers"
            title={stats.totalDrivers}
            period="Feb 1 - Apr 1"
            percentage={18.2}
            icon={faUserTie}
            iconColor="shape-info"
            subtitle=""
          />
        </Col>
        <Col xs={12} sm={6} xl={3}>
          <CounterWidget
            category="Pending Trips"
            title={stats.totalPendingTours}
            period="Feb 1 - Apr 1"
            percentage={18.2}
            icon={faMapMarkerAlt}
            iconColor="shape-warning"
            subtitle=""
          />
        </Col>
        <Col xs={12} sm={6} xl={3}>
          <CounterWidget
            category="Completed Trips"
            title={stats.totalCompletedTours}
            period="Feb 1 - Apr 1"
            percentage={18.2}
            icon={faCheckCircle}
            iconColor="shape-success"
            subtitle=""
          />
        </Col>
      </Row>
      <div>
        <Row>
          <Col xs={12} sm={6} xl={6}>
            <UpcomingLeaves />
          </Col>
          <Col xs={12} sm={6} xl={6}>
            <DriversStatus />
          </Col>
        </Row>
      </div>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-5 d-none d-sm-block">
          <RecentCompletedTrips data={props} />
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-5 d-none d-sm-block">
          <RecentCancelledTrips data={props} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={8} xl={8}>
          <UpcomingTrips />
        </Col>
        {/* <Col xs={12} sm={4} xl={4}>
          <UpcomingTrips />
        </Col> */}
      </Row>
      {/* <div>
        <Row>
          <Col xs={12} sm={6} xl={6}>
            <DriversStatus />
          </Col>
          <Col xs={12} sm={6} xl={6}>
            <DriversStatus />
          </Col>
        </Row>
      </div> */}
    </>
  );
};

export default AdminDashboardOverview;