import { Table, Card, Button, Badge, Pagination } from '@themesberg/react-bootstrap';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Avatar from '../../components/Widgets/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faUserCheck, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from 'react-hot-toast';
import AvatarImage from '../../components/Widgets/AvatarImage';


export default ({data}) => {
    const [drivers, setDrivers] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);  
    const [totalRecords, setTotalRecords] = useState(0);
    const perPage = 10;

    const fetchUsers = React.useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/leaves?role=admin&page=${currentPage}`);
            setDrivers(response.data.leavePlans);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setTotalRecords(response.data.totalRecords)
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }, [currentPage]);

    useEffect(() => {
        fetchUsers();
    }, [currentPage, fetchUsers]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const startEntry = totalRecords === 0 ? 0 : (currentPage - 1) * perPage + 1;
    const endEntry = Math.min(currentPage * perPage, totalRecords);

    // Convert date string to a readable format
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    const SuccessToast = ({ message }) => (
        <div className='pxy-2 successToast' style={{ display: 'flex', alignItems: 'center', background: '#fff' }}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '8px' }} />
            <span>{message}</span>
        </div>
    );

    const ErrorToast = ({ message }) => (
        <div className='pxy-2 successToast' style={{ display: 'flex', alignItems: 'center', background: '#fff' }}>
            <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', marginRight: '8px' }} />
            <span>{message}</span>
        </div>
    );

    const formatFullName = (firstName, lastName) => {
        const formattedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        const formattedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
        return `${formattedFirstName} ${formattedLastName}`;
    };

    const approveUser = async(userId) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/${userId}/approveLeavePlans`, {adminId: data.data.id});
            fetchUsers();
            toast.custom(<SuccessToast message={response.data.message} />);
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Oops! Something went wrong.';
            toast.custom(<ErrorToast message={errorMessage} />);
        }
    }

    const disapproveUser = async(userId) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/${userId}/rejectLeavePlans`, {adminId: data.data.id});
            fetchUsers();
            toast.custom(<SuccessToast message={response.data.message} />);
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Oops! Something went wrong.';
            toast.custom(<ErrorToast message={errorMessage} />);
        }
    }


    return (
        <>
            <Toaster />

            <Card border="light" className="table-wrapper table-responsive shadow mb-5">
                <Card.Header className='d-flex justify-content-between align-items-center'>
                    <h5 className="mb-0 upperCase-keyword">Driver leaves</h5>
                </Card.Header>
                <Card.Body className="pb-0">

                    {drivers.length > 0 ? (
                        <Table hover className="table table-centered table-nowrap mb-4 rounded align-items-center">
                            <thead className='thead-light'>
                                <tr>
                                    <th className="border-0 rounded-start">Name</th>
                                    <th className="border-0" >Email</th>
                                    <th className="border-0" >Leave Type</th>
                                    <th className="border-0" >Start Date</th>
                                    <th className="border-0">End Date</th>
                                    <th className="border-0">Status</th>
                                    <th className="border-0 rounded-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {drivers.map((leave) => (
                                    <tr key={leave.id}>
                                        <td>
                                            <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>

                                                {leave.image ? AvatarImage(leave.image) : Avatar(
                                                        leave.firstName, leave.lastName
                                                )}
                                                <div className='px-2'>
                                                    {formatFullName(leave.firstName, leave.lastName)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{leave.email}</td>
                                        <td className={`text-capitalize text-${leave.leaveType === "sick" ? "warning" : "danger"}`}>{leave.leaveType}</td>
                                        <td>{formatDate(leave.leaveStartDate)}</td>
                                        <td>{formatDate(leave.leaveEndDate)}</td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                <Badge className={`f-12 upperCase-keyword badge-lg badge bg-${leave.leaveStatus === 'rejected' ? 'danger' : leave.leaveStatus === 'approved' ? 'tertiary' : 'warning'}`}>
                                                    {leave.leaveStatus === 'approved' ? 'approved' : leave.leaveStatus === 'rejected' ? 'rejected' : 'pending'}
                                                </Badge>
                                            </span>
                                        </td>
                                        <td>
                                                <Button className="dropdown-toggle btn btn-success me-2" size='sm' onClick={() => approveUser(leave.id)}>
                                                    <FontAwesomeIcon icon={faUserCheck} />
                                                </Button>
                                                <Button className="dropdown-toggle btn btn-danger me-2" size='sm' onClick={() => disapproveUser(leave.id)}>
                                                    <FontAwesomeIcon icon={faUserSlash} />
                                                </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <p>No upcoming leaves found.</p>
                    )}
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between align-items-center">
          <small className="fw-bold">
            Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
          </small>
          {drivers.length !== 0 && <Pagination className="justify-content-center">
            <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
            {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>}
        </Card.Footer>
            </Card>
        </>
    );
};
