import React from 'react';
import { Modal, Button, InputGroup, ListGroup, Row, Col, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCar } from '@fortawesome/free-solid-svg-icons';
import { InfinitySpin } from 'react-loader-spinner';

export default ({ vehicleData, vehicleSearchQuery, setVehicleSearchQuery, handleCloseAssignModal, handleAssignVehicleSubmit, setSelectedVehicle }) => {
    const handleVehicleAssignment = (id) => {
        setSelectedVehicle(id);
    }
    return (
        <>
            <Modal.Header>
                <Modal.Title className='upperCase-keyword'>Select Vehicle</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleCloseAssignModal} />
            </Modal.Header>
            <div className='mx-4 mt-4'>
                <InputGroup>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="Search by name..."
                        value={vehicleSearchQuery}
                        onChange={(e) => setVehicleSearchQuery(e.target.value)}
                    />
                </InputGroup>
            </div>
            <Modal.Body>
                {vehicleData.showAssignVehicleModal ? (
                    <div className='loader-wrapper'>
                        <InfinitySpin
                            visible={true}
                            width="200"
                            color="#4fa94d"
                            ariaLabel="infinity-spin-loading"
                        />
                    </div>
                ) : (
                    <>
                        {vehicleData.vehicles.map((vehicle) => (
                            <ListGroup.Item key={vehicle.id} action className="border-bottom-0 br-5" onClick={() => {
                                handleVehicleAssignment(vehicle.id)
                            }}>
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                        <FontAwesomeIcon icon={faCar} className='f-20' />
                                    </Col>
                                    <Col className="ps-0 ms--2">
                                        <h4 className="h6 mb-0">{vehicle.licensePlateNumber}</h4>
                                        <small>Seat: {vehicle.seatCapacity}</small>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAssignModal}>Close</Button>
                <Button variant="primary" onClick={handleAssignVehicleSubmit}>Assign Vehicle</Button>
            </Modal.Footer>
        </>
    );
};