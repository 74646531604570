import React, { useEffect } from 'react';

export default ({ users }) => {

  useEffect(() => {
    const initMap = () => {
      // Create map
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: {lat: -34.397, lng: 150.644},
        zoom: 4,
        mapId: "fa0eb7cf6eaa12a1"
      });
    
      const cabIcon = "https://s3.amazonaws.com/test.tvg-driver.com/cab.png";
      const iconSize = new window.google.maps.Size(60, 60);
    
      // Add user markers with info windows
      users.forEach(user => {
        if (user.latitude && user.longitude) {
          const userMarker = { lat: user.latitude, lng: user.longitude };
          const marker = new window.google.maps.Marker({
            position: userMarker,
            map,
            title: `${user.firstName} ${user.lastName}`,
            icon: {
              url: cabIcon,
              scaledSize: iconSize,
            },
          });
    
          const infoWindow = new window.google.maps.InfoWindow({
            content: `<div>
                        <div>Name: <strong>${user.firstName} ${user.lastName}</strong></div>
                        <div>Status: <strong>Available</strong></div>
                      </div>`,
          });
    
          // Add click event listener to show InfoWindow
          marker.addListener("click", () => {
            infoWindow.open({
              anchor: marker,
              map,
            });
          });
        }
      });
    
      // Adjust map bounds to include all markers
      const bounds = new window.google.maps.LatLngBounds();
      users.forEach(user => {
        if (user.latitude && user.longitude) {
          bounds.extend(new window.google.maps.LatLng(user.latitude, user.longitude));
        }
      });
      map.fitBounds(bounds);
    };
    

    // Load Google Maps API script
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBqvc7HP_JL_pjCHyHvIhoet4jdg25l4ig';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        initMap();
      };
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    };

    loadGoogleMapsScript();
  }, [users]);

  return (
    <div id="map" style={{ height: '445px', width: '100%' }}></div>
  );
};