import React, { useEffect, useState } from 'react';
import { Table, Card, Pagination, Button, Modal, Form, Badge, InputGroup } from '@themesberg/react-bootstrap';
import axios from 'axios';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEllipsisH, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import Avatar from '../../components/Widgets/Avatar';
import { getNumberWithDates } from '../../utility/ValidateDates';
import AvatarImage from '../../components/Widgets/AvatarImage';

const AllDrivers = () => {
  const [drivers, setDrivers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Initialize with 1 to prevent undefined issues
  const [totalRecords, setTotalRecords] = useState(0); // Initialize with 1 to prevent undefined issues
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showErrorModal] = useState(false);
  const [selectedDrivers, setSelectedDrivers] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState('');
  const filterParam = '';
  const perPage = 5;

  const calculateExpiry = (data) => {
    
    if(data.validUpTo === undefined || data.validUpTo === null){
      return null;
    }

    const validUpto = moment(data.validUpto);
    const now = moment();
  
    const daysLeft = validUpto.diff(now, 'days');
    if (daysLeft === 0) {
      return `Expired`;
    }
    else if (daysLeft < 30 && daysLeft >= 0) {
      return `Expiring in ${daysLeft} days`;
    } else if (daysLeft >= 30) {
      const monthsLeft = validUpto.diff(now, 'months');
      return `Expiring in ${monthsLeft} month${monthsLeft > 1 ? 's' : ''}`;
    } else {
      return 'Already expired';
    }
  };

  useEffect(() => {
    const fetchDrivers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/manage/users?pageNumber=${currentPage}&pageSizeNumber=${perPage}&search=${searchQuery}&roleParam=driver&filterParam=approved`);
           
            const updatedDriver = response.data.data.map(item => ({
            ...item,
            licenceValidity: calculateExpiry(item)
          }));          
          setDrivers(updatedDriver)
          
        setTotalPages(response.data.totalPages || 1); // Ensure totalPages is set correctly
        setTotalRecords(response.data.totalRecords || 0)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching drivers:', error);
        setLoading(false);
      }
    };

    fetchDrivers();
  }, [currentPage, perPage, searchQuery, filterParam]);

  const showDriverDetails = (driverId) => {
    history.push(`/drivers/${driverId}`);
  };

  const formatFullName = (firstName, lastName) => {
    const formattedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    const formattedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
    return `${formattedFirstName} ${formattedLastName}`;
  };

  const driverPreDeleteState = () => {
    // Map the selected drivers to extract necessary data
    const driverState = Array.from(selectedDrivers).map((driverId) => {
      const driver = drivers.find((driverData) => driverData.driverId === driverId);
      return {
        driverId: driver?.driverId || '',
        driverName: `${driver?.firstName || ''} ${driver?.lastName || ''}`,
        driverStatus: driver?.driverStatus || 'unknown',
      };
    });
  
    // Return the driver review table
    return (
      <>
        <div>
            <p className="fw-bold mb-3">
              Please review all the drivers you have selected. Drivers with a status of <span className="text-warning">"On Trip"</span> cannot be marked as inactive.
            </p>
            <Table hover responsive className="table table-centered rounded align-items-center">
              <thead className="thead-light">
                <tr>
                  <th className="border-bottom border-0 rounded-start">Name</th>
                  <th className="border-bottom text-center border-0">Status</th>
                  <th className="border-bottom border-0 text-center rounded-end">Update</th>
                </tr>
              </thead>
              <tbody>
                {driverState.map((driver) => (
                  <tr key={driver.driverId}>
                    <td>
                      <div className="upperCase-keyword fw-bold">
                        {driver.driverName || 'Unknown Driver'}
                      </div>
                    </td>
                    <td className='text-center'>
                      <Badge
                        bg={
                          driver.driverStatus === 'available'
                            ? 'success'
                            : driver.driverStatus === 'onTrip'
                            ? 'warning'
                            : driver.driverStatus === 'inactive'
                            ? 'secondary'
                            : driver.driverStatus === 'unavailable'
                            ? 'danger'
                            : driver.driverStatus === 'suspended'
                            ? 'dark'
                            : driver.driverStatus === 'pickingUpCustomer'
                            ? 'info'
                            : 'light'
                        }
                        className="text-capitalize px-2 py-1"
                      >
                        {driver.driverStatus.replace(/([A-Z])/g, ' $1').toLowerCase()}
                      </Badge>
                    </td>
                    <td className='text-center'>
                      {driver.driverStatus === 'onTrip' ? (
                        <span className="text-warning">Not Allowed</span>
                      ) : (
                        <Form.Check
                          className='text-center'
                          id={`checkbox-${driver.driverId}`}
                          htmlFor={`checkbox-${driver.driverId}`}
                          checked={selectedDrivers.has(driver.driverId)}
                          onChange={() => handleCheckboxConfirmation(driver.driverId)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
        </div>
      </>
    );
  };
  

  const deleteSelectedDrivers = async () => {
    setShowConfirmationModal(true)
    const idsToDelete = Array.from(selectedDrivers);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/deleteAll`, { driverIds: idsToDelete });
      setDrivers((prevItems) => prevItems.filter(item => !selectedDrivers.has(item.driverId)));
      setSelectedDrivers(new Set());
      setShowConfirmationModal(false)
    } catch (error) {
      console.error('Error deleting driver:', error);
    }
  };

// This function will update the selected drivers based on the current page.
const handleCheckboxConfirmation = (driverId) => {
  setSelectedDrivers((prevSelected) => {
    const newSelected = new Set(prevSelected);
    if (newSelected.has(driverId)) {
      newSelected.delete(driverId); // Unselect if already selected
    } else {
      newSelected.add(driverId); // Select the driver
    }
    return newSelected;
  });
};
  
  const handleCheckboxChange = (driverId) => {
    setSelectedDrivers(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(driverId)) {
        newSelected.delete(driverId);
      } else {
        newSelected.add(driverId);
      }
      return newSelected;
    });
  };

  const handleSelectAll = () => {
    setSelectedDrivers(new Set(drivers.map(driver => driver.driverId)));
  };

  const handleDeselectAll = () => {
    setSelectedDrivers(new Set());
  };

  const ConfirmationModal = ({ show, onClose }) => (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{showErrorModal ? 'Error' : 'Confirmation'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {show && driverPreDeleteState()}
      </Modal.Body>
      <Modal.Footer>
        {!showErrorModal && <Button variant="primary" size='sm' onClick={() => deleteSelectedDrivers()}>Mark Inactive</Button>}
        <Button variant="secondary" size="sm" onClick={onClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );

  const downloadDriversData = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/download`,
        method: 'GET',
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'drivers.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const startEntry = (currentPage - 1) * perPage + 1;
  const endEntry = Math.min(currentPage * perPage, totalRecords);  


  const statusStyles = {
    available: { bg: 'success', label: 'Available' },
    onTrip: { bg: 'warning', label: 'On Trip' },
    inactive: { bg: 'secondary', label: 'Inactive' },
    unavailable: { bg: 'danger', label: 'Unavailable' },
    suspended: { bg: 'dark', label: 'Suspended' },
    pickingUpCustomer: { bg: 'info', label: 'Picking Up Customer' },
  };

  return (
    <div className='mb-6'>
      <Card border="light" className="table-wrapper table-responsive shadow">
        <Card.Header className='d-block-sm-md d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center mb-1-sm-md'>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search by name..."
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1); // Reset to first page when searching
                }}
              />
            </InputGroup>
          </div>
          <div className='d-flex justify-content-between'>
            <Button variant="danger" size='sm' className="btn d-inline-flex align-items-center me-2" onClick={() => setShowConfirmationModal(true)} disabled={selectedDrivers.size === 0}>
              <FontAwesomeIcon icon={faTrash} />
              <span className='px-2'>Mark Inactive</span>
            </Button>
            <Button variant="primary" size='sm' type="submit" className="btn d-inline-flex align-items-center me-2 dropdown-toggle" onClick={downloadDriversData}>
              <FontAwesomeIcon icon={faDownload} />
              <span className='px-2'>Generate Report</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="pb-0">
          <Table hover responsive className="table table-centered rounded align-items-center">
            <thead className='thead-light'>
              <tr>
                <th className="border-bottom border-0 rounded-start">
                  <Form.Check
                    id="checkbox1"
                    htmlFor="checkbox1"
                    checked={selectedDrivers.size === drivers.length}
                    onChange={e => e.target.checked ? handleSelectAll() : handleDeselectAll()}
                  />
                </th>
                <th className="border-bottom border-0">Name</th>
                <th className="border-bottom border-0">Contact</th>
                <th className="border-bottom border-0">Joining Date</th>
                <th className="border-bottom border-0">Driving Licence</th>
                <th className="border-bottom border-0">Current status</th>
                <th className="border-bottom border-0 rounded-end text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                    <td><Skeleton /></td>
                  </tr>
                ))
              ) : drivers.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center my-4 no-bottom-border">
                    No drivers found.
                  </td>
                </tr>
              ) : (
                drivers.map(driver => (
                  <tr key={driver.driverId}>
                    <td>
                      <Form.Check
                        id="checkbox1"
                        htmlFor="checkbox1"
                        checked={selectedDrivers.has(driver.driverId)}
                        onChange={() => handleCheckboxChange(driver.driverId)}
                      />

                    </td>
                    <td>
                      <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                      {driver.image ? AvatarImage(driver.image) : Avatar(
                              driver.firstName, driver.lastName
                          )}
                        <div className='px-2'>
                          {formatFullName(driver.firstName, driver.lastName)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='d-block'>
                        {driver.contactNumber}
                      </span>
                      <small className='small text-gray'>
                        {driver.email}
                      </small>
                    </td>
                    <td>{moment(driver.joiningDate).format('D MMM, YYYY')}</td>
                    <td>
                        {getNumberWithDates(driver.driverLicence, driver.validUpTo)}
                    </td>
                    <td>
                      <Badge
                        bg={statusStyles[driver.driverStatus]?.bg || 'secondary'}
                        className="mx-2 me-2 card-subtitle badge-lg px-2 upperCase-keyword f-12 fw-regular"
                      >
                        {statusStyles[driver.driverStatus]?.label || 'Unknown'}
                      </Badge>
                    </td>
                    <td className='text-center'>
                      <Button className="dropdown-toggle animate-up-2 theme-bg-color-1 text-center action-btn border-0 bg-transparent" size='sm' onClick={() => showDriverDetails(driver.driverId)}>
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <small className="fw-bold">
            Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
          </small>
          {drivers.length !== 0 && (
            <Pagination className="justify-content-center">
              <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} />
            </Pagination>
          )}
        </Card.Footer>
      </Card>

      <ConfirmationModal show={showConfirmationModal} onClose={() => setShowConfirmationModal(false)} />
    </div>
  );
};

export default AllDrivers;
