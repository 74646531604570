import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button, Card, Modal, Pagination, Form, InputGroup, Badge } from '@themesberg/react-bootstrap';
import {
    faSearch,
    faUserSlash,
    faUserCheck,
    faSortAlphaUp,
    faSortAlphaDown,
    faTimesCircle,
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import CreateBookingForm from './CreateBookingForm';
import Skeleton from 'react-loading-skeleton';
import toast, { Toaster } from 'react-hot-toast';
import sendAccountVerifiedAlert from '../../utility/sendAccountVerifiedAlert';

export default ({ data }) => {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(new Set());
    const [showCreateBookingModal, setShowCreateBookingModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const perPage = 10;
    const [searchQuery, setSearchQuery] = useState('');
    const history = useHistory();
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortValue, setSortValue] = useState("asc");
    const [filterParam, setFilterParam] = useState("");

    const fetchUsers = React.useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/manage/users?pageNumber=${currentPage}&pageSize=${perPage}&search=${searchQuery}&sortDirection=${sortValue}&roleParam=driver&filterParam=${filterParam}`);
            setUsers(response.data.data);
            setTotalPages(response.data.totalPages);
            setTotalRecords(response.data.totalRecords);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }, [currentPage, perPage, searchQuery, sortValue, filterParam]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleConfirmationClose = (confirmed) => {
        setShowConfirmationModal(false);
        if (confirmed) {
            history.push('/create-booking');
        }
    };

    const handleCloseCreateBookingModal = () => {
        setShowCreateBookingModal(false);
    };

    const handleCreateBookingSubmit = async (formData) => {
        try {
            fetchUsers();
            setShowCreateBookingModal(false);
        } catch (error) {
            console.error('Error creating booking:', error);
        }
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const confirmDeleteUsers = async () => {
        try {
            const idsToDelete = Array.from(selectedUsers);
            await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/users/deleteUsers`, { userIds: idsToDelete });
            fetchUsers();
            setSelectedUsers(new Set());
            setShowDeleteConfirmationModal(false);
        } catch (error) {
            console.error('Error deleting users:', error);
        }
    };

    const SuccessToast = ({ message }) => (
        <div className='pxy-2 successToast' style={{ display: 'flex', alignItems: 'center', background: '#fff' }}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '8px' }} />
            <span>{message}</span>
        </div>
    );

    const ErrorToast = ({ message }) => (
        <div className='pxy-2 successToast' style={{ display: 'flex', alignItems: 'center', background: '#fff' }}>
            <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', marginRight: '8px' }} />
            <span>{message}</span>
        </div>
    );

      const approveUser = async(userId, driverId) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/users/${userId}/approveUser`);
            fetchUsers();
            setSelectedUsers(new Set());
            toast.custom(<SuccessToast message={response.data.message} />);
            sendAccountVerifiedAlert(driverId, "ACCOUNT_VERFIED")
            console.log("sent validation message")
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Oops! Something went wrong.';
            toast.custom(<ErrorToast message={errorMessage} />);
        }
      }

      const disapproveUser = async(userId, email) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/users/${userId}/disapprove`);
            fetchUsers();
            setSelectedUsers(new Set());
            toast.custom(<SuccessToast message={response.data.message} />);
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Oops! Something went wrong.';
            toast.custom(<ErrorToast message={errorMessage} />);
        }
      }

    const startEntry = (currentPage - 1) * perPage + 1;
    const endEntry = Math.min(currentPage * perPage, totalRecords);


    const handleSort = () => {
        if (sortValue === "asc") {
            setSortValue("desc");
        }
        else {
            setSortValue("asc")
        }
    }

    const handleFilterParam = (event) => {
        // Get the selected value from the event target
        const selectedValue = event.target.value;
      
        // Update the state with the selected value
        setFilterParam(selectedValue);
        
        // Optionally, you can also trigger any other side effects here
        // For example, refetching data or updating UI based on the selected filter
      };
    return (
        <div className='mb-6'>
            <Toaster />
            <Card border="light" className="table-wrapper table-responsive shadow">
                <Card.Header className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search by name..."
                                value={searchQuery}
                                onChange={(e) => {
                                    setSearchQuery(e.target.value);
                                    setCurrentPage(1); // Reset to first page when searching
                                }}
                            />
                        </InputGroup>
                        <Form.Select 
                            defaultValue="0" 
                            className='f-14 w-200 m-4' 
                            value={filterParam} 
                            onChange={handleFilterParam}
                        >
                            <option value="">All</option>
                            <option value="approved">Approved</option>
                            <option value="not approved">Not Approved</option>
                        </Form.Select>

                    </div>
                </Card.Header>
                <Card.Body className="pb-0">
                    <Table hover className="table table-centered table-nowrap mb-0 rounded align-items-center">
                        <thead className='thead-light'>
                            <tr>
                                <th className="border-0 rounded-start">
                                    <Button bsPrefix="text" href="#" variant="primary" onClick={handleSort}>
                                        <span className='f-12 me-2'>
                                            Email Address
                                        </span>
                                        {sortValue === "down"
                                            ? <FontAwesomeIcon icon={faSortAlphaUp} className='f-12 me-2' />
                                            : <FontAwesomeIcon icon={faSortAlphaDown} className='f-12 me-2' />}
                                    </Button>
                                </th>
                                <th className="border-0">Approval Status</th>
                                <th className="border-0">Role</th>
                                <th className="border-0">Created On</th>
                                <th className="border-0 rounded-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                Array.from({ length: 5 }).map((_, index) => (
                                    <tr key={index}>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                        <td><Skeleton /></td>
                                    </tr>
                                ))
                            ) : users.length === 0 ? (
                                <tr>
                                    <td colSpan="8" className="text-center my-4 no-bottom-border">
                                        No data found.
                                    </td>
                                </tr>
                            ) : (
                                users.map((user) => (
                                    <tr className='cursor-pointer' key={user.userId}>
                                        <td>
                                            <span>
                                                {user.email}
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                <Badge className={`f-12 upperCase-keyword badge-lg badge bg-${user.approvedStatus !== 1 ? 'danger' : 'tertiary'}`}>
                                                    {user.approvedStatus === 1 ? 'Approved' : 'Not Approved'}
                                                </Badge>
                                            </span>
                                        </td>
                                        <td>
                                            <span className='upperCase-keyword'>
                                                <Badge className={`custom-badge-outline-${user.userRole === "admin" ? 'danger' : 'tertiary'} f-12 upperCase-keyword badge-lg badge `}>
                                                    {user.userRole}
                                                </Badge>
                                            </span>
                                        </td>
                                        <td>{moment(user.createdAt).format('D MMM HH:mm')}</td>
                                        <td>
                                            <Button className="dropdown-toggle btn btn-success me-2" size='sm' onClick={() => approveUser(user.id, user.driverId)}>
                                                <FontAwesomeIcon icon={faUserCheck} />
                                            </Button>
                                            <Button className="dropdown-toggle btn btn-danger me-2" size='sm' onClick={() => disapproveUser(user.id)}>
                                                <FontAwesomeIcon icon={faUserSlash} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between align-items-center">
                    <small className="fw-bold">
                        Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
                    </small>
                    {users.length !== 0 && <Pagination className="justify-content-center">
                        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                        {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        />
                    </Pagination>}
                </Card.Footer>
            </Card>

            {/* Confirmation Modal */}
            <Modal as={Modal.Dialog} centered show={showConfirmationModal} onHide={() => handleConfirmationClose(false)} dialogClassName="custom-modal-width">
                <Modal.Header>
                    <Modal.Title>Confirm Navigation</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={() => handleConfirmationClose(false)} />
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to navigate to the new booking page?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleConfirmationClose(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => handleConfirmationClose(true)}>Yes, Go to New Booking</Button>
                </Modal.Footer>
            </Modal>

            {/* Create Booking Modal */}
            <Modal as={Modal.Dialog} centered show={showCreateBookingModal} onHide={handleCloseCreateBookingModal} dialogClassName="custom-modal-width">
                <Modal.Header>
                    <Modal.Title>Create Booking</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleCloseCreateBookingModal} />
                </Modal.Header>
                <Modal.Body>
                    <CreateBookingForm data={data} onSubmit={handleCreateBookingSubmit} onCancel={handleCloseCreateBookingModal} />
                </Modal.Body>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal as={Modal.Dialog} centered show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)} dialogClassName="custom-modal-width">
                <Modal.Header>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={() => setShowDeleteConfirmationModal(false)} />
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the selected users?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmationModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={confirmDeleteUsers}>Yes, Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
