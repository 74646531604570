import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Breadcrumb, Button, Badge, Dropdown, Modal } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faLocationArrow, faMapMarkerAlt, faPhoneAlt, faEnvelope, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useLocation, useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import Avatar from '../Widgets/Avatar';
import TripRating from './TripRating';
import toast, { Toaster } from 'react-hot-toast';
import CollapsibleExpensesList from '../../pages/examples/CollapsibleExpensesList';
import TripTimeline from './TripComponents/TripTimeline';
import ShortenAddress from '../../utility/ShortenAddress';
import ListCreation from '../../utility/ListCreation';
import AvatarImage from '../Widgets/AvatarImage';

export default () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true); // State to manage loading indicator
    const [driversOptions, setDriversOptions] = useState(false);
    const [ratings, setRatings] = useState({});
    const { tourId } = useParams();
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [isRewardLoading, setRewardLoading] = useState(false);
    const [reloadTrigger, setReloadTrigger] = useState(0);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const adminIdFromQuery = queryParams.get('adminId');
    const adminId = adminIdFromQuery;



    useEffect(() => {
        const fetchTripData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tripDetailsBrief?tourId=${tourId}`);
                setData(response.data); // Assuming response.data contains user details
                setDriversOptions(response.data.driverName !== null && response.data.driverName !== "")
                setRatings(response.data.ratingScore);
                setLoading(false);

            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false); // Once data is fetched (or error occurred), setLoading to false
            }
        };

        fetchTripData();
    }, [tourId, reloadTrigger]); // Added reloadTrigger as dependency

    const SuccessToast = () => (
        <div className='pxy-2 successToast' style={{ display: 'flex', alignItems: 'center', background: '#fff' }}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '8px' }} />
            <span>Your rating has been successfully saved.</span>
        </div>
    );

    const ErrorToast = ({ message }) => (
        <div className='pxy-2 successToast' style={{ display: 'flex', alignItems: 'center', background: '#fff' }}>
            <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', marginRight: '8px' }} />
            <span>{message}</span>
        </div>
    );


    const handleCreditRewards = async (driverId) => {
        if (!driverId) return;
        try {
            setRewardLoading(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/rewards/credit`, { tourId, driverId, rating: 5, adminId });
            toast.custom(<SuccessToast />);
            setReloadTrigger((prev) => prev + 1); // Trigger data reload
        } catch (error) {
            // Check if the error response exists and extract the message
            const errorMessage = error.response && error.response.data && error.response.data.error
                ? error.response.data.error
                : 'Oops! Something went wrong.';

            toast.custom(<ErrorToast message={errorMessage} />);
            console.error('Error updating rating:', errorMessage);
        } finally {
            setRewardLoading(false);
        }
    };

    const ProfileData = () => (
        <div>
            <Row className="d-flex justify-content-between align-items-center py-4">
                <div className="d-block mb-0 md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Trip Itinerary</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className='d-flex justify-content-between align-items-center'>
                        {!loading ? <div>
                            <h4>Trip Details</h4>
                            <div className='d-flex justify-content-start align-items-center mt-4'>
                          <div>
                            {data.adminImage != "Not available" 
                            ? 
                            <div className='d-flex justify-content-center align-items-center gap-2'>{AvatarImage(data.adminImage)} created by: {data.adminName}
                            {data.adminEmail}
                            </div>
                            : <div className='d-flex justify-content-center align-items-center gap-3'>
                                    {Avatar(data.adminName.split(" ")[0], data.adminName.split(" ")[1])}
                                    <div>
                                         <p className='mb-0 f-14'>Created by {data.adminName}</p>
                                        <p className='mb-0 f-14'>{data.adminEmail}</p>
                                    </div>
                                </div>
                            }
                          </div>
                        </div>
                        </div> : <></>}
                    </div>
                </div>
            </Row>
            <Row>
                <Col>
                    {!loading ? parseInt(ratings) === 5 && data.tourDriverRating === 0 ? <div className='d-flex justify-content-between align-items-center alert alert-warning' role="alert">
                        <div className='px-4 pl-0'>
                            <div className='d-flex justify-content-between'>
                                <span className='text-dark fw-bold mx-2 ml-0'>Rating:</span>
                                <TripRating
                                    rating={ratings || 0}
                                    onRate={() => { }}
                                    disabled={true}
                                />
                            </div>
                            {loading ? <Skeleton width={150} /> : (
                                <p className="f-14 text-dark mb-0">Rated By: {data.adminName}</p>
                            )}
                        </div>
                        {loading ? <Skeleton width={150} /> : (
                            <div className='text-right'>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    className='animate-up-2'
                                    onClick={() => handleCreditRewards(data.driverId)}
                                    disabled={isRewardLoading}
                                >
                                    <FontAwesomeIcon className="me-2" />Add Rewards
                                </Button>
                                <p className='f-12 text-center mt-2 mb-0'> By clicking <strong className='fw-bold'>Add Rewards</strong>, You can add RM 10 to driver's wallet.</p>
                            </div>
                        )}
                    </div> : <></> : <></>}
                    <div className="my-3" />
                    {loading ? (
                        <Skeleton width={150} />
                    ) : (
                        <>
                            {data.hasExpenses === 1 && (
                                <div className='d-flex justify-content-between align-items-center alert alert-secondary' role="alert">
                                    <div className='px-4 pl-0'>
                                        <p className='mb-0 text-dark fw-bold'>Action Required:</p>
                                        <p className='f-14 mb-0'>Driver has submitted new expenses for review. Please take a moment to review and approve these expenses.</p>
                                    </div>
                                    <div>
                                        <Button variant="primary" size="sm" className='animate-up-2' onClick={handleShow}>
                                            <FontAwesomeIcon className="me-2" />Show Expenses
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    <Card className='trip-wrapper mb-6'>
                        <Card.Header className='py-3 px-4'>
                            <div className='d-flex justify-content-between align-items-center'>
                                {loading ? <Skeleton width={150} /> : (
                                    <p className="f-14 text-dark mb-0">Tour Name: {data.tourName}</p>
                                )}
                                {loading ? <Skeleton width={100} /> : (
                                    <Badge className={`f-14 upperCase-keyword badge-lg badge bg-${data.status === 'pending' ? 'warning' : 'tertiary'}`}>
                                        {data.status === 'pending' ? 'Yet to start' : 'Completed'}
                                    </Badge>
                                )}
                            </div>
                        </Card.Header>
                        <Card.Body className='start-location-field'>
                            <div className='d-flex align-item-center justify-content-between'>
                                <div className="location-wrapper">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div>
                                            <span className="circle-svg">
                                                <FontAwesomeIcon icon={faLocationArrow} className="progress-label text-secondary mt-1" />
                                            </span>
                                        </div>
                                        <div className="px-2">
                                            <p className="f-14 mb-0">
                                                {loading ? <Skeleton width={100} /> : (<>
                                                    {ShortenAddress(data.startLocation)}
                                                </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center mt-3">
                                        <span className="circle-svg">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} className="progress-label text-danger mt-1" />
                                        </span>
                                        <div className="px-2">
                                            <p className="f-14 mb-0">
                                                {loading ? <Skeleton width={100} /> : (<>
                                                    {ShortenAddress(data.endLocation)}
                                                </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-2 d-flex align-items-center">
                                    <p className="f-14 mb-0 text-dark fw-bold">
                                        {loading ? <Skeleton width={100} /> : (<>
                                            RM {data.fare}
                                        </>
                                        )}
                                    </p>
                                </div>
                            </div>
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <div className='guest-details-wrapper'>
                                <h6 className='mb-4'>Passengers details</h6>
                                <div className="d-flex justify-content-start align-items-center mb-3">
                                    {loading ? <Skeleton width={150} /> : (
                                        <>
                                            {Avatar(data.customerName.split(" ")[0], data.customerName.split(" ")[1])}
                                            <div className="px-3">
                                                <p className='mb-0'>
                                                    <span className="upperCase-keyword">
                                                        {data.customerName}
                                                    </span>
                                                </p>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <span className="circle-svg">
                                                        <FontAwesomeIcon icon={faEnvelope} className="progress-label text-danger mt-1" />
                                                    </span>
                                                    <div className="px-2">
                                                        <p className="f-12 mb-0">
                                                            {loading ? <Skeleton width={100} /> : (<>
                                                                {data.email}
                                                            </>
                                                            )}
                                                        </p>
                                                    </div>
                                                    <span className="circle-svg">
                                                        <FontAwesomeIcon icon={faPhoneAlt} className="progress-label text-danger mt-1" />
                                                    </span>
                                                    <div className="px-2">
                                                        <p className="f-12 mb-0">
                                                            {loading ? <Skeleton width={100} /> : (<>
                                                                {data.phone}
                                                            </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className='alert alert-info bg-light-blue mb-4'>
                                    {loading ? <Skeleton width={150} /> : (
                                        <div className="d-flex justify-content-start">
                                            <div className="px-2">
                                                <p className="f-12 mb-0 fw-bold">
                                                    {loading ? <Skeleton width={100} /> : (<>
                                                        Number of pax: {data.pax}
                                                    </>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <div className='guest-details-wrapper'>
                                <div>
                                    <h6 className='mb-4'>Driver details</h6>
                                    {loading ? <Skeleton width={150} /> : driversOptions ? (
                                        <>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex justify-content-start align-items-center mb-3">
                                                    {data.driverImage ? AvatarImage(data.driverImage) : Avatar(
                                                        data.driverName.split(" ")[0], data.driverName.split(" ")[1]
                                                    )}
                                                    <div className="px-3">
                                                        <p className='mb-0'>
                                                            <span className="upperCase-keyword">
                                                                {data.driverName}
                                                            </span>
                                                        </p>
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <span className="circle-svg">
                                                                <FontAwesomeIcon icon={faEnvelope} className="progress-label text-danger mt-1" />
                                                            </span>
                                                            <div className="px-2">
                                                                <p className="f-12 mb-0">
                                                                    {loading ? <Skeleton width={100} /> : (<>
                                                                        {data.driverEmail}
                                                                    </>
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <span className="circle-svg">
                                                                <FontAwesomeIcon icon={faPhoneAlt} className="progress-label text-danger mt-1" />
                                                            </span>
                                                            <div className="px-2">
                                                                <p className="f-12 mb-0">
                                                                    {loading ? <Skeleton width={100} /> : (<>
                                                                        {data.driverPhone}
                                                                    </>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {!loading ? parseInt(ratings) === 5 && parseInt(data.rewarded) !== 0 && data.tourDriverRating === 1 ? <div className='alert alert-warning' role="alert">
                                                <div className='px-4 pl-0'>
                                                    {loading ? <Skeleton width={150} /> : (
                                                        <p className="f-14 text-dark mb-0"><strong>RM 10</strong> has been credited to driver's wallet.</p>
                                                    )}
                                                </div>
                                            </div> : <></> : <></>}
                                        </>
                                    )
                                        :
                                        (
                                            <div className='alert alert-danger mb-4'>
                                                {loading ? <Skeleton width={150} /> : (
                                                    <div className="d-flex justify-content-start">
                                                        <div className="px-2">
                                                            <p className="f-12 mb-0 fw-bold">
                                                                {loading ? <Skeleton width={100} /> : (<>
                                                                    No driver has been assigned for this tour
                                                                </>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>)
                                    }
                                </div>
                            </div>
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <div className='guest-details-wrapper'>
                                <h6 className='mb-4'>Timeline</h6>
                                {loading ? <Skeleton width={150} /> : (
                                    <>
                                        <div className="d-flex justify-content-start align-items-center">
                                            <div className='f-14 mb-0'>
                                                {data.timeline ? <TripTimeline data={data.timeline} /> : (<>
                                                    No data available                                                        </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <div className='guest-details-wrapper'>
                                <h6 className='mb-2'>Itinerary details</h6>
                                {loading ? <Skeleton width={150} /> : (
                                    <>
                                        <div className="d-flex justify-content-start align-items-center">
                                            <div className='f-14 mb-0'>
                                                <ListCreation itinerary={data.itinerary} />
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <div className='guest-details-wrapper'>
                                <h6 className='mb-2'>Inclusions details</h6>
                                {loading ? <Skeleton width={150} /> : (
                                    <>
                                        <div className="d-flex justify-content-start align-items-center mb-3">
                                            <div className='f-14 mb-0'>
                                                <ListCreation itinerary={data.inclusions} />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <div className='guest-details-wrapper'>
                                <h6 className='mb-4'>Remarks & Other Information</h6>
                                {loading ? <Skeleton width={150} /> : (
                                    <>
                                        <div className="d-flex justify-content-start align-items-center mb-3">
                                            {data.remarks}
                                        </div>
                                    </>
                                )}

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );

    return (
        <>
            <Toaster />
            <ProfileData />
            <Modal centered show={showModal} onHide={handleClose}  size='xl'>
                {showModal && (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>Expenses</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CollapsibleExpensesList adminId={adminId} tourId={tourId} />
                        </Modal.Body>
                    </>)}
            </Modal>
        </>
    );
}