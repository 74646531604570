import React from 'react';
import { Oval } from 'react-loader-spinner';

export default () => {
    return (
        <div className='d-flex justify-content-center align-items-center'>
            <Oval
                visible={true}
                height="30"
                width="30"
                color="#262B40"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
                strokeWidth="5"
                secondaryColor="rgb(38 43 64 / 58%)"
            />
        </div>
    );
};