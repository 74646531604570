import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Tab, Nav, Row, Breadcrumb, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const LazyDriversToursHistory = lazy(() => import('../../components/SubComponents/DriverToursHistory'));
const LazyDriverWallet = lazy(() => import('./DriverWallet'));
const LazyDriverProfilePage = lazy(() => import('../../components/SubComponents/DriverProfilePage'));
const LazyDriversLeaves = lazy(() => import('../../components/SubComponents/DriversLeaves'));
const LazyDriverPerformance = lazy(() => import('../../components/SubComponents/DriverDetails/DriverPerformance'));
const LazyDriverOverview = lazy(() => import('../../components/SubComponents/DriverDetails/DriverOverview'));
const LazyDriverComplaints = lazy(() => import('../../components/SubComponents/DriverDetails/SubComponents/DriverComplaints'));
export default () => {
  const { driverId } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('overview'); // Track the active tab
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchDriverProfile?driverId=${driverId}`);
        setUser(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [driverId]);

  return (
    <>
      <Row className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Drivers</Breadcrumb.Item>
            <Breadcrumb.Item active>Driver Profile</Breadcrumb.Item>
          </Breadcrumb>
          <h4 className='upperCase-keyword'>{loading ? "Loading..." : `${user?.firstName} ${user?.lastName}`}</h4>
          <p className="mb-0">View driver's profile and other details</p>
        </div>
      </Row>

      <div className="row">
        <div className="col-12">
          {/* Tab */}
          <Tab.Container defaultActiveKey="overview"  onSelect={(k) => setActiveTab(k)}>
            <Card className='mb-4'>
              <Nav variant="tabs" className="flex-direction-sm-md nav-tabs py-2" id="nav-tab" role="tablist">
                <Nav.Item className='px-4'>
                  <Nav.Link eventKey="overview">Overview</Nav.Link>
                </Nav.Item>
                <Nav.Item className='px-4'>
                  <Nav.Link eventKey="performance">Performance</Nav.Link>
                </Nav.Item>
                <Nav.Item className='px-4'>
                  <Nav.Link eventKey="profile">Profile</Nav.Link>
                </Nav.Item>
                <Nav.Item className='px-4'>
                  <Nav.Link eventKey="assignments">Trips</Nav.Link>
                </Nav.Item>
                <Nav.Item className='px-4'>
                  <Nav.Link eventKey="walletTransactions">Wallet & Transactions</Nav.Link>
                </Nav.Item>
                <Nav.Item className='px-4'>
                  <Nav.Link eventKey="driverLeaves">Leaves</Nav.Link>
                </Nav.Item>
                <Nav.Item className='px-4'>
                  <Nav.Link eventKey="complaints">Complaints</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card>
            <Tab.Content className="tab-content" id="nav-tabContent">
              <Tab.Pane eventKey="overview" className="tab-pane fade" role="tabpanel">
                <Card>
                  <Card.Body className='table-wrapper table-responsive shadow '>
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyDriverOverview data={driverId} />
                    </Suspense>                  
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="performance" className="tab-pane fade" role="tabpanel">
                <Card>
                  <Card.Body className='table-wrapper table-responsive shadow '>
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyDriverPerformance data={driverId} />
                    </Suspense>
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="profile" className="tab-pane fade" role="tabpanel">
                <Card>
                  <Card.Body className='table-wrapper table-responsive shadow '>
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyDriverProfilePage  />
                    </Suspense>                  
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="assignments" className="tab-pane fade" role="tabpanel">
                <Suspense fallback={<div>Loading...</div>}>
                  <LazyDriversToursHistory data={driverId} />
                </Suspense>
              </Tab.Pane>
              <Tab.Pane eventKey="walletTransactions" className="tab-pane fade" role="tabpanel">
                <Suspense fallback={<div>Loading...</div>}>
                  <LazyDriverWallet data={driverId} />
                </Suspense>                  
              </Tab.Pane>
              <Tab.Pane eventKey="driverLeaves" className="tab-pane fade" role="tabpanel">
                <Suspense fallback={<div>Loading...</div>}>
                  <LazyDriversLeaves data={driverId} />
                </Suspense>
              </Tab.Pane>
              <Tab.Pane eventKey="complaints" className="tab-pane fade" role="tabpanel">
                <Suspense fallback={<div>Loading...</div>}>
                  <LazyDriverComplaints data={driverId} />
                </Suspense>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};
