import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';

export default ({ data }) => (
    <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-start align-items-center mb-3">
            <div className='avatar'>
                <FontAwesomeIcon icon={faCar} className='f-20' />
            </div>
            <div className='d-flex justify-space-between align-items-center'>
                <div className="px-3">
                    <p className='mb-0'>
                        <span className="f-14 upperCase-keyword">
                            Car Number Plate: {data.licensePlateNumber}
                        </span>
                    </p>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='mb-0'>
                            <span className="f-14 upperCase-keyword">
                                Seats: {data.seatCapacity}
                            </span>
                        </p>
                        <div>
                            <span className="f-14 upperCase-keyword">
                                Model: {data.carModel}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);