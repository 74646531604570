import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import ShortenAddress from '../../../utility/ShortenAddress';

export default ({ loading, data }) => (
    <>
        <div className="location-wrapper">
            <div className="d-flex justify-content-start align-items-center mt-2">
                <div>
                    <span className="circle-svg">
                        <FontAwesomeIcon icon={faLocationArrow} className="progress-label text-secondary mt-1" />
                    </span>
                </div>
                <div className="px-2">
                    <p className="f-14 mb-0">
                        {loading ? <Skeleton width={100} /> : (<>
                            {ShortenAddress(data.startLocation)}
                        </>
                        )}
                    </p>
                </div>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3">
                <span className="circle-svg">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="progress-label text-danger mt-1" />
                </span>
                <div className="px-2">
                    <p className="f-14 mb-0">
                        {loading ? <Skeleton width={100} /> : (<>
                            {ShortenAddress(data.endLocation)}
                        </>
                        )}
                    </p>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-start align-items-center mt-3">
            <span className="">
                <span className="progress-label text-danger mt-1">
                    <span className='text-dark'>
                        Commission:
                    </span>
                    <span className='px-1 fw-bold'> RM </span>
                </span>
            </span>
            <div>
                <p className="f-14 mb-0 fw-bold">
                    {loading ? <Skeleton width={100} /> : (<>
                        {(data.fare)}
                    </>
                    )}
                </p>
            </div>
        </div>
    </>
);
