import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button, Card, Modal, Pagination, OverlayTrigger, Tooltip, InputGroup, Form, Dropdown } from '@themesberg/react-bootstrap';
import { faPlus, faSearch, faEllipsisH, faFilter, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import CreateBookingForm from '../components/CreateBookingForm'; // Example import, adjust as needed
import Skeleton from 'react-loading-skeleton';
import FormatTz from '../../utility/FormatTz';
import Avatar from '../../components/Widgets/Avatar';
import Datetime from 'react-datetime';
import AvatarImage from '../../components/Widgets/AvatarImage';

const TourList = ({ data }) => {
  const [tours, setTours] = useState([]);
  const [showCreateBookingModal, setShowCreateBookingModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const perPage = 10; // Number of items per page
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();
  const [totalRecords, setTotalRecords] = useState(0);
  const [dateFilter, setDateFilter] = useState("");
  const [rangeFilterSelected, setRangeFilterSelected] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Filter By"); // Default label
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleFilterSelect = (filter, label) => {
    if (filter !== "custom_range") {
      setDateFilter(filter);
      setSelectedFilter(label); // Update dropdown label
      setStartDate(null);
      setEndDate(null);
      setRangeFilterSelected(false)
    }
  };


  const handleRangeFilterSelect = (filter, label) => {
    if (filter === "custom_range") {
      setRangeFilterSelected(true);
      setSelectedFilter(label);
    }
  }

  const fetchTours = React.useCallback(async () => {
    try {
      const filterParams = new URLSearchParams({
        pageNumber: currentPage,
        pageSize: perPage,
        search: searchQuery,
        filterByDate: startDate ? "custom_range" : dateFilter,
        startDate: startDate ? startDate.toISOString() : "",
        endDate: endDate ? endDate.toISOString() : startDate ? startDate.toISOString() : "",
      });

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchTours?${filterParams.toString()}`);
      setTours(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotalRecords(response.data.totalRecords);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.error('Error fetching tours:', error);
    }
  }, [currentPage, perPage, searchQuery, dateFilter, startDate, endDate]);

  useEffect(() => {
    fetchTours();
  }, [currentPage, perPage, searchQuery, dateFilter, startDate, endDate, fetchTours]);

  const handleCreateBooking = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirmationClose = (confirmed) => {
    setShowConfirmationModal(false);
    if (confirmed) {
      history.push('/create-booking');
    }
  };

  const handleCloseCreateBookingModal = () => {
    setShowCreateBookingModal(false);
  };

  const handleCreateBookingSubmit = async (formData) => {
    try {
      fetchTours();
      setShowCreateBookingModal(false);
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

  const showTripDetails = (tourId) => {
    history.push(`/tripdetails/${tourId}`);
  };

  const handleDateChange = (date) => {
    setStartDate(date)
  };

  const handleEndDateChange = (date) => {
    setEndDate(date)
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
  const startEntry = totalRecords === 0 ? 0 : (currentPage - 1) * perPage + 1;
  const endEntry = Math.min(currentPage * perPage, totalRecords);

  return (
    <div className='mb-6'>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Button variant="primary" className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" onClick={handleCreateBooking}>
          <FontAwesomeIcon icon={faPlus} className='me-2' />
          <span className='px-2'>Add New Trip</span>
        </Button>
      </div>

      <Card border="light" className="shadow">
        <Card.Header className='d-block-sm-md d-flex justify-content-between align-items-center'>
          <div>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search by name..."
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1); // Reset to first page when searching
                }}
              />
            </InputGroup>
          </div>
          <div className="d-flex align-items-center gap-2">
            {/* Date Filter Dropdown */}
            <Dropdown className="btn-toolbar bg-transparent btn-filter-input">
              <Dropdown.Toggle as="text" variant="primary" size="sm">
                <FontAwesomeIcon icon={faFilter} className="me-2 f-14" />
                {selectedFilter}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2 filterDropDown">
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("today", "Today")}
                >
                  Today
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("tomorrow", "Tomorrow")}
                >
                  Tomorrow
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("week", "Week")}
                >
                  This Week
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("month", "Month")}
                >
                  This Month
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleRangeFilterSelect("custom_range", "Select by date range")}
                >
                  Select by Date Range
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Custom Date Range Picker */}
            {rangeFilterSelected && (
              <div className="d-flex align-items-center">
                <div>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleDateChange}
                    value={startDate}
                    inputProps={{
                      required: true,
                      placeholder: 'mm/dd/yyyy',
                    }}
                    required
                    renderInput={(props, openCalendar) => (
                      <InputGroup onClick={openCalendar}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
                          placeholder='Select date'
                          required
                        />
                      </InputGroup>
                    )}
                  />
                </div>
                <span className="mx-2">to</span>
                <div className='me-2'>
                <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleEndDateChange}
                    value={endDate}
                    inputProps={{
                      required: true,
                      placeholder: 'mm/dd/yyyy',
                    }}
                    required
                    renderInput={(props, openCalendar) => (
                      <InputGroup onClick={openCalendar}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
                          placeholder='Select date'
                          required
                        />
                      </InputGroup>
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body className="pb-0 table-wrapper table-responsive">
          <Table className="table table-centered table-nowrap mb-0 rounded align-items-center">
            <thead className='thead-light'>
              <tr>
                <th className="border-0 rounded-start">Guest Name</th>
                {/* <th className="border-bottom">Tour Name</th> */}
                <th className="border-0">Pick Up Point</th>
                <th className="border-0">Drop Off</th>
                <th className="border-0">Start Date & Time</th>
                <th className="border-0">Net Cost</th>
                <th className="border-0">Commission</th>
                <th className="border-0">Assigned Driver</th>
                <th className="border-0 rounded-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: perPage }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                  </tr>
                ))
              ) : tours.length === 0 ? (
                <tr>
                  <td colSpan="8" className="text-center my-4 no-bottom-border">
                    No data found.
                  </td>
                </tr>
              ) : (
                tours.map((tour) => (
                  <tr key={tour.tourId}>
                    <td>
                      <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                        {Avatar(
                          tour.customerFullName.split(" ")[0], tour.customerFullName.split(" ")[1]
                        )}
                        <div className='px-2'>
                          {tour.customerFullName}
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-danger">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.startLocation}</Tooltip>} >
                          <div className="truncate-text">
                            {tour.startLocation}
                          </div>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td>
                      <span className="text-success">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.endLocation}</Tooltip>} >
                          <div className="truncate-text">
                            {tour.endLocation}
                          </div>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td>{FormatTz(tour.tourDate)}, {moment(tour.tourTime, 'HH:mm:ss').format('hh:mm A')}</td>
                    <td><span className="fw-bold">RM {tour.netCost}</span></td>
                    <td><span className="fw-bold">RM {tour.fare}</span></td>
                    <td>
                        <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                          {tour.driverImage ? AvatarImage(tour.driverImage) : Avatar(
                              tour.driverFirstName, tour.driverLastName
                          )}
                          <div className='px-2'>
                            {tour.driverFirstName} {tour.driverLastName}
                          </div>
                        </div>
                      </td>
                      <td className='text-center'>
                        <Button className="dropdown-toggle animate-up-2 theme-bg-color-1 text-center action-btn border-0 bg-transparent" size='sm' onClick={() => showTripDetails(tour.tourId)}>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </Button>
                      </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
        {/* Pagination */}
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <small className="fw-bold">
            Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
          </small>
          {tours.length !== 0 && <Pagination className="justify-content-center">
            <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
            {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>}
        </Card.Footer>
      </Card>

      {/* Confirmation Modal */}
      <Modal as={Modal.Dialog} centered show={showConfirmationModal} onHide={() => handleConfirmationClose(false)} dialogClassName="custom-modal-width">
        <Modal.Header>
          <Modal.Title>Confirm Navigation</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => handleConfirmationClose(false)} />
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to navigate to the new booking page?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleConfirmationClose(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => handleConfirmationClose(true)}>Yes, Go to New Booking</Button>
        </Modal.Footer>
      </Modal>

      {/* Create Booking Modal */}
      <Modal as={Modal.Dialog} centered show={showCreateBookingModal} onHide={handleCloseCreateBookingModal} dialogClassName="custom-modal-width">
        <Modal.Header>
          <Modal.Title>Create Booking</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseCreateBookingModal} />
        </Modal.Header>
        <Modal.Body>
          <CreateBookingForm data={data} onSubmit={handleCreateBookingSubmit} onCancel={handleCloseCreateBookingModal} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TourList;
