import React, { Suspense, useEffect, useState } from 'react';
import { Button, Card, Modal, Pagination } from '@themesberg/react-bootstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import CreateBookingForm from './CreateBookingForm';
import Skeleton from 'react-loading-skeleton';
const FetchViolations = React.lazy(() => import('../../components/SubComponents/FetchViolations'));

export default ({ data }) => {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(new Set());
    const [showCreateBookingModal, setShowCreateBookingModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const perPage = 10;
    const searchQuery = '';
    const history = useHistory();
    const [totalRecords, setTotalRecords] = useState(0);
    const sortValue = "asc";
    const filterParam = "";

    const fetchUsers = React.useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/violations`);
            setUsers(response.data.data);
            setTotalPages(response.data.totalPages);
            setTotalRecords(response.data.totalRecords);
            setLoading(false);
        } catch (error) {
        }
    }, [currentPage, perPage, searchQuery, sortValue, filterParam]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleConfirmationClose = (confirmed) => {
        setShowConfirmationModal(false);
        if (confirmed) {
            history.push('/create-booking');
        }
    };

    const handleCloseCreateBookingModal = () => {
        setShowCreateBookingModal(false);
    };

    const handleCreateBookingSubmit = async() => {
        try {
            fetchUsers();
            setShowCreateBookingModal(false);
        } catch (error) {
        }
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const confirmDeleteUsers = async () => {
        try {
            const idsToDelete = Array.from(selectedUsers);
            await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/users/deleteUsers`, { userIds: idsToDelete });
            fetchUsers();
            setSelectedUsers(new Set());
            setShowDeleteConfirmationModal(false);
        } catch (error) {
        }
    };
    
    const startEntry = (currentPage - 1) * perPage + 1;
    const endEntry = Math.min(currentPage * perPage, totalRecords);


      const showViolationDetails = (violationId) => {
        history.push(`/complaint/${violationId}`);
      };
    
    return (
        <div className='mb-6'>
            <Card border="light" className="table-wrapper table-responsive shadow">
                <Card.Body className="pb-0">
                <Suspense fallback={<Skeleton count={5} />}>
                        <FetchViolations
                            users={users}
                            loading={loading}
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            showViolationDetails={showViolationDetails}
                        />
                    </Suspense>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between align-items-center">
                    <small className="fw-bold">
                        Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
                    </small>
                    {users.length !== 0 && <Pagination className="justify-content-center">
                        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                        {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        />
                    </Pagination>}
                </Card.Footer>
            </Card>

            {/* Confirmation Modal */}
            <Modal as={Modal.Dialog} centered show={showConfirmationModal} onHide={() => handleConfirmationClose(false)} dialogClassName="custom-modal-width">
                <Modal.Header>
                    <Modal.Title>Confirm Navigation</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={() => handleConfirmationClose(false)} />
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to navigate to the new booking page?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleConfirmationClose(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => handleConfirmationClose(true)}>Yes, Go to New Booking</Button>
                </Modal.Footer>
            </Modal>

            {/* Create Booking Modal */}
            <Modal as={Modal.Dialog} centered show={showCreateBookingModal} onHide={handleCloseCreateBookingModal} dialogClassName="custom-modal-width">
                <Modal.Header>
                    <Modal.Title>Create Booking</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleCloseCreateBookingModal} />
                </Modal.Header>
                <Modal.Body>
                    <CreateBookingForm data={data} onSubmit={handleCreateBookingSubmit} onCancel={handleCloseCreateBookingModal} />
                </Modal.Body>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal as={Modal.Dialog} centered show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)} dialogClassName="custom-modal-width">
                <Modal.Header>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={() => setShowDeleteConfirmationModal(false)} />
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the selected users?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmationModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={confirmDeleteUsers}>Yes, Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
