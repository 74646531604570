import React, { useEffect, useState } from 'react';
import { Table, Card, OverlayTrigger, Tooltip, Button } from '@themesberg/react-bootstrap';
import axios from 'axios';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import ShortenAddress from '../../utility/ShortenAddress';
import FormatTz, { createdATFormat } from '../../utility/FormatTz';
import Avatar from '../../components/Widgets/Avatar';
import AvatarImage from '../../components/Widgets/AvatarImage';
import { Routes } from '../../routes';

export default ({ data }) => {
  const adminId = data.id;
  const [tours, setTours] = useState([]);
  const currentPage = 1
  const [isLoading, setIsLoading] = useState(true);
  const perPage = 5;
  const searchQuery = '';
  const history = useHistory();

  const fetchTours = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchPastTours?page=${currentPage}&pageSize=${perPage}&search=${searchQuery}`);
      const fetchedTours = response.data.data;
      setTours(fetchedTours);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, perPage, searchQuery]);

  useEffect(() => {
    fetchTours();
  }, [currentPage, perPage, searchQuery])

  const showTripDetails = (tourId) => {
    history.push(`/trips/past/${tourId}?adminId=${adminId}`);
  };

  const showAllTrips = () => {
      history.push(Routes.ToursHistory.path)
  };

  return (
    <div>
      <Card border="light" className="shadow">
        <Card.Header className='d-flex justify-content-between align-items-center'>
            <h5 className="mb-0 upperCase-keyword">Completed Trips</h5>
            <Button className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" size='sm' onClick={() => showAllTrips()}>
              See all
            </Button>
        </Card.Header>
        <Card.Body className="pb-5 table-wrapper table-responsive">
          <Table className="table table-centered table-nowrap mb-0 rounded align-items-center">
            <thead className='thead-light'>
              <tr>
              <th className="border-0 rounded-start">Guest Name</th>
                <th className="border-0">Drop Off</th>
                <th className="border-0">
                  Start Date & Time
                </th>
                <th className="border-0">
                  End Date & Time
                </th>
                <th className="border-0">
                  Created on
                </th>
                <th className="border-0">Driver</th>
                <th className="border-0 rounded-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                  </tr>
                ))
              ) : (
                tours.length === 0 ? (
                  <tr>
                    <td colSpan="9" className="text-center my-4 no-bottom-border">
                      No data found.
                    </td>
                  </tr>
                ) : (
                  tours.map((tour) => (
                    <tr key={tour.tourId}>
                      <td>
                      <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                        {Avatar(
                          tour.customerName.split(" ")[0], tour.customerName.split(" ")[1]
                        )}
                        <div className='px-2'>
                          {tour.customerName}
                        </div>
                      </div>
                    </td>
                      <td>
                        <span className="text-success">
                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.endLocation}</Tooltip>} >
                            <div className="truncate-text">
                              {ShortenAddress(tour.endLocation)}
                            </div>
                          </OverlayTrigger>
                        </span>
                      </td>
                      <td>{FormatTz(tour.tourDate)}, {moment(tour.tourTime, 'HH:mm:ss').format('hh:mm A')}</td>
                      <td>{createdATFormat(`${tour.completedAt}`)}</td>
                      <td>{createdATFormat(`${tour.createdAt}`)}</td>
                      <td>
                        <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                          {tour.driverImage ? AvatarImage(tour.driverImage) : Avatar(
                              tour.driverName.split(" ")[0], tour.driverName.split(" ")[1]
                          )}
                          <div className='px-2'>
                            {tour.driverName}
                          </div>
                        </div>
                      </td>
                      <td className='text-center'>
                        <Button className="dropdown-toggle animate-up-2 theme-bg-color-1 text-center action-btn border-0 bg-transparent" size='sm' onClick={() => showTripDetails(tour.tourId)}>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </Button>
                      </td>
                    </tr>
                  ))
                )
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};
