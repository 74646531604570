import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import ActiveToursList from "./examples/ActiveToursList";

export default (data) => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Active Trips</Breadcrumb.Item>
          </Breadcrumb>
          <h4 className="text-capitalize">Active Trips overview</h4>
          <p className="mb-0">This section shows all currently ongoing trips, with drivers actively assigned and the tours in progress.</p>
        </div>
      </div>
      <ActiveToursList data={data} />
    </>
  );
};