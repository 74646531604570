import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCalendarDay, faSuitcase, faUserTie } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import { Col, Row, Button, Form, Alert, InputGroup, Modal } from '@themesberg/react-bootstrap';
import axios from 'axios';
import ReactSelect from 'react-select';
import { Hourglass } from 'react-loader-spinner';

export default ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const [formData, setFormData] = useState({
    driverId: '',
    leaveType: '',
    leaveStartDate: '',
    leaveEndDate: '',
    adminId: data.id,
    leaveStatus: 'pending', // Default status is 'pending'
    createdBy: 'admin', // Default createdBy is 'driver'
    remarks: '',
  });

  const leaveTypesSelectData = [{
    value: 'sick',
    label: 'Sick'
  },
  {
    value: 'vacation',
    label: 'Vacation'
  },
  {
    value: 'planned',
    label: 'Planned'
  },
  {
    value: 'others',
    label: 'Others'
  }];
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectOptions, setSelectOptions] = useState([]); // Options for drivers
  const leaveTypeOptions = leaveTypesSelectData;
  const scrollRef = React.useRef(null);

  const scrollToMessageAfterSubmitForm = () => {
    window.scrollTo({
      behavior: "smooth",
      top: scrollRef.current
    });
  };

  useEffect(() => {
    const fetchDriverList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/manage/users?filterParam=approved&pageSizeNumber=-1`);
        const options = response.data.data.map(item => ({
          value: item.driverId,
          label: `${item.firstName} ${item.lastName}`,
        }));
        setSelectOptions(options);
      } catch (error) {
        handleRequestError('Error fetching drivers. Please try again later.');
      }
    };

    fetchDriverList();
  }, []);

  const validateForm = () => {
    let error = '';
    let isValid = true;

    if (!formData.leaveStartDate || !formData.leaveEndDate) {
      error = !formData.leaveStartDate ? 'Leave start date is required' : 'Leave end date is required';
      isValid = false;
    }

    if (formData.leaveStartDate && formData.leaveEndDate) {
      const startDate = moment(formData.leaveStartDate);
      const endDate = moment(formData.leaveEndDate);

      if (startDate.isAfter(endDate)) {
        error = 'Leave start date must be before the leave end date';
        isValid = false;
      }
    }

    if (formData.leaveType === '' || formData.leaveType === "Open this select menu") {
      error = 'Select leave type correctly';
      isValid = false;
    }

    if (formData.driverId === '') {
      error = 'Select driver';
      isValid = false;
    }

    setError(error);
    return isValid;
  };

  const handleClose = () => setShowDefault(false);

  const handleDriverChange = (selectedOption) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        driverId: selectedOption.value,
      });
    }
  };

  const handleLeaveTypeChange = (selectedOption) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        leaveType: selectedOption.value,
      });
    }
  };

  const handleStartDateChange = (date) => {
    setFormData({
      ...formData,
      leaveStartDate: moment(date).format('YYYY-MM-DD'),
    });
  };

  const handleEndDateChange = (date) => {
    setFormData({
      ...formData,
      leaveEndDate: moment(date).format('YYYY-MM-DD'),
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const isValid = validateForm();
    const formatStartDate = moment
        .utc(formData.leaveStartDate) // Convert the date to UTC
        .tz('Asia/Kuala_Lumpur') // Convert it to Malaysia time
        .format('YYYY-MM-DD HH:mm:ss'); // Final formatted datetime string
    
    const formatEndDate = moment
        .utc(formData.leaveEndDate) // Convert the date to UTC
        .tz('Asia/Kuala_Lumpur') // Convert it to Malaysia time
        .format('YYYY-MM-DD HH:mm:ss'); // Final formatted datetime string
    

    if (isValid) {
      clearMessages();
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/leaves`, {
          ...formData, formatStartDate, formatEndDate
        });


        await new Promise(resolve => setTimeout(resolve, 3000));
        setSuccessMessage('Leave request submitted successfully.');
        setLoading(false);
        scrollToMessageAfterSubmitForm();
        resetForm();
      } catch (error) {
        await new Promise(resolve => setTimeout(resolve, 3000)); // 3000ms = 3 seconds
        setLoading(false);
        scrollToMessageAfterSubmitForm();
        if (error.response) {
          handleRequestError(error.response.data.message);
        } else if (error.request) {
          handleRequestError(error.request);
        } else {
          handleRequestError(error.message);
        }
      }
    }
  };

  const handleRequestError = (errorMessage) => {
    setError(errorMessage);
  };

  const handleRemarksChange = (e) => {
    setFormData({
      ...formData,
      remarks: e.target.value,
    });
  };

  const clearMessages = () => {
    setError('');
    setSuccessMessage('');
  };

  const resetForm = () => {
    setFormData({
      driverId: '',
      leaveType: '',
      leaveStartDate: '',
      leaveEndDate: '',
      leaveStatus: 'pending',
      createdBy: 'admin',
      adminId: data.id,
      remarks: '',
    });
  };

  const valid = function (current) {
    return current.isAfter(moment().subtract(1, 'day'));
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px", // Smaller font size
      borderRadius: "0.2rem",
      borderColor: "#ddd",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#aaa",
      },
      color: "white"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#0080a8" // Selected option background
        : state.isFocused
        ? "#f1f1f1" // Focused option background
        : "white", // Default background
      color: state.isSelected ? "white" : "black", // Text color
      padding: 10,
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "14px", // Adjust font size in dropdown menu
    }),
  };

  const selectedDriver = selectOptions.find(option => option.value === formData.driverId);
  const selectedLeaveType = leaveTypeOptions.find(option => option.value === formData.leaveType);

  return (
    <>
      <Button variant="primary" size="sm" className='animate-up-2 mb-3 mx-2 shadow-3d' onClick={() => setShowDefault(true)} >
        <FontAwesomeIcon className="me-2" />Apply Leave
      </Button>
      <Modal border="light" className="shadow mb-6" centered show={showDefault} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Create Leave Plan</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
              <Hourglass
                visible={true}
                height="80"
                width="80"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#306cce', '#72a1ed']}
              />
              <p className="mt-2">Just a moment, your leave request is being confirmed.</p>
            </div>
          ) : <></>}
          <Form onSubmit={handleSubmit} className='form-input-pl-0'>
            {error && <Alert ref={scrollRef} variant="danger">{error}</Alert>}
            {successMessage && <Alert ref={scrollRef} variant="success">{successMessage}</Alert>}

            <Row>
              <Col xs={12} md={12} className="mb-3 required">
                <Form.Group controlId="driverId">
                  <Form.Label>Select Driver</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUserTie} />
                    </InputGroup.Text>
                    <ReactSelect options={selectOptions} styles={customStyles} theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#61DAFB',
                        primary: '#61DAFB',
                      }
                    })}
                      name="driverId"
                      value={selectedDriver}
                      onChange={handleDriverChange}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group controlId="leaveStartDate">
                  <Form.Label>Leave Start Date:</Form.Label>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleStartDateChange}
                    isValidDate={valid}
                    value={formData.leaveStartDate}
                    inputProps={{
                      name: 'leaveStartDate',
                      required: true,
                      placeholder: 'mm/dd/yyyy',
                    }}
                    renderInput={(props, openCalendar) => (
                      <InputGroup onClick={openCalendar}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarDay} />
                        </InputGroup.Text>
                        <input className="form-control" {...props} />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group controlId="leaveEndDate">
                  <Form.Label>Leave End Date:</Form.Label>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleEndDateChange}
                    isValidDate={valid}
                    value={formData.leaveEndDate}
                    inputProps={{
                      name: 'leaveEndDate',
                      required: true,
                      placeholder: 'mm/dd/yyyy',
                    }}
                    renderInput={(props, openCalendar) => (
                      <InputGroup onClick={openCalendar}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <input className="form-control" {...props} />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6} className="mb-3 required">
                <Form.Group controlId="leaveType">
                  <Form.Label>Leave Type:</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSuitcase} />
                    </InputGroup.Text>
                    <ReactSelect options={leaveTypeOptions} styles={customStyles} theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#61DAFB',
                        primary: '#61DAFB',
                      }
                    })}
                      name="leaveType"
                      value={selectedLeaveType}
                      onChange={handleLeaveTypeChange}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  </InputGroup>

                </Form.Group>
              </Col>
            </Row>
            {formData.leaveType === 'others' && (
              <Row>
                <Col xs={12} className="mb-3">
                  <Form.Group controlId="remarks">
                    <Form.Label>Leave Reason:</Form.Label>
                    <textarea
                      className="form-control"
                      rows="4"
                      value={formData.remarks}
                      onChange={handleRemarksChange}
                      required={formData.leaveType === 'others'}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              <Col xs={12} className="mb-1">
                <Button variant="primary" size="sm" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>

        </Modal.Body>
      </Modal>
    </>
  );
};
