import { Image } from '@themesberg/react-bootstrap';
import React from 'react';

const AvatarImage = (image = '') => {
    
    return (
        <div className="avatar avatar-image">
            <Image src={image} />
        </div>
    );
};

export default AvatarImage;