import { Table, Card, Button } from '@themesberg/react-bootstrap';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Avatar from '../../components/Widgets/Avatar';
import { useHistory } from 'react-router-dom';
import FormatTz from '../../utility/FormatTz';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faLocationArrow, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import ShortenAddress from '../../utility/ShortenAddress';
import OvalLoaderSecondary from '../../components/SubComponents/Loader/OvalLoaderSecondary';
import AvatarImage from '../../components/Widgets/AvatarImage';
import { Routes } from "../../routes";

// React component for displaying upcoming leaves
export default () => {
    const [tours, setTours] = useState([]);
    const [loading, setLoading] = useState(true);
    const currentPage = 1;
    const perPage = 5;
    const searchQuery = ''
    const history = useHistory();

    const fetchTours = React.useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/activeTours?pageNumber=${currentPage}&pageSize=${perPage}&search=${searchQuery}`);
            setTours(response.data.data);
            setTimeout(() => {
                setLoading(false);
            }, 1500);
        } catch (error) {
            console.error('Error fetching tours:', error);
        }
    }, [currentPage, perPage, searchQuery]);

    useEffect(() => {
        fetchTours();
    }, [fetchTours]);

    const showTripDetails = (tourId) => {
        history.push(`/tripdetails/${tourId}`);
    };

    const showAllTrips = () => {
        history.push(Routes.ActiveTours.path)
    };

    return (
        (tours.length !== 0) ? (
            <div className='wrapper mb-5'>
                <div className="ripple-container">
                    <div className="center-circle"></div>
                    <div className="ripple ripple-1"></div>
                    <div className="ripple ripple-2"></div>
                    <div className="ripple ripple-3"></div>
                </div>
                <Card border="light" className="shadow">
                    <Card.Header className='d-flex justify-content-between align-items-center'>
                        <h5 className="mb-0 upperCase-keyword">OnGoing Trips</h5>
                        <Button className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" size='sm' onClick={() => showAllTrips()}>
                            See all
                        </Button>
                    </Card.Header>
                    { loading 
                    ? <OvalLoaderSecondary /> 
                    : <Card.Body className="pb-0 table-wrapper table-responsive">
                            <Table className="table table-centered table-nowrap mb-0 rounded align-items-center">
                            <thead className='thead-light'>
                                <tr>
                                    <th className="border-0 rounded-start">Guest Name</th>
                                    <th className="border-0">Route</th>
                                    <th className="border-0">Start Date & Time</th>
                                    <th className="border-0">Assigned Driver</th>
                                    <th className="border-0 rounded-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    Array.from({ length: 5 }).map((_, index) => (
                                        <tr key={index}>
                                            <td className='vertical-line-m'><Skeleton /></td>
                                            <td className='vertical-line-m'><Skeleton /></td>
                                            <td className='vertical-line-m'><Skeleton /></td>
                                            <td className='vertical-line-m'><Skeleton /></td>
                                            <td className='vertical-line-m'><Skeleton /></td>
                                        </tr>
                                    ))
                                ) : tours.length === 0 ? (
                                    <tr>
                                        <td colSpan="10" className="text-center my-4 no-bottom-border">
                                            No data found.
                                        </td>
                                    </tr>
                                ) : (
                                    tours.map((tour) => (
                                        <tr key={tour.tourId}>
                                            <td className='vertical-line-m'>
                                                <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                                                    {Avatar(
                                                        tour.customerFullName.split(" ")[0], tour.customerFullName.split(" ")[1]
                                                    )}
                                                    <div className='px-2'>
                                                        {tour.customerFullName}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='vertical-line-m'>
                                                <div className="location-wrapper">
                                                    <div className="d-flex justify-content-start align-items-center mt-2">
                                                        <div>
                                                            <span className="circle-svg">
                                                                <FontAwesomeIcon icon={faLocationArrow} className="progress-label text-secondary mt-1" />
                                                            </span>
                                                        </div>
                                                        <div className="px-2">
                                                            <p className="f-14 mb-0">
                                                                {loading ? <Skeleton width={100} /> : (<>
                                                                    {ShortenAddress(tour.startLocation)}
                                                                </>)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-start align-items-center mt-3">
                                                        <span className="circle-svg">
                                                            <FontAwesomeIcon icon={faMapMarkedAlt} className="progress-label text-danger mt-1" />
                                                        </span>
                                                        <div className="px-2">
                                                            <p className="f-14 mb-0">
                                                                {loading ? <Skeleton width={100} /> : (<>
                                                                    {ShortenAddress(tour.endLocation)}
                                                                </>)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='vertical-line-m'>{FormatTz(tour.tourDate)}, {moment(tour.tourTime, 'HH:mm:ss').format('hh:mm A')}</td>
                                            <td className='vertical-line-m'>
                                                <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                                                    {tour.driverImage ? AvatarImage(tour.driverImage) : Avatar(
                                                          tour.driverFirstName, tour.driverLastName
                                                    )}
                                                    <div className='px-2'>
                                                        {tour.driverFirstName} {tour.driverLastName}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='text-center vertical-line-m'>
                                                <Button className="dropdown-toggle animate-up-2 theme-bg-color-1 text-center action-btn border-0 bg-transparent" size='sm' onClick={() => showTripDetails(tour.tourId)}>
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </Card.Body>
                    }
                    <Card.Footer></Card.Footer>
                </Card>
            </div>
        ) : null
    )    
}    