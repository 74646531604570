import { Form } from "@themesberg/react-bootstrap";
import React from "react";
import ReactSelect from "react-select";

export default ({ paymentStatus, handleSelectPayment }) => {
  const paymentOptions = [
    { value: "prepaid", label: "Prepaid" },
    { value: "postpaid", label: "Postpaid" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px", // Smaller font size
      borderRadius: "0.2rem",
      borderColor: "#ddd",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#aaa",
      },
      color: "white"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#0080a8" // Selected option background
        : state.isFocused
        ? "#f1f1f1" // Focused option background
        : "white", // Default background
      color: state.isSelected ? "white" : "black", // Text color
      padding: 10,
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "14px", // Adjust font size in dropdown menu
    }),
  };

  const handleChange = (selectedOption) => {
    handleSelectPayment({
      target: { value: selectedOption.value },
    });
  };

  return (
    <>
      <Form.Label>Payment Status</Form.Label>
      <ReactSelect
        value={paymentOptions.find((option) => option.value === paymentStatus)}
        onChange={handleChange}
        options={paymentOptions}
        styles={customStyles}
        placeholder="Open this select menu"
        isSearchable={false} // Disable search box if unnecessary
      />
    </>
  );
};