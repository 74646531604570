import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ListCreation from '../../../utility/ListCreation';

export default ({ loading, data }) => (
    <div className='guest-details-wrapper'>
        <h6 className='mb-2'>Itinerary details</h6>
        {loading ? <Skeleton width={150} /> : (
            <>
                <div className="d-flex justify-content-start align-items-center mb-3">
                    <div className='f-14 mb-0'>
                        <ListCreation itinerary={data.itinerary} />
                    </div>
                </div>
            </>
        )}
    </div>
);