import React from 'react';
import moment from 'moment';

// Validate the expiry date to check if it is expiring soon (within 3 months)
const validateExpiryDates = (expiryDate) => {
    if (!expiryDate) return false;  // Early return if expiryDate is not provided

    const currentDate = moment();  // Get current date
    const expiryMoment = moment(expiryDate);  // Convert to moment object

    // Calculate the difference in months between current date and expiry date
    const diffMonths = expiryMoment.diff(currentDate, 'months');

    return diffMonths >= 3;  // Return true if it's not expiring soon (>= 3 months away)
}

// Helper function to display the expiry date information with validation
const getNumberWithDates = (a, b) => {
    if (a === null || a === undefined) {
        return <>--</>;  // Return "--" if 'a' is null or undefined
    }

    const isValid = validateExpiryDates(b);  // Check if expiry date is valid

    return (
        <>
            <span className='d-block mb-1'>{a}</span>
            {!isValid && (
                <small className='upperCase-keyword text-danger'>
                    Expiring soon
                </small>
            )}
        </>
    );
}

export { validateExpiryDates, getNumberWithDates };
