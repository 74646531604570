import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from 'moment-timezone';
import { ProfileCardWidget } from './Widgets';

const ProfilePage = ({ userData }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchProfile`, {id: userData.data.id, email: userData.data.email, userRole: userData.data.role});
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (userData && userData.data) {
      fetchUserData();
    }
  }, [userData]); // Adding `userData` as a dependency to avoid multiple calls

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Row className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Profile</Breadcrumb.Item>
          </Breadcrumb>
          <h4>My Profile</h4>
          <p className="mb-0">Your important information.</p>
        </div>
      </Row>

      <Row className="justify-content-center">
        <Col xs={12} className="mt-1">
          <Card border="light" className="bg-white shadow mb-4">
            <Card.Body>
              <h5 className="mb-4">General information</h5>
              <Row>
                <ProfileCardWidget data={user} />
                <Col md={6} className="mb-3">
                  <div id="firstName">
                    <p className='mb-2 text-capitalize'><strong>First Name</strong></p>
                    <p className="text-muted mb-2  text-capitalize">{user.firstName}</p>
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div id="lastName">
                    <p className='mb-2  text-capitalize'><strong>Last Name</strong></p>
                    <p className="text-muted mb-2  text-capitalize">{user.lastName}</p>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6} className="mb-3">
                  <div id="birthday">
                    <p className='mb-2'><strong>Birthday</strong></p>
                    <p className="text-muted mb-2">{moment(user.birthDate).format('YYYY-MM-DD')}</p>
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div id="dateOfJoining">
                    <p className='mb-2'><strong>Date of Joining</strong></p>
                    <p className="text-muted mb-2">{moment(user.joiningDate).format('YYYY-MM-DD')}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <div id="email">
                    <p className='mb-2'><strong>Email</strong></p>
                    <p className="text-muted mb-2">{user.email}</p>
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div id="phone">
                    <p className='mb-2'><strong>Phone</strong></p>
                    <p className="text-muted mb-2">{user.contactNumber}</p>
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div id="phone">
                    <p className='mb-2'><strong>Address</strong></p>
                    <p className="text-muted mb-2">{user.address}</p>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ProfilePage;
