import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button, Card, Modal, Pagination, Form, InputGroup, Alert, Image, Badge } from '@themesberg/react-bootstrap';
import { faSearch, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import ReactSelect from 'react-select';
import axios from 'axios';
import { Hourglass } from 'react-loader-spinner';
import moment from 'moment-timezone';
import Avatar from '../../components/Widgets/Avatar';



export default ({ data }) => {
    const [showPayoutReviewModal, setShowPayoutReviewModal] = useState(false);
    const [currentMonthPayout, setCurrentMonthPayout] = useState({
        totalTrips: 0,
        totalRewards: 0,
        totalEarnings: 0,
        totalDeductions: 0,
        netPayout: 0
    });
    const [payouts, setPayouts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const [formData, setFormData] = useState({
        selectedDriver: null,
    });
    const [currentPeriod, setCurrentMonthPeriod] = useState();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const perPage = 10;
    const scrollRef = React.useRef(null);
    const scrollToMessageAfterSubmitForm = () => {
        window.scrollTo({
          behavior: "smooth",
          top: scrollRef.current
        });
      };
    

    // Custom styles for React Select
    const customStyles = {
        control: (provided) => ({
            ...provided,
            fontSize: '14px',
            borderRadius: '0.2rem',
            borderColor: '#ddd',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#aaa',
            },
            color: 'white',
            textTransform: 'capitalize', // Make option text uppercase
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#0080a8' : state.isFocused ? '#f1f1f1' : 'white',
            color: state.isSelected ? 'white' : 'black',
            padding: 10,
            cursor: 'pointer',
            textTransform: 'capitalize', // Make option text uppercase

        }),
        menu: (provided) => ({
            ...provided,
            fontSize: '14px',
        }),
    };

    // Fetch driver list
    const fetchDriverList = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/manage/users?filterParam=approved&pageSizeNumber=-1`
            );
            const options = response.data.data.map((item) => ({
                value: item.driverId,
                label: `${item.firstName} ${item.lastName}`,
            }));
            setSelectOptions(options);
        } catch (error) {
            console.error('Error fetching driver list:', error);
        }
    };

    // Fetch payout details for the selected driver
    const fetchPayoutDetails = async (driverId) => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/${driverId}/payout`
            );
            const payoutData = response.data;
            // Update currentMonthPayout with fetched data
            setCurrentMonthPayout({
                totalTrips: payoutData.completedToursThisMonth,
                totalRewards: payoutData.totalRewards,
                totalEarnings: payoutData.totalCredits,
                totalDeductions: payoutData.totalDeductions,
                netPayout: payoutData.totalPayouts                
            });
        } catch (error) {
            console.error('Error fetching payout details:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch all payouts to display in the table
    const fetchAllPayouts = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/payout`
            );
            setPayouts(response.data.data); // Set all payout data for display
            setTotalRecords(response.data.totalRecords);
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.currentPage);
        } catch (error) {
            console.error('Error fetching payouts:', error);
        }
    };

    const handleRequestError = (errorMessage) => {
        setError(errorMessage);
    };
    
    const clearMessages = () => {
        setError('');
        setSuccessMessage('');
    };

    // Handle the "Pay" button click to update payout status
    const payPayout = async () => {
        try {
            clearMessages();
            if (!formData.selectedDriver) {
                handleRequestError("Please select a driver.");
                return;
            }

            setLoading(true);
            const netPayout = (parseFloat(currentMonthPayout.totalEarnings, 10) + parseFloat(currentMonthPayout.totalRewards, 10)) - parseFloat(currentMonthPayout.totalDeductions, 10);
            const payoutData = {
                driverId: formData.selectedDriver.value,
                adminId: data.data.id,
                totalTrips: currentMonthPayout.totalTrips,
                totalRewards: currentMonthPayout.totalRewards,
                totalEarnings: currentMonthPayout.totalEarnings,
                totalDeductions: currentMonthPayout.totalDeductions,
                netPayout: netPayout
            };

            await axios.post(
                `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/payout`,
                payoutData
            );
            await new Promise(resolve => setTimeout(resolve, 3000));
            setSuccessMessage('Payout successfully saved.');
            setLoading(false);
            scrollToMessageAfterSubmitForm();
            fetchAllPayouts();
            setLoading(false);
        } catch (error) {
            await new Promise(resolve => setTimeout(resolve, 3000));
            setLoading(false);
            scrollToMessageAfterSubmitForm();
            if (error.response && error.response.data && error.response.data.error) {
                handleRequestError(error.response.data.error);
            } else {
                handleRequestError('An unexpected error occurred. Please try again.');
            }      
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const currentPeriod = moment().tz('Asia/Kuala_Lumpur').format('MMMM YYYY'); // Get the current month and year in MYT
        setCurrentMonthPeriod(currentPeriod);  
        fetchDriverList();
        fetchAllPayouts(); // Fetch all payouts when the component mounts
    }, []);

    const handleDriverChange = (selectedOption) => {
        setFormData((prev) => ({
            ...prev,
            selectedDriver: selectedOption,
        }));

        // Fetch payout details for the selected driver
        if (selectedOption && selectedOption.value) {
            fetchPayoutDetails(selectedOption.value);
        }
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const startEntry = (currentPage - 1) * perPage + 1;
    const endEntry = Math.min(currentPage * perPage, totalRecords);

    return (
        <div>
            <Card border="light" className="table-wrapper table-responsive shadow">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center justify-content-between">
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Search by name..."
                            />
                        </InputGroup>
                    </div>
                    <Button variant="primary" size='sm' onClick={() => setShowPayoutReviewModal(true)}>
                        New Payout
                    </Button>
                </Card.Header>

                {loading ? (
                    <div className="preloader flex-column justify-content-center align-items-center bg-transparent-white">
                        <Hourglass
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="hourglass-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            colors={['#306cce', '#72a1ed']}
                        />
                        <p className="mt-2">Just a moment, your request is being processed.</p>
                    </div>
                ) : <></>}
                <Card.Body className='pb-0'>
                    <Table responsive className="table table-centered rounded align-items-center">
                        <thead className='thead-light'>
                            <tr>
                                <th className="border-bottom border-0 rounded-start">Name</th>
                                <th className="border-bottom border-0">Payout Period</th>
                                <th className="border-bottom border-0">Trips Completed</th>
                                <th className="border-bottom border-0">Earnings (RM)</th>
                                <th className="border-bottom border-0">Ratings (RM)</th>
                                <th className="border-bottom border-0">Deductions (RM)</th>
                                <th className="border-bottom border-0">Net Payouts (RM)</th>
                                <th className="border-bottom border-0 rounded-end">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        {payouts.length === 0 ? (
                            <tr>
                            <td colSpan="9" className="text-center my-4 no-bottom-border">
                                No data found.
                            </td>
                            </tr>
                        ) : (
                            payouts.map((payout) => (
                                <tr key={payout.driverId}>
                                    <td>
                                        <div className='fw-bold d-flex justify-content-start align-items-center'>
                                            {payout.image === undefined || payout.image === null ?
                                                <>{Avatar(payout.firstName, payout.lastName)}</>
                                                :
                                                <div className='avatar'>
                                                    <Image src={payout.image} />
                                                </div>
                                                
                                            }
                                            <div className='px-2'>
                                                <span className='d-block text-capitalize'>
                                                    {payout.firstName} {payout.lastName}
                                                </span>
                                                <small className='small text-gray'>
                                                    {payout.email}
                                                </small>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className='d-block text-capitalize text-danger fw-bold'>
                                            {moment(payout.payoutDate).tz('Asia/Kuala_Lumpur').format('MMMM YYYY')}

                                        </span>
                                    </td>
                                    <td className='text-center'>{payout.totalTrips}</td>
                                    <td className='text-center'>{payout.totalEarnings}</td>
                                    <td className='text-center'>{payout.totalRewards}</td>
                                    <td className='text-center'>{payout.totalDeductions}</td>
                                    <td className='text-center'>{payout.netPayout}</td>
                                    <td className='text-center'>
                                        <Badge className="f-12 upperCase-keyword badge-lg badge bg-tertiary">
                                            {payout.payoutStatus}
                                        </Badge>
                                    </td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </Table>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between align-items-center">
                            <small className="fw-bold">
                                Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
                            </small>
                            {payouts.length !== 0 && <Pagination className="justify-content-center">
                                  <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                                  {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
                                    <Pagination.Item
                                      key={index + 1}
                                      active={index + 1 === currentPage}
                                      onClick={() => paginate(index + 1)}
                                    >
                                      {index + 1}
                                    </Pagination.Item>
                                  ))}
                                  <Pagination.Next
                                    onClick={() => paginate(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                  />
                                </Pagination> }
                </Card.Footer>
            </Card>

            {/* Review Payout Modal */}
            <Modal centered show={showPayoutReviewModal} onHide={() => setShowPayoutReviewModal(false)}>
                <Modal.Header>
                    <Modal.Title>Review payout</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={() => setShowPayoutReviewModal(false)} />
                </Modal.Header>
                <Modal.Body>
                    <div className='py-2'>
                        {error && <Alert className="mb-0" ref={scrollRef} variant="danger">{error}</Alert>}
                        {successMessage && <Alert  className="mb-0" ref={scrollRef} variant="success">{successMessage}</Alert>}
                    </div>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Payout Period</Form.Label>
                            <Form.Control type="text" value={currentPeriod} readOnly />
                        </Form.Group>
                        <Form.Group controlId="assignedDriver" className='mb-3'>
                            <Form.Label>Driver</Form.Label>
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faUserAlt} />
                                </InputGroup.Text>
                                <ReactSelect
                                    options={selectOptions}
                                    styles={customStyles}
                                    value={formData.selectedDriver}
                                    onChange={handleDriverChange}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Total Trips</Form.Label>
                            <Form.Control type="text" value={currentMonthPayout.totalTrips} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Total Earnings</Form.Label>
                            <Form.Control type="text" value={currentMonthPayout.totalEarnings} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Total Rewards</Form.Label>
                            <Form.Control type="text" value={currentMonthPayout.totalRewards} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Total Deductions</Form.Label>
                            <Form.Control type="text" value={currentMonthPayout.totalDeductions} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Net Payout</Form.Label>
                            <Form.Control type="text" value={currentMonthPayout.netPayout} readOnly />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" size='sm' onClick={payPayout}>
                        Pay
                    </Button>
                    <Button variant="secondary" size='sm' onClick={() => setShowPayoutReviewModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
