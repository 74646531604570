import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Card } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../routes';

export default () => {
    const history = useHistory();
    const [tours, setTours] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state for fetching tours
    const fetchTours = React.useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchTours`); setTours(response.data.data);
            setTimeout(() => {
                setLoading(false);
            }, 500);
        } catch (error) {
        }
    }, []);

    useEffect(() => {
        fetchTours();
    }, [fetchTours]);

    const showTripDetails = (tourId) => {
        history.push(`/tripdetails/${tourId}`);
    };

    
    const showAllTrips = () => {
        history.push(Routes.UpcomingTours.path)
    };
    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow mb-5 height-800">
                <Card.Header className='d-flex justify-content-between align-items-center'>
                    <h5 className="mb-0 upperCase-keyword">upcoming trips</h5>
                    <Button className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" size='sm' onClick={() => showAllTrips()}>
                        See all
                    </Button>
                </Card.Header>
                <Card.Body className="pb-0">


                    {tours.map((tour, index) => (
                        <div key={index} border="light" className="row align-items-center d-block d-sm-flex border-bottom pb-4 mb-4">
                            <Row className="d-block row d-lg-flex row align-items-center">
                                <Col className="mb-3 mb-sm-0 col-auto">
                                    {loading ? (
                                        <div className="calendar d-flex">
                                            <Skeleton height={60} />
                                        </div>
                                    ) : (
                                        <div className="calendar d-flex">
                                            <span className="calendar-month">{moment.utc(tour.tourCreatedAt).tz('Asia/Kuala_Lumpur').format('MMM')}</span>
                                            <span className="calendar-day py-2">{moment.utc(tour.tourCreatedAt).tz('Asia/Kuala_Lumpur').format('DD')}</span>
                                        </div>
                                    )}
                                </Col>
                                <Col className="align-items-center d-block justify-content-between d-xl-flex">
                                    {loading ? (
                                        <>
                                            <Skeleton height={30} width={200} />
                                            <Skeleton count={2} />
                                            <Skeleton width={100} />
                                        </>
                                    ) : (
                                        <div className="card-body-content">
                                            <Card.Title className="title-header mb-2">{tour.tourListTourName}</Card.Title>
                                            <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center f-12'>
                                                        Guest Name: {tour.customerFullName}
                                            </div>
                                            <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center f-12'>
                                                {tour.driverId ?
                                                    <>
                                                        Driver Name: {tour.driverFirstName} {tour.driverLastName}
                                                    </> : "No driver assigned"
                                                }
                                            </div>
                                            <Card.Text className='f-12'>
                                                <span className="small fw-bold f-12">
                                                    Created By <span className='text-capitalize'>{`${tour.adminFirstName} ${tour.adminLastName}`}</span> on {moment(tour.tourCreatedAt).format('Do MMMM, YYYY')}
                                                </span>
                                            </Card.Text>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                        {loading ? (
                                            <Skeleton height={38} />
                                        ) : (
                                            <div className="action-items">
                                                <Button className="dropdown-toggle animate-up-2 theme-bg-color-1 text-center action-btn border-0 bg-transparent" size='sm' onClick={() => showTripDetails(tour.tourId)} >
                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Card.Body>
            </Card>
        </>
    );
};