import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Breadcrumb, Card, Pagination } from '@themesberg/react-bootstrap';
import moment from 'moment-timezone';
import axios from 'axios';

export default () => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 5; // Number of notifications per page

  const fetchNotifications = useCallback(async (page = 1) => {
      if (page > totalPages ) return; // Prevent multiple concurrent requests

      setIsLoading(true);
      try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/notifications/admins`, {
              params: { limit: perPage, offset: (page - 1) * perPage }
          });
          setNotifications(prevNotifications => [
              ...prevNotifications, 
              ...response.data.data
          ]);
          setTotalPages(response.data.totalPages);
          setCurrentPage(page);
      } catch (err) {
      } finally {
          setIsLoading(false);
      }
  }, [perPage, totalPages]);

  useEffect(() => {
      fetchNotifications(1);
  }, [fetchNotifications]);

  const formatCreatedAt = (createdAt) => {
      const now = moment();
      const created = moment(createdAt);
      const minutesAgo = now.diff(created, 'minutes');
      const hoursAgo = now.diff(created, 'hours');
      const daysAgo = now.diff(created, 'days');

      if (minutesAgo < 1) {
          return 'Just now';
      } else if (minutesAgo < 60) {
          return `${minutesAgo} min${minutesAgo > 1 ? 's' : ''} ago`;
      } else if (hoursAgo < 24) {
          return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
      } else if (daysAgo === 1) {
          return 'Yesterday';
      } else {
          return created.format('D MMM, h:mm A');
      }
  };
  
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Notifications</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Notifications</h4>
          <p className="mb-0">You can view all notifications.</p>
        </div>
      </div>
      <Row>
        <Col xs={12} md={12}>
        <Card>
          <Card.Body className='p-0'>
          {notifications.map((notification, index) => (
            <>
              <div class="hover-state rounded-0 py-3 border-bottom">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center justify-content-end px-4 order-lg-4 col-lg-2 col-2">
                    <div class="text-danger small d-none d-lg-block">
                      {formatCreatedAt(notification.createdAt)}
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3 mt-lg-0 ps-0 col-lg-7 col-12">
                    <span class="fw-bold ps-lg-3">{notification.message}</span>
                  </div>
                </div>
              </div>
            </>
          ))}
          {!isLoading && (
            <Pagination className="justify-content-center mt-3">
              <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
              {Array.from({ length: Math.ceil(notifications.length / perPage) }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(notifications.length / perPage)}
              />
            </Pagination>
          )}
          </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

