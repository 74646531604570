import React, { useEffect, useState } from "react";
import axios from 'axios';
import { CounterWidget } from "../../Widgets";
import { faCheckCircle, faClipboardList, faClock, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "@themesberg/react-bootstrap";
import Skeleton from "react-loading-skeleton";

export default () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchVehicleData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/dashboard/trips/today`);
                const { data } = response;
                // Extract values from the provided object structure
                setData(data);
            } catch (error) {
            } finally {
                setLoading(false); // End loading
            }
        };
        fetchVehicleData();
    }, []);

    return (
        <>
            {loading ?
                <Row>
                    <Col xs={12} sm={6} xl={3}>
                        <Skeleton width={'100%'} height={100}/>
                    </Col>
                    <Col xs={12} sm={6} xl={3}>
                        <Skeleton width={'100%'} height={100}/>
                    </Col>
                    <Col xs={12} sm={6} xl={3}>
                        <Skeleton width={'100%'} height={100}/>
                    </Col>
                    <Col xs={12} sm={6} xl={3}>
                        <Skeleton width={'100%'} height={100}/>
                    </Col>
                </Row>
                :
                <Row>
                <Col xs={12} sm={6} xl={3}>
                    <CounterWidget
                        category="Bookings"
                        title={data.bookings}
                        period="Feb 1 - Apr 1"
                        percentage={18.2}
                        icon={faClipboardList} // Icon for general listings/bookings
                        iconColor="shape-primary" // Professional blue for general stats
                        subtitle=""
                    />
                </Col>
                <Col xs={12} sm={6} xl={3}>
                    <CounterWidget
                        category="Completed Trips"
                        title={data.completed}
                        period="Feb 1 - Apr 1"
                        percentage={18.2}
                        icon={faCheckCircle} // Check icon remains relevant for completed tasks
                        iconColor="shape-success" // Green for success
                        subtitle=""
                    />
                </Col>
                <Col xs={12} sm={6} xl={3}>
                    <CounterWidget
                        category="Scheduled Trips"
                        title={data.scheduled}
                        period="Feb 1 - Apr 1"
                        percentage={18.2}
                        icon={faClock} // Clock icon for scheduled trips
                        iconColor="shape-info" // Light blue for informational stats
                        subtitle=""
                    />
                </Col>
                <Col xs={12} sm={6} xl={3}>
                    <CounterWidget
                        category="Cancelled Trips"
                        title={data.cancelled}
                        period="Feb 1 - Apr 1"
                        percentage={18.2}
                        icon={faTimesCircle} // X-circle icon for cancellations
                        iconColor="shape-danger" // Red for alerts/errors
                        subtitle=""
                    />
                </Col>
            </Row>            
            }
        </>)

}