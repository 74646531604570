export default (driverId, message) => {
    const url = `wss://j6pviturf3.execute-api.ap-southeast-1.amazonaws.com/dev?driverId=${driverId}`;
    const socket = new WebSocket(url);
    socket.onopen = () => {
        socket.send(JSON.stringify({ action: 'sendNewTripPrompt', message: JSON.stringify({driverId, "message": message}) }));
    };
    return () => {
        if (socket) {
            socket.close();
        }
    };
};