// routes/Routes.js
export const Routes = {
    DashboardOverview: { path: "/" },
    NewBookings: { path: "/tours/new-bookings" },
    UpcomingTours: { path: "/tours/upcoming-tours" },
    ActiveTours: { path: "/tours/active-tours" },
    ToursHistory: { path: "/tours/tours-history" },
    CancelledToursHistory: { path: "/tours/cancelled-tours" },
    Customers: { path: "/customers" },
    Profile: { path: "/profile" },
    CreateBooking: { path: "/create-booking" },
    CreateTour: { path: "/create-tour" },
    GetAllDrivers: { path: "/drivers" },
    DriverDetails: { path: "/driversDetails" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    NotFound: { path: "/404" },
    Reports: { path: "/reports" },
    CreateDriver: { path: "/create-driver" },
    GetTripDetails: {path: "/tripdetails/:tourId"},
    GetPastTripDetails: {path: "/trips/past/:tourId"},
    GetDriverDetails: {path: "/drivers/:driverId"},
    AdminConfirmation: {path: "/confirmation"},
    AdminManagement: {path: "/manage/admins"},
    DriverManagement: {path: "/manage/drivers"},
    LeavesManagement: {path: "/manage/leaves"},
    DriverComplaintManagement: {path: "/manage/complaint"},
    DriverComplaintDetails: {path: "/complaint/:violationId"},
    ExpensesManagement: {path: "/expenses"},
    ReviewPendingExpenses: {path: "/expenses/approval"},
    ReviewCompletedExpenses: {path: "/expenses/reviewed"},
    VehiclesManagement: {path: "/vehicles"},
    VehiclesDetails: {path: "/vehicles/:vehicleId"},
    Notifications: {path: "/notifications"},
    Payouts: {path: "/payouts"}
};
