import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Card, Image } from "@themesberg/react-bootstrap";
import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
export default () => {
    const { violationId } = useParams();
    const [data, setData] = useState(null);

    const fetchUsers = React.useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/driver/violations/${violationId}`);
            setData(response.data);
        } catch (error) {
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);


    const history = useHistory();
    return (
        <>
            <div className="my-4">
                <p onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faArrowLeft} size="md me-2" /> <h5 className="d-inline-block">Back</h5>
                </p>
            </div>
            {data !== null && <Card className="mb-5">
                <Card.Body>
                     <div className="details">
                        <h4 className="mb-4">{data.summary}</h4>
                        <h6>Description</h6>
                        <p>{data.description}</p>
                        <h6 className="mt-5">Attachments</h6>
                            {data.attachments.length === 0 
                                ? <p>No Attachments</p>
                                : <div>
                                    {data.attachments.map((url, index) => (
                                <div key={index} className="image-container" style={{ margin: '10px' }}>
                                    <Image
                                        src={url}
                                        alt={`Image ${index + 1}`}
                                        style={{
                                            width: '100px',   // Fixed width
                                            height: '100px',  // Fixed height
                                            objectFit: 'cover', // Ensures the image covers the square box
                                            borderRadius: '4px', // Optional: for rounded corners
                                        }}
                                        
                                    />
                                </div>
                            ))}
                            </div>
}
                        <h6 className="mt-5">Details</h6>
                        <Card>
                            <Card.Body className="p-3">
                                <div className="d-flex">
                                    <p className="w-40 f-16">Category</p>
                                    <p className="flex-grow-1 f-16">{data.category}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="w-40 f-16">Sub Category</p>
                                    <p className="flex-grow-1 f-16">{data.subCategory}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="w-40 f-16">Driver</p>
                                    <p className="f-16 flex-grow-1 text-capitalize">{data.driverName}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="w-40 f-16">Reporter</p>
                                    <p className="f-16 flex-grow-1 text-capitalize">{data.adminName}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="w-40 f-16">Priority</p>
                                    <p className="f-16 flex-grow-1">
                                    <Badge className={`f-12 upperCase-keyword badge-lg badge bg-${data.priority === "high" ? "danger" : data.priority === "medium" ? "warning" : data.priority === "low" ? "secondary" : "danger"}`}>
                                            {data.priority}
                                                </Badge>
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <p className="w-40 f-16">Created on</p>
                                    <p className="f-16 flex-grow-1">{moment(data.createdAt).format('D MMM YYYY')}</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </Card.Body>
            </Card>
}
        </>
    )
}