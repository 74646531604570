import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Breadcrumb, Modal, Button, Dropdown } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import SendNewTripPrompt from '../../utility/SendNewTripPrompt';
import CustomModal from './CustomModal/CustomModal';
import TripHeader from './TripComponents/TripHeader';
import TripLocation from './TripComponents/TripLocation';
import TripPax from './TripComponents/TripPax';
import TripTimeline from './TripComponents/TripTimeline';
import VehicleModal from './CustomModal/VehicleModal';
import TripDriverModal from './CustomModal/TripDriverModal';
import TripVehicle from './TripComponents/TripVehicle';
import TripInclusions from './TripComponents/TripInclusions';
import TripItinerary from './TripComponents/TripItinerary';
import TripDriverDetails from './TripComponents/TripDriverDetails';
import toast, { Toaster } from 'react-hot-toast';
import TripDriverAssignment from './TripComponents/TripDriverAssignment';
import Avatar from '../Widgets/Avatar';
import AvatarImage from '../Widgets/AvatarImage';

export default () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [selectedTour, setSelectedTour] = useState(null);
    const [driverData, setDriverData] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState('');
    const [assignedDriverDetails, setAssignedDriverDetails] = useState([]);
    const [showDriverContent, setShowDriverContent] = useState(false);
    const [requiredRefresh, setRefreshRequired] = useState(false);
    const [enablePreloader, setEnablePreloader] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [vehicleSearchQuery, setVehicleSearchQuery] = useState('');
    const { tourId } = useParams();
    const [selectedVehicle, setSelectedVehicle] = useState();
    const [vehicleData, setVehicleData] = useState({
        showVehicleModal: false,
        vehicles: []
    });
    

    // Fetch trip data
    const fetchTripData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tripDetailsBrief?tourId=${tourId}`);
            setData(response.data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }, [tourId]);

    useEffect(() => {
        fetchTripData();
    }, [fetchTripData, requiredRefresh]);

    // Fetch drivers when assigning
    const fetchDrivers = useCallback(async () => {
        if (!showAssignModal) return;
        setEnablePreloader(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/fetchDriversAvailable?search=${searchQuery}&tourStartDate=${data.tourDate.split(" ")[0]}&tourId=${tourId}`);
            setDriverData(response.data.data);
        } catch (error) {
            console.error('Error fetching drivers:', error);
        } finally {
            setEnablePreloader(false);
        }
    }, [showAssignModal, searchQuery]);
    
    useEffect(() => {
        fetchDrivers();
    }, [fetchDrivers, requiredRefresh]);

    // Fetch vehicles
    useEffect(() => {
        const fetchVehicles = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/vehicle/all?filter=status=active&search=${vehicleSearchQuery}`);
                setVehicleData({ vehicles: response.data.data });
            } catch (error) {
                console.error('Error fetching vehicles:', error);
            }
        };

        fetchVehicles();
    }, [vehicleData.showAssignVehicleModal, requiredRefresh, vehicleSearchQuery]);

    const handleAssignDriver = (tourId) => {
        setSelectedTour(tourId);
        setShowAssignModal(true);
        setShowDriverContent(true);
    };

    const handleAssignDriverSubmit = async () => {
        try {
            setEnablePreloader(true);

            // Attempt to assign the driver
            await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/assignDriver/${selectedTour}`, {
                driverId: selectedDriver.driverId,
                tourDate: data.tourDate,
                tourTime: data.tourTime
            });
            await fetchDrivers();
            await fetchTripData();
            SendNewTripPrompt(selectedDriver.driverId, selectedTour);
            toast.success('Driver assigned successfully!');
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setEnablePreloader(false);
        }
    };

    const handleAssignVehicleSubmit = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/vehicle/vehicleDetails`, {
                driverId: selectedDriver.driverId,
                vehicleId: selectedVehicle
            });
            setRefreshRequired(true);
            toast.success('Vehicle assigned successfully!');
            setShowAssignModal(false);
            SendNewTripPrompt(selectedDriver.driverId, selectedTour);
        } catch (error) {
            toast.error(error.response.data.error);
        }
    };

    const handleCloseAssignModal = () => {
        setShowAssignModal(false);
        setSelectedTour(null);
        setSelectedDriver('');
    };

    const unassignDriver = async (driverId) => {
        try {
            setEnablePreloader(true);
            await axios.put(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/unassignDriver/${tourId}`, {
                driverId: driverId
            });
            await fetchDrivers();
            await fetchTripData();
            toast.success('Driver unassigned successfully!');
        } catch (error) {
            toast.error(error.response.data.error);
        } finally {
            setEnablePreloader(false);
        }
    };
    

    const ProfileData = () => (
        <div>
            <Row className="d-flex justify-content-between align-items-center py-4">
                <div className="d-block mb-4 md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Trip Details</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className='d-flex justify-content-between align-items-center'>
                        {!loading ? <div>
                            <h4>Trip Details</h4>
                            <div className='d-flex justify-content-start align-items-center mt-4'>
                          <div>
                            {data.adminImage !== "Not available" 
                            ? 
                            <div className='d-flex justify-content-center align-items-center gap-2'>{AvatarImage(data.adminImage)} created by: {data.adminName}
                            {data.adminEmail}
                            </div>
                            : <div className='d-flex justify-content-center align-items-center gap-3'>
                                    {Avatar(data.adminName.split(" ")[0], data.adminName.split(" ")[1])}
                                    <div>
                                         <p className='mb-0 f-14'>Created by {data.adminName}</p>
                                        <p className='mb-0 f-14'>{data.adminEmail}</p>
                                    </div>
                                </div>
                            }
                          </div>
                        </div>
                        </div> : <></>}
                    </div>
                </div>
                <div>
                    {!loading && (data.driverName === null
                        ? <div className='d-flex justify-content-between align-items-center alert alert-warning mb-0' role="alert">
                            <div className='px-4 pl-0'>
                                <p className='mb-0 text-dark fw-bold'>Action Required:</p>
                                <p className='f-14 mb-0'>This tour currently has no assigned driver. Please assign a driver to continue.</p>
                            </div>
                            <div>
                                <Button variant="primary" size="sm" className='animate-up-2' onClick={() => handleAssignDriver(tourId)} >
                                    <FontAwesomeIcon className="me-2" />Assign Driver
                                </Button>
                            </div>
                        </div>
                        : <></>)}
                </div>
            </Row>
            <Row>
                <Col>
                    <Card className='trip-wrapper'>
                        <TripHeader loading={loading} data={data} />
                        <Card.Body className='start-location-field'>
                            <TripLocation loading={loading} data={data} />
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <TripPax loading={loading} data={data} />
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <div className='guest-details-wrapper'>
                                <h6 className='mb-4'>Driver details</h6>
                                {loading ? <Skeleton width={150} /> : data.driverId ? (
                                    <>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <TripDriverDetails loading={loading} data={data} />
                                            <div className='d-none d-sm-block'>
                                                <TripDriverAssignment   
                                                    status={data.status}
                                                    driverId={data.driverId}
                                                    handleUnassignDriver={unassignDriver}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-block d-sm-none mt-2'>
                                        <TripDriverAssignment   
                                            status={data.status}
                                            driverId={data.driverId}
                                            handleUnassignDriver={unassignDriver}
                                        />
                                        </div>
                                    </>
                                ) : (
                                    <div className='alert alert-danger mb-4'>
                                        <div className="d-flex justify-content-start">
                                            <div className="px-2">
                                                <p className="f-12 mb-0 fw-bold">
                                                    No driver has been assigned for this tour
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <Dropdown.Divider className="my-3 border-indigo" />
                            <div className='guest-details-wrapper'>
                                <h6 className='mb-4'>Vehicle details</h6>
                                {loading ? <Skeleton width={150} /> : data.driverId
                                    ? <TripVehicle data={data} />
                                    : (
                                        <div className='alert alert-danger mb-4'>
                                            <div className="d-flex justify-content-start">
                                                <div className="px-2">
                                                    <p className="f-12 mb-0 fw-bold">
                                                        No vehicle has been assigned for this tour
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <div className='guest-details-wrapper'>
                                <h6 className='mb-4'>Timeline</h6>
                                {loading ? <Skeleton width={150} /> : (
                                    <div>
                                        {data.timeline ? <TripTimeline data={data.timeline} /> : 'No data available'}
                                    </div>
                                )}
                            </div>
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <TripItinerary loading={loading} data={data} />
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <TripInclusions loading={loading} data={data} />
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <div className='guest-details-wrapper'>
                                <h6 className='mb-4'>Remarks & Other Information</h6>
                                {loading ? <Skeleton width={150} /> : <div className="d-flex justify-content-start align-items-center mb-3">{data.remarks}</div>}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );

    return (
        <div className='mb-6'>
            <Toaster />
            <ProfileData />
            <Modal as={Modal.Dialog} centered show={showAssignModal} onHide={handleCloseAssignModal} size='lg' scrollable='true'>
                {showDriverContent ? (
                    <TripDriverModal
                        tourId={tourId}
                        driverData={driverData}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        handleCloseAssignModal={handleCloseAssignModal}
                        setAssignedDriverDetails={setAssignedDriverDetails}
                        unassignDriver={unassignDriver}
                        handleAssignDriverSubmit={handleAssignDriverSubmit}
                        enablePreloader={enablePreloader}
                        setSelectedDriver={setSelectedDriver}
                        tripDate={data.tourDate}
                    />
                ) : (
                    <VehicleModal
                        vehicleData={vehicleData}
                        vehicleSearchQuery={vehicleSearchQuery}
                        setVehicleSearchQuery={setVehicleSearchQuery}
                        handleCloseAssignModal={handleCloseAssignModal}
                        handleAssignVehicleSubmit={handleAssignVehicleSubmit}
                        setSelectedVehicle={setSelectedVehicle}
                    />
                )}
            </Modal>
            <CustomModal
                show={!!assignedDriverDetails.length}
                onClose={() => setAssignedDriverDetails([])}
                driverDetails={assignedDriverDetails}
            />
        </div>
    );
};
