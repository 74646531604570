import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button, Card, Modal, Pagination, OverlayTrigger, Tooltip, Form, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { faPlus, faDownload, faEllipsisH, faSearch, faBan, faFilter, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import toast, { Toaster } from 'react-hot-toast';
import { Routes } from '../../routes';
import ShortenAddress from '../../utility/ShortenAddress';
import FormatTz from '../../utility/FormatTz';
import Avatar from '../../components/Widgets/Avatar';
import Datetime from 'react-datetime';

export default () => {
  const [tours, setTours] = useState([]);
  const [selectedTours, setSelectedTours] = useState(new Set());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const perPage = 10;
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();
  const [totalRecords, setTotalRecords] = useState(0);
  const [dateFilter, setDateFilter] = useState("");
  const [rangeFilterSelected, setRangeFilterSelected] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Filter By"); // Default label
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleFilterSelect = (filter, label) => {
    if (filter !== "custom_range") {
      setDateFilter(filter);
      setSelectedFilter(label); // Update dropdown label
      setStartDate(null);
      setEndDate(null);
      setRangeFilterSelected(false)
    }
  };


  const handleRangeFilterSelect = (filter, label) => {
    if (filter === "custom_range") {
      setRangeFilterSelected(true);
      setSelectedFilter(label);
    }
  }

  const showAllCancelledTrips = () => {
    history.push(Routes.CancelledToursHistory.path);
  };
  const CustomToast = () => {
    return (
      <div style={{ padding: '16px 6px', color: '#fff' }}>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <p className='mb-1'>Explore and review all your canceled trips by visiting the Cancelled Trips page.</p>
            <Button variant="primary" className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" size='sm' onClick={() => showAllCancelledTrips()}>
              <span className='px-2 upperCase-keyword'>Click here</span>
            </Button>
          </div></div>
      </div>
    );
  };

  
  const handleDateChange = (date) => {
    setStartDate(date)
  };

  const handleEndDateChange = (date) => {
    setEndDate(date)
  }

  const fetchTours = React.useCallback(async () => {
    setLoading(true)
    try {
      const filterParams = new URLSearchParams({
        page: currentPage,
        pageSize: perPage,
        search: searchQuery,
        filterByDate: startDate ? "custom_range" : dateFilter,
        startDate: startDate ? startDate.toISOString() : "",
        endDate: endDate ? endDate.toISOString() : startDate ? startDate.toISOString() : "",
      });
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/newTours?${filterParams.toString()}`);
      setTours(response.data.data);
      setTotalPages(response.data.totalPages);
      setTotalRecords(response.data.totalRecords);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tours:', error);
    }
  }, [currentPage, perPage, searchQuery, dateFilter, startDate, endDate])

  useEffect(() => {
    fetchTours();
  }, [currentPage, perPage, searchQuery, dateFilter, startDate, endDate, fetchTours])

  const handleCreateBooking = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirmationClose = (confirmed) => {
    setShowConfirmationModal(false);
    if (confirmed) {
      history.push('/create-booking');
    }
  };

  const showTripDetails = (tourId) => {
    history.push(`/tripdetails/${tourId}`);
  };

  const downloadWeeklyReport = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/download-weekly-sales`,
        method: 'GET',
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'weekly_sales_data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleTourSelect = (tourId) => {
    setSelectedTours(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(tourId)) {
        newSelection.delete(tourId);
      } else {
        newSelection.add(tourId);
      }
      return newSelection;
    });
  };

  const handleBulkDelete = () => {
    setShowDeleteConfirmationModal(true);
  };

  const confirmDeleteTours = async () => {
    try {
      const idsToDelete = Array.from(selectedTours);
      await axios.post(`${process.env.REACT_APP_BACKEND_API_SCHEME}${process.env.REACT_APP_BACKEND_API_DOMAIN}${process.env.REACT_APP_BACKEND_API_STAGE}/tours/deleteTours`, { tourIds: idsToDelete });
      fetchTours();
      setSelectedTours(new Set());
      setShowDeleteConfirmationModal(false);
      toast.success("Successfully cancelled selected trips.")
      toast(<CustomToast />, {
        duration: 4000,
        position: 'bottom-right',
        style: { backgroundColor: '#262B40' },
        className: '',
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });

    } catch (error) {
      toast.error("Failed to cancel the trips.")
      console.error('Error deleting tours:', error);
    }
  };

  const startEntry = (currentPage - 1) * perPage + 1;
  const endEntry = Math.min(currentPage * perPage, totalRecords);

  return (
    <div className='mb-6'>
      <Toaster />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Button variant="primary" className="f-14 me-2 dropdown-toggle btn btn-dark animate-up-2 theme-bg-color-1" onClick={handleCreateBooking}>
          <FontAwesomeIcon icon={faPlus} />
          <span className='px-2'>Add New Trip</span>
        </Button>
      </div>

      <Card border="light" className="shadow">
        <Card.Header className=' d-block-sm-md d-flex justify-content-between align-items-center'>
          <div className='mb-2-sm-md'>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search by guest name..."
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1); // Reset to first page when searching
                }}
              />
            </InputGroup>
          </div>
          <div className='d-flex justify-content-between gap-2'>
          <div className="d-flex align-items-center gap-2">
            {/* Date Filter Dropdown */}
            <Dropdown className="btn-toolbar bg-transparent btn-filter-input">
              <Dropdown.Toggle as="text" variant="primary" size="sm">
                <FontAwesomeIcon icon={faFilter} className="me-2 f-14" />
                {selectedFilter}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2 filterDropDown">
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("today", "Today")}
                >
                  Today
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("tomorrow", "Tomorrow")}
                >
                  Tomorrow
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("week", "Week")}
                >
                  This Week
                </Dropdown.Item>
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleFilterSelect("month", "Month")}
                >
                  This Month
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  className="fw-bold f-14"
                  onClick={() => handleRangeFilterSelect("custom_range", "Select by date range")}
                >
                  Select by Date Range
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Custom Date Range Picker */}
            {rangeFilterSelected && (
              <div className="d-flex align-items-center">
                <div>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleDateChange}
                    value={startDate}
                    inputProps={{
                      required: true,
                      placeholder: 'mm/dd/yyyy',
                    }}
                    required
                    renderInput={(props, openCalendar) => (
                      <InputGroup onClick={openCalendar}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
                          placeholder='Select date'
                          required
                        />
                      </InputGroup>
                    )}
                  />
                </div>
                <span className="mx-2">to</span>
                <div className='me-2'>
                <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleEndDateChange}
                    value={endDate}
                    inputProps={{
                      required: true,
                      placeholder: 'mm/dd/yyyy',
                    }}
                    required
                    renderInput={(props, openCalendar) => (
                      <InputGroup onClick={openCalendar}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
                          placeholder='Select date'
                          required
                        />
                      </InputGroup>
                    )}
                  />
                </div>
              </div>
            )}
          </div>
            <Button variant="danger" size="sm" className="dropdown-toggle btn btn-secondary" onClick={handleBulkDelete} disabled={selectedTours.size === 0}>
              <FontAwesomeIcon icon={faBan} />
              <span className='px-2'>Cancel Trip</span>
            </Button>
            <Button variant="primary" size='sm' type="submit" className=" btn d-inline-flex align-items-center me-2 dropdown-toggle" onClick={downloadWeeklyReport}>
              <FontAwesomeIcon icon={faDownload} />
              <span className='px-2'>Generate Report</span>
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="pb-0 table-wrapper table-responsive">
          <Table hover className="table table-centered table-nowrap mb-0 rounded align-items-center">
            <thead className='thead-light'>
              <tr>
                <th className="border-0 rounded-start">
                  <Form.Check
                    className='f-14'
                    checked={selectedTours.size === tours.length}
                    onChange={() => {
                      if (selectedTours.size === tours.length) {
                        setSelectedTours(new Set());
                      } else {
                        setSelectedTours(new Set(tours.map(tour => tour.tourId)));
                      }
                    }}
                  />
                </th>
                <th className="border-0">Guest Name</th>
                <th className="border-0">Pick Up Point</th>
                <th className="border-0">Drop Off</th>
                <th className="border-0">Start Date & Time</th>
                <th className="border-0">Net Cost</th>
                <th className="border-0">Commission</th>
                <th className="border-0 rounded-end text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: perPage }).map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                    <td><Skeleton width={100} height={20} /></td>
                  </tr>
                ))
              ) : tours.length === 0 ? (
                <tr>
                  <td colSpan="9" className="text-center my-4 no-bottom-border">
                    No data found.
                  </td>
                </tr>
              ) : (
                tours.map((tour) => (
                  <tr className='cursor-pointer' key={tour.tourId}>
                    <td>
                      <Form.Check
                        id={`checkbox-${tour.tourId}`}
                        checked={selectedTours.has(tour.tourId)}
                        onChange={() => handleTourSelect(tour.tourId)}
                      />
                    </td>
                    <td>
                      <div className='upperCase-keyword fw-bold d-flex justify-content-start align-items-center'>
                        {Avatar(
                          tour.customerFullName.split(" ")[0], tour.customerFullName.split(" ")[1]
                        )}
                        <div className='px-2'>
                          {tour.customerFullName}
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-danger">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.startLocation}</Tooltip>} >
                          <div className="truncate-text">
                            {ShortenAddress(tour.startLocation)}
                          </div>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td>
                      <span className="text-success">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-location">{tour.endLocation}</Tooltip>} >
                          <div className="truncate-text">
                            {ShortenAddress(tour.endLocation)}
                          </div>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td>{FormatTz(tour.tourDate)}, {moment(tour.tourTime, 'HH:mm:ss').format('hh:mm A')}</td>
                    <td><span className="fw-bold">RM {tour.netCost}</span></td>
                    <td><span className="fw-bold">RM {tour.fare}</span></td>
                    <td className='text-center'>
                        <Button className="dropdown-toggle animate-up-2 theme-bg-color-1 text-center action-btn border-0 bg-transparent" size='sm' onClick={() => showTripDetails(tour.tourId)}>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <small className="fw-bold">
            Showing <b>{startEntry} to {endEntry} </b> out of <b>{totalRecords}</b> entries
          </small>
          {tours.length !== 0 && <Pagination className="justify-content-center">
            <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
            {Array.from({ length: Math.ceil(totalPages) }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>}
        </Card.Footer>
      </Card>

      {/* Confirmation Modal */}
      <Modal as={Modal.Dialog} centered show={showConfirmationModal} onHide={() => handleConfirmationClose(false)}>
        <Modal.Header>
          <Modal.Title className='f-22'>Confirm Navigation</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => handleConfirmationClose(false)} />
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to proceed to the new booking page? Any unsaved changes may be lost.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={() => handleConfirmationClose(false)}>Cancel</Button>
          <Button variant="primary" size="sm" onClick={() => handleConfirmationClose(true)}>Proceed</Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal as={Modal.Dialog} centered show={showDeleteConfirmationModal} onHide={() => setShowDeleteConfirmationModal(false)} >
        <Modal.Header>
          <Modal.Title className='f-22'>Confirm Cancellation</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowDeleteConfirmationModal(false)} />
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel the selected tours?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={() => setShowDeleteConfirmationModal(false)}>Cancel</Button>
          <Button variant="primary" size="sm" onClick={confirmDeleteTours}>Proceed</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
